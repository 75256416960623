@import "../../../styles/variables";

.document-request {
    .file-search-wrapper {
        .search-header-wrapper {
            padding: 0px 15px 0px 1px !important;
        }
    }
    .message-pane-top {
        padding-top: 8px !important;
    }
}
.request-document-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $gray-border;
    padding-top: 15px;
    padding-bottom: 10px;

    header {
        h3 {
            margin-top: 0;
            margin-bottom: 1rem;
            font-size: $heading-size-1;
            font-weight: 500;
            color: $brand-primary-blue-2;
        }

        span {
            font-size: $base-font-size;
            color: $brand-secondary-black-60;
            font-weight: 500;
        }
    }
}

.document-row-container {
    max-height: 166px;

    .document-row-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 14px;
        border: $gray-border;
        font-size: $font-size-md;
        margin-top: 12px;
    }

    .name-icon-wrapper {
        display: flex;
        align-items: center;
        max-width: 48%;

        span:not(.document-category-name) {
            margin-left: 11px;
        }

        .document-category-name {
            color: $gray-500;
            display: block;
        }
    }

    .file-name {
        font-size: 14px;
        width: 51vw;
        span {
            margin-left: 0px;
            font-weight: 700;
        }
    }

    .inline-button {
        border-right: 1px solid #ced4da;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    button {
        all: unset;
        color: $brand-primary-blue-1;
        border-right: $gray-border;
        padding-right: 8px;

        i {
            margin-right: 8px;
            margin-left: 8px;
        }

        svg {
            margin-right: 8px;
            margin-left: 8px;
        }

        &:last-child {
            border-right: 0;
            margin-right: 4px;
        }
    }
    .preview-button {
        svg {
            margin-top: 2px;
        }
    }
    .inline-button-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: -webkit-fill-available;
        p {
            margin-bottom: 0;
            color: $brand-primary-blue-1;
            padding-right: 8px;
            font-size: 14px;
        }

        button:disabled {
            opacity: 0.65;
            cursor: not-allowed;
        }

        .accordion-toggle {
            margin-left: 15px;
        }
    }

    .file-wrapper {
        display: flex;
        flex-direction: row;
        padding: 9px 18px 9px 38px;

        &:nth-child(odd) {
            background: #f0f1f1;
        }
    }
    .accordion {
        color: #444;
        cursor: pointer;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        border-radius: 2px 2px 0px 0px;
        border: 1px solid $gray-400;
        background: rgba(255, 255, 255, 0);
    }

    .accordion:hover {
        background-color: #f0f1f1;
    }

    .panel {
        background-color: white;
        flex-direction: column;
        border: 1px solid $gray-200;
        border-top: none;
    }

    .active,
    .panel {
        max-height: 125px;
        transition: max-height 0.2s ease-out;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
}

.my-files-request-padding{
    padding-left: 1px !important;

}

.document-request-detailed {
    .document-status-dropdown {
        .Dropdown-control {
            background-color: $success-100 !important;
            color: $brand-body_black !important;
        }
    }
    .request-more-btn {
        border-radius: 2px;
        margin-top: 15px;
        gap: 8px;
        padding: 6px 12px;
        display: flex;
        height: 38px;
        align-items: center;
        width: 141px;
        text-wrap: nowrap;
    }
}

.document-list {
    max-height: calc(100vh - 202px);
}

.document-body-wrapper {
    overflow: auto;
    max-height: 81px;

    p {
        font-size: $font-size-md;
        color: $brand-secondary-black;
    }

    .client-note {
        max-height: 35%;
        overflow: auto;
        margin-top: 10px;
    }
}

.document-body-wrapper + .document-row-list-wrapper {
    cursor: pointer;

    .add-files-btn {
        p {
            color: $brand-primary-blue-1;
        }
    }
}

.toggle-wrapper {
    display: flex;
    position: relative;
}

.template-dropdown {
    font-size: $font-size-md !important;

    &__control {
        font-size: $base-font-size;
        min-height: unset !important;

        &--is-focused {
            border-color: #86b7fe !important;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
    }

    &__menu {
        max-width: 100%;
        font-size: $base-font-size;
    }

    &__option {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 4px 8px !important;
    }

    &__indicator {
        padding: 0 4px !important;
    }

    &__clear-indicator {
        cursor: pointer;
    }

    &__menu {
        margin: 0;
    }

    &__menu-list {
        padding: 0;
    }
}

.category-dropdown {
    font-size: $font-size-md !important;

    &__control {
        font-size: $base-font-size;
        min-height: unset !important;
        height: 28.6px;

        &--is-focused {
            border-color: #86b7fe !important;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
    }

    &__menu {
        max-width: 100%;
        font-size: $base-font-size;
        z-index: 999999;
        position: absolute;
    }

    &__option {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 4px 8px !important;
    }

    &__indicator {
        padding: 0 4px !important;
    }

    &__clear-indicator {
        cursor: pointer;
    }

    &__menu {
        margin: 0;
    }

    &__menu-list {
        padding: 0;
    }
}

.status-change-modal {
    .modal-dialog {
        max-width: 680px !important;
    }

    .modal-content {
        white-space: pre-line;
    }

    .notify-container {
        span {
            padding-right: 10px;
        }
    }
}
.request-more-modal {
    .add-files-btn {
        p {
            color: $brand-primary-blue-1;
            cursor: pointer;
        }
    }

    .add-files-wrapper {
        .files-container-div {
            max-height: 200px;
            overflow: auto;
        }
    }
}

.container-with-scrollbar {
    overflow-y: auto;
}

.preview-modal {
    max-height: 99.8%;
    overflow: hidden !important;
    padding-left: 0 !important;

    .preview-header {
        background-color: $header_blue !important;
    }

    .modal-dialog {
        margin: 0px !important;
    }

    .modal-content {
        width: 100vw;
    }

    .modal-body {
        padding: 0;
        max-height: 100% !important;
    }

    .middle-section {
        position: absolute;
        left: 30%;
    }

    .toolbar-item-label {
        padding-top: 8px;
    }

    .d-sm-block .icon {
        margin-top: 2px;
    }

    .icon-bar .d-sm-block {
        margin-top: 2px;
    }

    .download-icon-file-viewer {
        display: flex;
        margin-top: 9px;
        padding-left: 9px;
        height: 100%;
        cursor: pointer;
    }
}

.filter-icon-container {
    align-items: center;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 7px;
    flex: 0 1 42px;
}

.search-header-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0px;
    flex-wrap: nowrap;

    .search-input-wrapper-col {
        padding-right: 0px;
        flex: 0 1 450px;
    }

    .search-input-wrapper-archive {
        padding-right: 0px;
        width: 27.5%;
        margin-left: -8px;
    }
}

.file-search-wrapper {
    display: flex;
    justify-content: space-between;

    .search-header-wrapper {
        margin: 0px;
        padding: 0px 12px 0px 1px;

        .search-input-wrapper-col {
            padding-right: 0px;
        }

        .search-input-wrapper-archive {
            padding-right: 0px;
            margin-left: -8px;
            flex: 1 1 450px;
        }
        .filter-icon-container {
            margin-right: 7px;

        }
        .refresh-button-wrapper-col {
            flex: 0 1 40px;
            padding: 0px;
        }
    }

    .file-view-toggle-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > *:not(:last-child) {
            margin-right: 5px;
        }
    }
}

.request-view-toggle{
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
    margin-right: -9px !important;
}


@media (max-width: 1366px) {
    .filter-icon-container {
        width: 30.4%;
    }
}

.list-refresh-icon-container {
    align-items: center;
    display: flex;
    padding: 0;
    text-align: center;
    margin: 0px;
    padding: 0px;
    flex: 0 1 40px;

    .list-refresh-button {
        padding: 5px 7px;
        width: 40px;
        height: 33px;
        border: 1px solid #ced4da;
        border-radius: 0px;
        cursor: pointer;
        text-align: center;
    }

    .disabled{
        cursor: not-allowed !important;
        opacity: 0.4;
    }
}

.print-dr-container {
    padding: 20px;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: $brand-body-black;

    h4 {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        margin-top: 20px;
    }

    h5 {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    header {
        svg {
            margin-left: 20px;
        }
    }

    .print-message-info {
        padding-left: 20px;
        display: flex;
        flex-direction: column;

        p {
            color: $gray-500;
            margin: 0px 0px 0px 0px;
        }

        .to-recipients {
            margin-top: 10px;
        }
    }

    .attachment-list-container {
        padding-left: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .attachment-list-title {
            display: flex;
            align-items: center;

            span {
                color: $gray-500;
            }
        }
    }

    .message-container {
        padding-left: 20px;
        margin-top: 20px;
    }
}

.document-request-border{
    position: absolute;
    width: 82%;
   top:205px !important;
   color: $gray-border;
}

.padding-right-9px{
    padding-right: 9px;
}
.download-button{
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 2px;
    gap:8px;  
}