// PRIMARY COLORS

$brand-primary-blue-1: #05386b;
$brand-primary-blue-2: #0973ba;
$brand-primary-green: #88c656;
$brand-primary-dark-blue: #002147;

// SECONDARY COLORS

$brand-secondary-blue: #13294b;
$brand-secondary-black: #101820;
$brand-secondary-green: #c7e995;
$brand-secondary-gray: #d9d9d6;
$brand-secondary-light-blue: #2f4362;
$brand-body_black: #212529;


// OTHER COLORS
$white: #fff;
$black: #000;
$sapphire-100: #E6F1F8;
$sapphire-200: #b5d5ea;
$brand-secondary-black-30: #1018204d;
$brand-secondary-black-50: #10182080;
$brand-secondary-black-60: #10182099;
$error-color: #dc3545;
$brand-seconday-gray-35: rgba(217, 217, 214, 0.35);
$gray-100: #f0f1f1;
$gray-200:#C4C6C8;
$gray-300: #a6a9ac;
$gray-400:#898D91;
$gray-500: #6B7075;
$dark-gray: #737373;
$white-90: #ffffffcc;
$icon-normal-state: #929292;
$icon-hover-state: $dark-gray;
$brand-blue-10: rgba(5, 56, 107, 0.1);
$hover-blue-shadow: 0 0 4px 2px #0d6efd40;
$non-branded-light-blue_hover: #0d6efd40;
$orange: #ffdb6f;
$hover-orange: #ffaa00;
$placeholder-grey: $dark-gray;
$theme_shamrock: #669440;
$navy_100:#E6EBF0;
$navy_blue:#011120;
$header_blue:#13294b;
$opaque_black:rgba(0, 0, 0, 0.9);

//DOCUMENT STATUS BUTTONS
$orange-500: #d36d13;
$info-500: #355abf;
$success-500: #1f8747;
$prince-500: #542d87;
$error-500: #cc4a43;

$orange-100: #fbf0e7;
$prince-100: #eeeaf3;
$error-100: #faedec;
$success-100: #E9F3ED;
$info-100: #ebeff9;

// TOASTER COLORS
$error-100: #FAEDEC;
$error-600: #b8433c;
$success-100: #E9F3ED;
$success-500: #1F8747;
$error-red: #CC4A43;
$theme-warning: #D69F38;
$warning-100:#fbf5eb;

// FONT

$font-family: "Mulish", sans-serif;
$base-font-size: 12px;
$font-size-md: 14px;
$heading-size-1: 18px;
$heading-size-2: 20px;

// BORDER

$gray-border: 1px solid #ced4da;
$selected-button-border: solid 6px $brand-primary-green;
$gray-border-shadow: inset 0 0px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1;

//ICON
$bsicon-arrow-down: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e";
$sapphire :#0973ba;

// Color Codes
$navy 		: #05386b;
$navy-100 	: #e6ebf0;
$navy-200 	: #b4c3d3;
$navy-300 	: #9bafc4;
$navy-400 	: #6988a6;
$navy-500 	: #05386b;
$navy-600 	: #053260;
$navy-700 	: #04274b;
$navy-800 	: #032240;
$navy-900 	: #011120;