@import "../../../styles/variables";
@import "../../../styles/components/keyframes";

.app-header-container {
    .brand-name-container {
        padding-right: 5px !important;
        padding-left: 5px !important;
        padding-bottom: 10px;

        .navbar-brand {
            padding-top: 0.3125rem;
            padding-bottom: 0.3125rem;
            margin-right: 1rem;
            font-size: 2.25rem;
            line-height: inherit;
            white-space: nowrap;
        }

        .navbar-brand img {
            max-width: 145px;
            max-height: 40px;
            margin-top: 12px;
        }

        .navbar .company-title {
            font-size: 20px !important;
            font-weight: 500;
            margin-left: -10px;
            font-size: 12px;
            font-family: "Roboto";
            font-weight: bold;
        }

        .navbar-brand:hover,
        .navbar-brand:focus {
            text-decoration: none;
        }
    }

    .app-header-menu-container {
        padding-right: 0px !important;
        padding-left: 0px !important;

        .app-header-menu {
            float: right;
            height: 48px;
            align-items: center;

            & > span {
                color: $brand-primary-blue-2;
                margin-right: 10px;
                font-size: 16px;
            }

            .header-username {
                font-weight: bold;
                font-size: 18px;
            }

            .app-header-menu-item {
                display: flex;
                align-items: center;
                font-size: 14px;
                padding: 3px 15px;
                cursor: pointer;
                color: $black;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05); // custom color for highlight
                }

                .dropdown-menu {
                    min-width: 12rem;
                }

                i {
                    color: $brand-secondary-black-60 !important;
                    text-shadow: #fff 1px 1px 1px;
                    line-height: 3.5rem;
                    font-size: 20px;
                    margin: 0 10px 0;
                }
            }
        }
    }
}

#profile-form,
#change-password-form {
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    .form-label {
        color: $dark-gray;
    }
}

.password-policies {
    padding: 10px 20px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        background-color: $brand-secondary-black-30;
        width: 1px;
        height: 70%;
    }

    h5 i {
        color: $orange;
    }

    .tooltip-target {
        color: $brand-primary-blue-2;
    }
}

#change-password-form {
    min-height: 350px;

    .form-control {
        border-right-width: 0;
    }

    .input-group-text {
        background-color: $white;
        color: $icon-normal-state;
        cursor: pointer;

        &:hover {
            color: $icon-hover-state;
        }
    }
}

.my-account {
    height: 100%;
    width: 100%;
    margin: 0;

    h3 {
        font-size: $heading-size-1;
    }

    .btn-info.btn-icon.icon-left {
        padding-right: 12px;
        padding-left: 39px;
    }
}

.profile-menu-container {
    .dropdown {
        user-select: none;

        .dropdown-toggle {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
            display: "inline-block" i {
                padding-inline: 5px;
            }
        }

        .dropdown-menu {
            padding: 0;
            left: auto;
            right: 0;
            width: 180px;
            -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.54);
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.54);
            border: none;
            font-size: $font-size-md;

            span {
                font-size: $font-size-md;
                color: $brand-secondary-black-60;
                font-weight: 500;
            }

            .menu-list-item {
                display: flex;
                justify-content: flex-start;
                padding: 0 10px;
                line-height: 3.5rem;

                & > a {
                    width: 100% !important;
                }

                & > div {
                    width: 100% !important;
                    margin: 0px;
                }

                .caption {
                    margin-left: 1rem;
                }
            }
        }

        .layout-dropdown-menu-right {
            left: auto;
            padding: 0.5rem 0;
            margin: 0.125rem 0 0;
            font-size: 14px;
            font-weight: 500;
            color: $brand-secondary-black-60;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 0.25rem;

            span {
                margin: 10px;
                color: $brand-secondary-black-60;
            }

            i {
                color: $brand-secondary-black-60 !important;
                text-shadow: #ffffff 1px 1px 1px;
            }
        }
    }
}

.dropoff-modal-container {
    margin-top: 0px;

    .modal-dialog {
        max-width: 720px !important;
    }

    .modal-body {
        min-height: fit-content;
    }

    .dropoff-modal-links-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px;
        font-size: $font-size-md;
        color: $brand-secondary-black;

        .copy-icon {
            font-size: 20px;
            color: $brand-secondary-black-60;
        }
    }
}

.dropoff-modal-links-container {
    margin-top: 0px;

    .modal-dialog {
        max-width: 720px !important;
    }

    .modal-body {
        min-height: fit-content;
    }

    .dropoff-modal-links-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px;
        font-size: $font-size-md;
        color: $brand-secondary-black;

        span {
            padding-right: 5px;
        }
        .copy-icon {
            font-size: 20px;
            color: $brand-secondary-black-60;
        }
    }
}
.my-downloads-modal {
    .description {
        font-size: $base-font-size;

        i {
            color: $brand-primary-blue-2;
        }
    }

    .settings-table {
        max-height: 50vh;
        overflow: auto;
        table-layout: fixed !important;

        .action-cell {
            display: flex;
            justify-content: center;
            align-items: center;

            & > div {
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            svg {
                fill: $icon-normal-state;
                cursor: pointer;

                &:hover {
                    fill: $brand-primary-blue-2;
                }
            }
            .pending-icon-animate {
                fill: $brand-primary-blue-2;
                animation-name: spin;
                animation-duration: 5000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }
        .folder-name-cell {
            display: flex;
            align-items: center;
            span {
                width: calc(100% - 34px);
            }
        }
    }
}

.dropoff-notification-setting {
    display: flex;
}
