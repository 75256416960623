@import "../../../styles/variables" ;

.header-actions-wrapper {
    display: flex;
    align-items: center;
    & > span {
        cursor: pointer;
        margin-right: 15px;
    }
    & > span > svg:hover > path{
        fill: $theme_shamrock !important;
    }
    .viewInbox-dropdown-wrapper {
        margin-right: 15px;
    }
    .report-problem-active > svg > path{
        fill: $theme_shamrock !important;
    }
}
.widget-wrapper {
    margin-right: 15px;
}
header .right-child {
    align-items: center;
}
#ssuite-layout-wrapper > header .right-child {
    align-items: center;
}
