.toaster-container {
  width: 400px !important;
  margin-right: 9px;
  margin-top: 47px;

  .toaster-body {
    width: 400px !important;
    border-radius: 4px;
    cursor: default;
    color: $brand-body_black;

    [aria-label="close"] {
      font-size: 22px;
      background: transparent;
      outline: none;
      color: $gray-500;
      border: none;
      padding: 0;
      cursor: pointer;
      transition: .3s ease;
      font-weight: 300;
      opacity: 0.7;
      transform: scale(0.9);
      align-self: center;
    }

    .close-button {
      display: flex;
      align-items: center;
    }
  }

  .toaster-error {
    background: $error-100;
    border-left: 5px solid $error-red;
  }

  .toaster-warning {
    background: $warning-100;
    border-left: 5px solid $theme-warning;
  }

  .toaster-success {
    background: $success-100;
    border-left: 5px solid $success-500;
  }

  .toaster-info {
    background: $sapphire-100;
    border-left: 5px solid $sapphire;
  }

  .toaster-icon {
    align-self: center;
    padding-right: 12px;
  }

  .toaster-icon.toaster-close svg:hover {
    fill: $gray-400;
  }

  .toaster-text {
    color: $brand-body_black;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-right: 14px;
    font-family: $font-family;
  }
}