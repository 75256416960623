@import "../../../../../styles/variables";

.category-wrapper{
    margin-top: 5px;
    .add-btn {
        width: 154px;
        height: 38px;
        border-radius: 2px;
        padding: 6px,12px,6px,12px;
        background-color: $brand-primary-blue-2;
        color: white;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
    }

    .delete-btn{
      
        border-radius: 2px ;
        border: 1px ;
        opacity: 0.4px;
    }
    
    .delete-btn-disabled{
        @extend .delete-btn;
        cursor: not-allowed !important;
    }

    .btn-container{
        display: flex;
        gap:8px;
        align-items: end;
        justify-content: flex-end;
        margin-bottom: 1%;
    }
}

.category-text{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-height: 41px;
    cursor: default;

}
.disabled-action{
    cursor: not-allowed !important;
}
.disabled-span{
    pointer-events: none;
    color: grey !important;
}

.category-table-container {
    height: 100%;

    .total-records {
        margin-left: 20px;
        position: absolute;
        top: 20px;
        left: 239px;
    }

    .react-bootstrap-table {
        max-height: calc(100% - 27vh);
        overflow-y: scroll;
        tr {
            cursor: pointer;
        }

        th {
            white-space: nowrap;
        }
    }
    .react-bootstrap-table-pagination {
        position: absolute;
        padding-top: 0px !important;
        width: 100%;

        & > div:nth-child(1) {
            padding-left: 20px;
            padding-top: 5px;
        }
        .react-bootstrap-table-pagination-list {
            & > ul {
                margin-bottom: 0px;
            }
        }
    }
}


.action-btn
    {

        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $brand-primary-blue-1;
        cursor: pointer;
    }

    .category-table {
        font-size: $font-size-md;
        .selection-cell-header{
            width: 10px;
        }
        .react-bootstrap-table & {
            table-layout: fixed !important;
        }
        .selection-cell {
            input[type="checkbox"] {
                height: 15px;
                width: 15px;
                border-radius: 4px;
                margin-top: 4px;

            }
        }
       
    }