@import "../../../styles/variables";

.unlock-loader {
    height: calc(100% - 16px);

    .others-inbox-info-label {
        position: absolute;
        width: 100%;
    }

    .tab-container .tab-header-container {
        width: calc(100% - 20px) !important;
    }

    .unlock-parent-container {
        height: 100%;
        padding: 1rem;
        padding-bottom: 0rem;
        margin-right: 10px;
      
        .unlock-tab-content {
            margin-left: -10px;

            .view-inbox-header{
                margin-top: 10px;
            }
            
            header {
                margin-bottom: 10px;

                h3 {
                    margin-top: 0;
                    margin-bottom: 1rem;
                    font-size: $heading-size-1;
                    font-weight: 500;
                    color: $brand-primary-blue-2;
                }

                span {
                    font-size: $base-font-size;
                    color: $brand-secondary-black-60;
                    font-weight: 500;
                }
            }
        }

        .unlock-button {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 115px;
            padding: 8px 10px;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .button-primary-blue-rounded {
            float: right;
        }

        .td-type-1 {
            text-overflow: ellipsis;
        }

       .unlock-table-container {
        margin-left: -10px;
        margin-right: -5px;

        .react-bootstrap-table {
            height: calc(100vh - 268px);
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
            table-layout: fixed !important;

            .td {
                overflow: hidden;
            }

            .email-cell {
                max-width: 25%;
            }

            .subject-cell {
                max-width: 50%;
            }
        }

         .react-bootstrap-table-pagination {
            background-color: white;
                padding-top: 0px !important;
            width: 100%;

            & > div:nth-child(1) {
                padding-left: 20px;
                padding-top: 5px;
            }
            .react-bootstrap-table-pagination-list {
                & > ul {
                    margin-bottom: 0px;
                }
            }
        }
    }
        .unlock-switch-div {
            float: right;
            padding-top: 1%;
        }
    }
}

.unlock-delegate-container{
    .react-bootstrap-table{
       height: calc(100vh - 287px) !important;
    }
}