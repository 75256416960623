@import "../../../styles/variables.scss";

.print-message-container {
  padding: 20px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: $brand-body-black;

  h4 {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-top: 20px;

  }

  h5 {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

  }

  header {
    svg {
      margin-left: 20px;
    }
  }

  .print-message-info {
    padding-left: 20px;
    display: flex;
    flex-direction: column;

    p {
      color: $gray-500;
      margin: 0px 0px 0px 0px;
    }

    .to-recipients {
      margin-top: 10px;
    }
  }

  .attachment-list-container {
    padding-left: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .attachment-list-title {
      display: flex;
      align-items: center;

      span {
        color: $gray-500;
      }
    }

  }

  .message-container {
    padding-left: 20px;
    margin-top: 20px;
  }
}