@import './brand_colors.css';
@import url("https://fonts.googleapis.com/css?family=Roboto");

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #373e4a;
  margin-top: 0.5rem;
}
/*-------- Scroll Bar CSS---Start-- */
/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: inherit;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f6f6f6;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--non-branded-light-blue_hover);
  /* background: var(--brand-light-blue); */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--brand-light-blue);
}
/*-------- Scroll Bar CSS---End---- */
.header-section-container {
  float: left;
  width: 100%;
  height: 10%;
}

.detail-section-container {
  height: 100%;
  width: 100%;
}

.text-success {
  color: #28a745 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-violet {
  color: #940094 !important;
}

.text-grey {
  color: #666666 !important;
}

.text-warning {
  color: orange !important;
}

.text-blue {
  color: #17a2b8;
}

.text-ERO {
  color: #f0ad4e;
}

.MainContent.classic {
  padding-left: 0 !important;
  padding-top: 0;
}

.MainContent.classic.no-sidemenubar {
  padding-left: 28px !important;
}

.menu-toggle-icon {
  border: 1px solid #ebebec;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  color: #51555d;
  font-size: 18px;
  margin: 4px 2px 2px;
  position: relative;
  left: 14.7%;
}

.clearfix {
  clear: both;
}

.main-body-container {
  padding-top: 5px;
}

.profile-icon {
  margin-left: 5px;
}

.bg-none {
    background: none !important;
} 

.detail-section-container {
  padding: 58px 0 0 0;
}

.navbar-fixed-top {
  border-bottom: 1px solid #ebebec;
  background: #fff;
}

.navbar-fixed-top ul.nav > li > a {
  padding: 22px 20px 20px 20px;
  color: #51555d;
  font-size: 12px;
}

.navbar-fixed-top ul.nav > li > a i.right {
  margin-right: 0;
  margin-left: 5px;
}

.navbar-fixed-top ul.nav .dropdown .dropdown-menu {
  width: 140px !important;
  min-width: 140px;
  background-color: #f5f5f6;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
  box-shadow: none;
}

.navbar-toggle .icon-bar {
  background: #00008a;
}


.minimized-menu .main-body-container.classic {
  width: 97%;
}

.minimized-menu .sidemenubar-container.classic {
  width: 0;
  position: absolute;
  z-index: 9;
}

.minimized-menu .menu-toggle-icon {
  left: 0;
}

.minimized-menu .menu-toggle-icon > .glyphicon {
  transform: rotate(180deg);
}

.btn {
  font-size: 12px;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  box-shadow: none;
}
.cursor-default{
  cursor: default;
}
.small-header-info {
  color: #337ab7;
  font-weight: 400;
  font-size: small;
}

.padTB15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pad-left-34 {
  padding-left: 26% !important;
}

.allPadding2 {
  padding: 2px;
}

.margin-lf-5 {
  margin-left: 5%;
}

.font-italic {
  font-style: italic;
}

.purchaseReturnBody {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.top-padding-4 {
  padding-top: 4px;
}

.top-21-p {
  margin-top: -21px;
}

.backg-lighter-grey {
  background-color: #f5f5f5;
}

.backg-light-grey {
  background-color: #ddd !important;
}

.padding-b-30p {
  padding-bottom: 30px;
}

.order-summary-pad {
  padding: 15px 0px 15px 25px;
}

.margin-lft-76per {
  margin-left: -76%;
}

.tot-balance {
  font-size: 15px;
  font-weight: 700;
  margin-top: 2%;
  margin-right: 8%;
}

.right-margin-0 {
  margin-right: 0px;
}

.padding-margin-b-12p {
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.margin-bottom-35p {
  margin-bottom: 35px;
}

.backg-white {
  background-color: white;
}

.allPadding5 {
  padding: 5px;
}

.purchase-nav-link {
  padding-top: 10px;
  padding-bottom: 15px;
}

.row.allpadding2 > .col-sm-3,
.row.allpadding2 > .col-sm-2,
.row.allpadding2 > .col-sm-1 {
  padding: 2px;
}

.noBorder {
  border: none;
}

.btn.btn-secondary {
  background-color: rgb(241, 241, 241);
}

.modal-exended > .modal-dialog > .modal-content {
  width: 110%;
}

.nav-item.whiteLink > .nav-link:hover,
.nav-item.whiteLink.active > .nav-link:hover {
  border-top: none;
}

.nav-item.whiteLink > .nav-link {
  border-bottom-color: #337ab7;
  border-bottom-width: unset;
  background-color: #eee;
  border-top: none;
}

.nav-item.whiteLink.active > .nav-link {
  text-shadow: none;
  color: white !important;
  background-color: #337ab7;
  border-top: none;
  border-bottom-color: #337ab7;
  border-bottom-width: unset;
}

.blueTitle {
  color: #337ab7;
  font-weight: bold;
}

.marginBottm10p {
  margin-bottom: 10px;
}

.allPadding10 {
  padding: 10px;
  background-color: #eee;
}

.padding10 {
  padding: 10px;
}

.min-height400 {
  min-height: 400px;
}

.min-height300 {
  min-height: 300px;
}

.backgroung-white {
  background-color: white;
}

.order-summary-tab {
  margin-left: 4%;
  padding: 10px;
  background-color: white;
}

.order-summary-tab > * {
  font-size: medium !important;
  background-color: white;
}

.order-summary-header {
  background-color: #337ab7;
  color: white;
}

.order-summary-header > div > label {
  font-size: medium;
}

.summary-header {
  font-size: medium;
  font-weight: 400;
}

.order-summary-total {
  font-weight: 700;
}

.summary14 {
  font-size: 14px;
}

.summary-info {
  font-weight: 400;
}

.summaryLn {
  border-top-style: solid;
  border-top-color: #8a888e;
  border-top-width: thin;
}

.pad20p {
  padding: 20px;
}


.pointer {
  cursor: pointer;
}

.toolbar .btn {
  padding: 4px 10px 5px 40px;
  position: relative;
}

.toolbar .btn i {
  position: absolute;
  left: 0;
  padding: 8px;
  top: 0;
  border-radius: 4px 0 0 4px;
  font-size: 10px;
}

.toolbar-left-buttons .btn-default {
  margin-right: 4px;
}

.toolbar-left-buttons .btn-default i {
  background: #e6e6e6;
}

.toolbar-right-buttons .btn-info i {
  background: #31b0d5;
}

.modal.upload-doc-modal .modal-dialog {
  min-width: 100%;
}

.set-access-modal .modal-dialog {
  width: 61%;
}

.set-access-modal .nav-button {
  margin-top: 87px;
  text-align: center;
  font-size: 26px;
}

.set-access-modal .nav-button a {
  color: #373e4a;
}

.set-access-modal .nav-button a:hover,
.set-access-modal .nav-button a:focus {
  color: #818da2;
}

.purchase-return-modal .modal-dialog {
  width: 65%;
}

.purchase-return-modal .nav-button {
  margin-top: 87px;
  text-align: center;
  font-size: 26px;
}

.purchase-return-modal .nav-button a {
  color: #373e4a;
}

.purchase-return-modal .nav-button a:hover,
.purchase-return-modal .nav-button a:focus {
  color: #818da2;
}

.modal.upload-doc-modal .modal-dialog .divDocumentUpload {
  border: 4px dashed #bababa;
  padding: 8px;
}

.modal.upload-doc-modal .modal-dialog .divDocumentUpload > img {
  max-width: 100%;
}

.modal.upload-doc-modal .modal-dialog table thead th {
  background-color: #f5f5f6;
  color: #a6a7aa;
  font-weight: 400;
}

.modal.upload-doc-modal .modal-dialog table tbody td {
  background-color: #f8f8f8;
}

.modal.upload-doc-modal .modal-dialog .table > thead > tr > th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered > tbody > tr > th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered > tfoot > tr > th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered > thead > tr > td,
.modal.upload-doc-modal .modal-dialog .table .table-bordered > tbody > tr > td,
.modal.upload-doc-modal .modal-dialog .table .table-bordered > tfoot > tr > td {
  border: 1px solid #ebebeb;
}

.modal-body .alert {
  margin: -14px -14px 20px -14px;
  border: none;
  border-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 11px;
}

.user-assignment-content,
.company-assignment-content {
  margin-top: 0px;
}

.user-assignment-content table td.button-cell,
.company-assignment-content table td.button-cell {
  overflow: visible;
}

.user-assignment-content table td.button-cell .button-group > .btn-toolbar,
.company-assignment-content table td.button-cell .button-group > .btn-toolbar {
  float: left;
}

.user-assignment-content table #process-return-dropdown,
.company-assignment-content table #process-return-dropdown,
#delivered-return-dropdown {
  padding: 2px 10px;
  padding-top: 0px;
  margin-top: -5px;
  height: 20px;
  border-radius: 0px !important;
  font-size: smaller;
  background: #ffffff;
  border: 1px #e0e0e0 solid;
  color: #303641;
}

.user-assignment-content table td.button-cell .button-group > .btn > .glyphicon,
.company-assignment-content
  table
  td.button-cell
  .button-group
  > .btn
  > .glyphicon {
  font-size: 8px;
  padding: 6px 11px;
}

.user-assignment-content .react-bs-container-body,
.company-assignment-content .react-bs-container-body {
  overflow: inherit;
}

#pageDropDown {
  display: none;
}

.react-bs-table-tool-bar > .row > div:nth-child(1) {
  float: right;
}

.user-assignment-content .react-bs-table .table-bordered > thead > tr > th,
.company-assignment-content .react-bs-table .table-bordered > thead > tr > th {
  background: #f5f5f6;
}

.react-bs-table .table-bordered > tbody > tr > td {
  padding-top: 3px;
  padding-bottom: 1px;
}

.form-horizontal .text-left {
  text-align: left !important;
}

.modal.clientinfo-edit-modal .modal-dialog {
  width: 36%;
}

h4,
.h4 {
  font-size: 15px;
}

.btn-default {
  color: #303641;
  background-color: #f0f0f1;
  border-color: #f0f0f1;
}

.modal.clientinfo-edit-modal .panel {
  border-radius: 0;
}

.form-control {
  border-radius: 3px;
  font-size: 12px;
}

.form-check-label {
  font-weight: normal;
}

.modal .modal-content {
  border-radius: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-dialog .modal-content {
  border-radius: 1px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-footer .btn + .btn {
  margin-left: 10px;
}

.main-body-container.classic > div > h3 {
  margin-top: -15px;
}

/*process return tabs*/
#process-return-tabs {
  margin-top: 20px;
}

.process-return-container .parent-tab-head > .nav-tabs {
  margin-bottom: 10px;
}

.process-return-container .parent-tab-head > .nav-tabs > li + li {
  border-left: 1px solid #eee;
}

.process-return-container .parent-tab-head > .nav-tabs > li > a {
  color: #979898;
  font-weight: bold;
  border-radius: 0;
}

.process-return-container .parent-tab-head > .nav-tabs > li.active > a {
  color: #646565;
  border: 1px solid transparent;
  background: #fcfcfc;
}

.process-return-container .parent-tab-head > .nav-tabs > li.active,
li.active ~ li > a:after {
  background-color: #fcfcfc !important;
}

.process-return-container .parent-tab-head > .nav-tabs > li {
  background: #fcfcfc;
}

.process-return-container .nav-tabs > li a,
.process-return-container .nav-tabs > li a:hover,
.process-return-container .nav-tabs > li a:focus {
  padding: 10px 5px;
  border-bottom: none !important;
  border: none !important;
  background: #fcfcfc !important;
  color: #555;
}

.process-return-container .parent-tab-head > .nav-tabs > li a:after {
  content: "";
  position: absolute;
  display: block;
  height: 10px;
  background-color: #8dc63f;
  top: 100%;
  left: 0;
  right: 0;
}

.process-return-container .parent-tab-head > .nav-tabs > li.active a:after {
  background: rgba(255, 196, 0, 1);
}

.process-return-container .row > .tab-content {
  min-height: 400px;
  background: #f7f7f7;
  padding: 10px 0 40px 0px;
}

.process-return-container .panel-default > .panel-heading {
  background: #fff;
  border: none;
}

.process-return-container
  .panel-default
  > .panel-collapse
  .panel-default
  > .panel-heading {
  background: #f7f7f7;
}

.process-return-container
  .panel-default
  > .panel-collapse
  .panel-default
  > .panel-heading
  .panel-title {
  font-size: 14px;
}

.panel-title i {
  color: #aaa;
  margin-right: 10px;
  text-shadow: #ffffff -2px 2px 1px;
}


#left-group-tab .menu-item ul.nav-pills li > a {
  border-width: 2px 2px 2px 10px;
  padding: 16px 15px;
  margin-right: 0px;
  color: #000;
  background: #fff;
  border-style: dashed dashed dashed solid;
}

#left-group-tab .menu-item ul.nav-pills li.all > a {
  border-color: #e0e0e0;
  border-style: solid;
}

#left-group-tab .menu-item ul.nav-pills li.transmittal > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.yellow-color {
  border-color: #ffc400;
}

#left-group-tab .menu-item ul.nav-pills li.efile > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.orange-color {
  border-color: #f1af5e;
}

#left-group-tab .menu-item ul.nav-pills li.voucher > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.red-color {
  border-color: #e59fa1;
}

#left-group-tab .menu-item ul.nav-pills li.k1 > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.purple-color {
  border-color: #dd88ee;
}

#left-group-tab .menu-item ul.nav-pills li.tax-returns > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.green-color {
  border-color: #8dc63f;
}

#left-group-tab .menu-item ul.nav-pills li.invoice > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.blue-color {
  border-color: #a0bdfa;
}

#left-group-tab .menu-item ul.nav-pills li.deleted > a,
#left-group-tab .menu-item ul.nav-pills > li > a .arrow.black-color {
  border-color: #595959;
}

#left-group-tab .menu-item ul.nav-pills > li > a:hover,
#left-group-tab .menu-item ul.nav-pills > li > a:active,
#left-group-tab .menu-item ul.nav-pills > li > a:focus {
  background: #fff;
  color: #000;
}

#left-group-tab .menu-item ul.nav-pills > li.active > a .arrow {
  border-style: dashed dashed dashed solid;
  border-width: 2px 2px 2px 10px;
  width: 36px;
  height: 36px;
  transform: rotate(45deg);
  position: absolute;
  top: 32px;
  margin-top: -25px;
  right: -19px;
  z-index: 10;
}

#left-group-tab .menu-item ul.nav-pills > li.active > a .arrow.gray-color {
  border-color: #e0e0e0;
  border-style: solid;
}

#left-group-tab .menu-item ul.nav-pills > li.active > a .arrow,
#left-group-tab .menu-item ul.nav-pills li.active > a {
  border-style: solid;
}

#left-group-tab .menu-item .nav-stacked > li + li {
  margin-top: 10px;
}

#left-group-tab .menu-item ul.nav-pills > li.active > a .arrow-cover {
  top: 34px;
  margin-top: -27px;
  right: -16px;
  width: 35px;
  height: 35px;
  border: 2px dashed transparent;
  position: absolute;
  background: #ffffff;
  z-index: 30;
  transform: rotate(45deg);
}

.nopadding-left {
  padding-left: 0 !important;
}

.nopadding-right {
  padding-right: 0 !important;
}

.nopadding {
  padding: 0 !important;
}

#process-return-tabs .pagination > .active > span {
  background-color: #000;
  border-color: #000;
}

#process-return-tabs .pagination > li a {
  color: #000;
  background: #fff;
}

#process-return-tabs .pagination > li a:hover {
  color: #000;
}

#process-return-tabs .pagination > .active > a {
  color: #ffffff;
  background-color: #303641;
  border-color: #303641;
}

#process-return-tabs .pagination > .active > a:hover {
  color: #ffffff;
}

#process-return-tabs .pagination > li.active span:hover {
  background: #000;
  border: 1px solid #000;
}

.process-return-container .return-footer .btn-default {
  margin-right: 2px;
  background: #fff;
  border: 1px #dddddd solid;
}

.process-return-container .return-footer .copyright-text {
  margin-top: 5px;
  color: #949494;
}

.group-tab .groups-title {
  background: #fff;
}

.group-tab .groups-title .move,
.group-tab .groups-title .trash {
  margin-top: 5px;
}

.group-tab .groups-title .move .btn,
.group-tab .groups-title .trash .btn {
  background: #fff;
  border: none;
}

.group-tab .tab-content .panel-default {
  background: none;
}

.group-tab .tab-content {
  overflow-y: scroll;
  height: 85vh;
}

.group-tab .tab-content .group-report-options .checkbox {
  position: absolute;
  left: 140px;
}

.group-tab .tab-content .group-report-options .search {
  position: absolute;
  bottom: 10px;
  left: 115px;
  color: #777272;
}

.group-tab .tab-content .group-report-options .trash {
  position: absolute;
  bottom: 10px;
  left: 135px;
  color: #777272;
}

.group-tab .tab-content canvas {
  float: left;
}

.group-tab .group-report-items {
  width: auto;
}

.group-tab .group-report-items .canvas-outer {
  height: 200px;
  width: 160px;
  position: relative;
  background: #fff;
  margin-right: 20px;
  margin-bottom: 25px;
  margin-top: 5px;
  box-shadow: 0px 0px 10px #ffc400;
}

#left-group-tab-pane-viewAll .panel .panel-heading {
  color: #fff;
}

#left-group-tab-pane-viewAll .trasmittals-panel-all .panel-heading {
  background: #ffc400;
}

#left-group-tab-pane-viewAll .efile-panel-all .panel-heading {
  background: #f1af5e;
}

#left-group-tab-pane-viewAll .vouchers-panel-all .panel-heading {
  background: #e59fa1;
}

#left-group-tab-pane-viewAll .k1-panel-all .panel-heading {
  background: #dd88ee;
}

#left-group-tab-pane-viewAll .tax-returns-panel-all .panel-heading {
  background: #8dc63f;
}

#left-group-tab-pane-viewAll .invoice-panel-all .panel-heading {
  background: #a0bdfa;
}

#left-group-tab-pane-viewAll .deleted-panel-all .panel-heading {
  background: #595959;
}

.icon-textbox .glyphicon {
  position: absolute;
  background: #eee;
  padding: 10px;
}

.icon-textbox input {
  padding-left: 40px;
}

.btn.icon-button {
  padding: 4px 10px 5px 40px;
  position: relative;
  background: #fff;
}

.btn.icon-button i {
  position: absolute;
  left: 0;
  padding: 8px;
  top: 0;
  border-radius: 4px 0 0 4px;
  font-size: 10px;
  background: #e6e6e6;
}

.attachments-tab .divDocumentUpload {
  border: 4px dashed #bababa;
  padding: 8px;
  background: #fafafa;
  padding-top: 10px;
  margin-bottom: 10px;
}

.attachments-tab .divDocumentUpload img {
  display: block;
  margin: 0 auto;
}

.attachments-tab .title {
  padding: 1px 10px;
  background: #fff;
}

.attachments-tab .panel-default .react-bs-table * {
  border: none;
}

.attachments-tab .panel-default .react-bs-table {
  border: none;
  color: #222;
  font-weight: bold;
}

.attachments-tab .dz-default.dz-message {
  display: none;
}

.attachments-tab .btn-success {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.attachments-tab .btn-danger {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.process-return-container .client-info-tab .content,
.process-return-container .transmittals-tab .content,
.process-return-container .tax-returns-tab .content,
.process-return-container .efile-tab .content,
.process-return-container .vouchers-tab .content,
.process-return-container .invoices-tab .content {
  margin-left: 68px;
}

.process-return-container .client-info-tab .right-sidebar,
.process-return-container .transmittals-tab .right-sidebar,
.process-return-container .tax-returns-tab .right-sidebar,
.process-return-container .efile-tab .right-sidebar,
.process-return-container .vouchers-tab .right-sidebar,
.process-return-container .invoices-tab .right-sidebar {
  width: 27%;
}

.tab-content > .active.delivery-options-tab > div {
  background: #fff;
}

.delivery-options-tab .content-box h4 {
  color: #1a8fbf;
  font-size: 16px;
}

.radio-inline input[type="radio"] {
  margin-top: 0;
}

.form-group .fa-question-circle {
  font-size: 14px;
  color: #ffdb6f;
}

.group-zoom-modal canvas {
  margin: 0 auto;
}

.draggable-signature {
  border: 1px solid #666;
  position: absolute;
  z-index: 5;
  cursor: move;
  background: #fff;
  margin-top: 35px;
}

.draggable-signature .remove-handle {
  top: -12px;
  background: #000;
  color: #fff;
  cursor: pointer;
  right: -10px;
}

.draggable-signature .glyphicon-pencil {
  padding: 4px;
  background: #f0f0f1;
}

.draggable-signature .glyphicon-calendar {
  padding: 4px;
  background: #f0f0f1;
}

.draggable-signature .glyphicon-font {
  padding: 4px;
  background: #f0f0f1;
}

.esignControlWrapper .boxshadow {
  box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
}

@media only screen and (max-width: 768px) {
  .detail-section-container .menu-toggle-icon {
    border: none;
    position: initial;
    left: 0;
    width: 0;
  }

  .detail-section-container .navbar-toggle {
    position: absolute;
    top: 0;
    z-index: 9999;
  }

  .detail-section-container .menu-toggle {
    height: 0;
  }

  .sidemenubar-container.classic {
    width: 0;
    padding: 0;
    float: left;
  }

  .sidemenubar-container.classic * {
    padding: 0;
    width: 0;
    overflow: hidden;
  }

  .maximized-menu .sidemenubar-container.classic {
    width: auto;
    padding: 0 15px;
    float: none;
  }

  .maximized-menu .sidemenubar-container.classic * {
    padding: initial;
    width: 100%;
    overflow: visible;
  }

  .maximized-menu .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
  }

  .modal.clientinfo-edit-modal .modal-dialog {
    width: 90%;
  }
}

@media only screen and (max-width: 991px) {
  .menu-toggle-icon {
    left: 30.5%;
  }
}

.compulsory-star {
  color: #c50505;
  position: absolute;
  right: 3px;
  top: 12px;
  font-size: 7px;
}

.txt-ar {
  text-align: right;
}

.txt-al {
  text-align: left;
}

.marL10 {
  margin-left: 10px;
}
.marL23{
  margin-left: 23px;
}
.marL0 {
  margin-left: 0px;
}

.marB5 {
  margin-bottom: 5px;
}

.marB10 {
  margin-bottom: 10px;
}

.marB20 {
  margin-bottom: 20px;
}

.marB10I {
  margin-bottom: 10px !important;
}

.padT07 {
  padding-top: 7px;
}

.padT15 {
  padding-top: 15px;
}

.padR25 {
  padding-right: 25px;
}

.pad15 {
  padding: 15px;
}
.pad-align{
  padding: 9px 5px;
}
.pad-my-account{
  padding:1.5rem;
}
.tpLabelFont {
  font-size: 12px;
  font-weight: bold;
}

.checkbox-replace,
.radio-replace {
  position: relative;
  padding-left: 0;
}

.btn {
  position: relative;
  box-shadow: #80808040 0px 0px 3px;
  border: none;
  border-radius: 2px !important;
}

.btn i {
  float: left;
  right: auto;
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  padding: 9px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  text-shadow: 0px 1px 0px #ebebeb;
  border-radius: 2px !important;
}

.btn-icon {
  padding: 9px 12px 10px 34px;
}

.btn-info {
  background-color: #21a9e1;
  color: white;
}

.btn-info:hover {
  background-color: #1a8fbf;
}

.btn-info i {
  background-color: #1a8fbf;
}

.btn-white {
  background: #fafafa;
  border: 1px #e0e0e0 solid;
  color: #303641;
  margin-right: 2px;
  margin-bottom: 2px;
}

.btn-white:hover {
  background: #ebebeb;
}

.btn-white i {
  text-shadow: 0px 1px 0px #ebebeb;
}

.btn-icon-default {
  background: #ebebeb;
  border: 1px #e0e0e0 solid;
  color: #303641;
  margin-right: 2px;
  margin-bottom: 2px;
}

.btn-icon-default:hover {
  background: #ebebeb;
}

.btn-icon-default i {
  text-shadow: 0px 1px 0px #ebebeb;
}

.btn-success i {
  background: #449d44;
}

.btn-danger i {
  background: #c9302c;
}

.btn-default i {
  background: #d4d4d4;
  text-shadow: 0px 1px 0px #ebebeb;
}

.btn-sm [disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}

.button-hide {
  display: none;
}

/*Company Settings starts*/

.company-settings .new-main-content h3 {
  color: #1a8fbf;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 16px;
}

.new-main-content h4 {
  color: #1a8fbf;
}

.company-settings .help-circle {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 1px #ffdb6f solid;
  padding: 2px 0px 1px;
  margin-left: 5px;
  font-size: 12px;
  color: #ffdb6f;
}

.company-settings .marB15 {
  margin-bottom: 15px;
}

.company-settings .btn-new {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 48%,
    rgba(236, 236, 236, 1) 48%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ececec',GradientType=0 );
  border: 1px #4c4c4c solid;
  color: #4c4c4c;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  padding: 7px 0px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
}

.counter-textbox input {
  width: 50px;
  height: 29px;
  border-width: 1px 0px 1px 0px;
  border-color: #ccc;
  border-style: solid;
  text-align: center;
  vertical-align: bottom;
}

.counter-textbox .btn-left-radius {
  border-radius: 4px 0px 0px 4px;
}

.counter-textbox .btn-right-radius {
  border-radius: 0px 4px 4px 0px;
}

.edit-ins-list {
  height: 100px;
  margin-bottom: 10px;
  border: 1px #ebebeb solid;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  height: 240px;
}

.edit-ins-list ul {
  margin: 0px;
  padding: 0px;
}

.edit-ins-list ul li {
  margin: 0px;
  padding: 5px;
}

.edit-ins-list ul li:hover {
  background: #f0f0f1;
  color: #000000;
  cursor: pointer;
}

.activeManual {
  background: #e9e9ee;
  color: #51555d;
}
/*Company settings ends*/
.add-column-value .help-circle {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 1px #ffdb6f solid;
  padding: 2px 0px 1px;
  margin-left: 5px;
  font-size: 12px;
  color: #ffdb6f;
}

.custom-column .help-circle {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 1px #ffdb6f solid;
  padding: 2px 0px 1px;
  margin-left: 5px;
  font-size: 12px;
  color: #ffdb6f;
}

.custom-column .custom-column-list-group {
  cursor: pointer;
  max-height: 200px;
  height: 200px;
}

.custom-column .list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.custom-column .col-sm-11 {
  width: 91.66666666666666%;
}

.custom-column .list-group,
custom-column-list-group {
  border: solid;
  border-width: 2px;
  -webkit-border-radius: 5px;
}

.group-management-components .list-border {
  margin: 0px;
  padding: 0px;
  border: 1px solid #ebebeb;
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.group-management-edit-model .list-border {
  margin: 0px;
  padding: 0px;
  border: 1px solid #ebebeb;
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.group-management-edit-model .modal-dialog {
  width: 60% !important;
}

.group-management-edit-model .icon_box {
  background: #dbdbdd;
  border: 1px solid #d0d0d3;
  -webkit-border-radius: 5px;
  width: 40px;
  display: block;
}

.group-management-edit-model .marB10 {
  margin-bottom: 10px;
}

.group-management-edit-model .icon_box_space {
  margin-top: 80px;
}

.group-management-edit-model .hidden {
  display: none;
}

/*Client Instruction start*/

.instruction-set .textStyle {
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  background: #0973ba;
  border: 1px #000000 solid;
  height: 35px;
  line-height: 32px;
  display: block;
  -webkit-border-radius: 5px;
}

.instruction-set .descriptionStyle {
  padding-top: 8px;
}

.content-description .help-circle {
  -webkit-border-radius: 50px;
  border: 1px #ffdb6f solid;
  padding: 2px 0px 1px;
  margin-left: 5px;
  font-size: 12px;
  color: #ffdb6f;
}

.client-instruction .edit-ins-list {
  height: 100px;
  margin-bottom: 10px;
  border: 1px #ebebeb solid;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-border-radius: 5px;
}

.client-instruction .edit-ins-list ul {
  margin: 0px;
  padding: 0px;
}

.client-instruction .edit-ins-list ul ul {
  margin: 0px;
  padding: 5px;
}

.client-instruction .edit-ins-list ul ul:hover {
  background: #f0f0f1;
  color: #000000;
  cursor: pointer;
}

.client-instruction .edit-ins-list .active {
  background: #e9e9ee;
  color: #51555d;
}

.client-instruction .buttonWidth {
  margin-left: 10px;
}

.client-instruction .active {
  background: #e9e9ee;
  color: #51555d;
}
/*Client Instruction end*/
/*Company settings ends*/
.instruction-set .textStyle {
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  background: #0973ba;
  border: 1px #000000 solid;
  height: 35px;
  line-height: 32px;
  display: block;
  -webkit-border-radius: 5px;
}

.instruction-set .descriptionStyle {
  padding-top: 8px;
}

.content-description .help-circle {
  -webkit-border-radius: 50px;
  border: 1px #ffdb6f solid;
  padding: 2px 0px 1px;
  margin-left: 5px;
  font-size: 12px;
  color: #ffdb6f;
}

.message-list .edit-ins-list {
  height: 100px;
  margin-bottom: 10px;
  border: 1px #ebebeb solid;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-border-radius: 5px;
}

.message-list .edit-ins-list ul {
  margin: 0px;
  padding: 0px;
}

.message-list .edit-ins-list ul ul {
  margin: 0px;
  padding: 5px;
}

.message-list .edit-ins-list ul ul:hover {
  background: #f0f0f1;
  color: #000000;
  cursor: pointer;
}

.message-list .edit-ins-list .active {
  background: #e9e9ee;
  color: #51555d;
}
/*Company settings ends*/
/* ===== Begin Checkbox & Radio ===== */
.checkbox-replace,
.radio-replace {
  position: relative;
  padding-left: 0;
  margin: 0;
}

.radio-inline,
.checkbox-inline {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.checkbox-replace input[type="checkbox"],
.radio-replace input[type="checkbox"],
.checkbox-replace input[type="radio"],
.radio-replace input[type="radio"] {
  opacity: 0;
}

.checkbox-replace .cb-wrapper,
.radio-replace .cb-wrapper {
  position: relative;
  display: inline-block;
  border: 1px solid #ebebeb;
  margin-bottom: 0;
  width: 16px;
  height: 15px;
  padding-left: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-replace .cb-wrapper input,
.radio-replace .cb-wrapper input {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

.checkbox-replace .cb-wrapper.disabled,
.radio-replace .cb-wrapper.disabled {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.checkbox-replace .cb-wrapper .checked,
.radio-replace .cb-wrapper .checked {
  position: absolute;
  background: #ebebeb;
  display: block;
  left: 2px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: 250ms all cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -o-transition: 250ms all cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -webkit-transition: 250ms all cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: 250ms all cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.checkbox-replace .cb-wrapper + label,
.radio-replace .cb-wrapper + label {
  position: relative;
  top: -3px;
  margin-left: 6px;
}

.checkbox-replace:hover .cb-wrapper,
.radio-replace:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03);
}

.checkbox-replace.radio-replace .cb-wrapper,
.radio-replace.radio-replace .cb-wrapper {
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.checkbox-replace.radio-replace .cb-wrapper .checked,
.radio-replace.radio-replace .cb-wrapper .checked {
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.checkbox-replace.checked .cb-wrapper .checked,
.radio-replace.checked .cb-wrapper .checked {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  filter: alpha(opacity=100);
}

.checkbox-replace .cb-wrapper,
.radio-replace .cb-wrapper {
  border: 1px solid #d2d2d2;
}

.checkbox-replace .cb-wrapper .checked,
.radio-replace .cb-wrapper .checked {
  background: black;
}

.checkbox-replace:hover .cb-wrapper,
.radio-replace:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, 0.1);
  box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, 0.1);
}

.checkbox-replace.color-primary .cb-wrapper,
.radio-replace.color-primary .cb-wrapper {
  border: 1px solid #303641;
}

.checkbox-replace.color-primary .cb-wrapper .checked,
.radio-replace.color-primary .cb-wrapper .checked {
  background: #303641;
}

.checkbox-replace.color-primary:hover .cb-wrapper,
.radio-replace.color-primary:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(48, 54, 65, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(48, 54, 65, 0.1);
  box-shadow: 0 0 0 2px rgba(48, 54, 65, 0.1);
}

.checkbox-replace.color-red .cb-wrapper,
.radio-replace.color-red .cb-wrapper {
  border: 1px solid #ee4749;
}

.checkbox-replace.color-red .cb-wrapper .checked,
.radio-replace.color-red .cb-wrapper .checked {
  background: #ee4749;
}

.checkbox-replace.color-red:hover .cb-wrapper,
.radio-replace.color-red:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(238, 71, 73, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(238, 71, 73, 0.1);
  box-shadow: 0 0 0 2px rgba(238, 71, 73, 0.1);
}

.checkbox-replace.color-blue .cb-wrapper,
.radio-replace.color-blue .cb-wrapper {
  border: 1px solid #21a9e1;
}

.checkbox-replace.color-blue .cb-wrapper .checked,
.radio-replace.color-blue .cb-wrapper .checked {
  background: #21a9e1;
}

.checkbox-replace.color-blue:hover .cb-wrapper,
.radio-replace.color-blue:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(33, 169, 225, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(33, 169, 225, 0.1);
  box-shadow: 0 0 0 2px rgba(33, 169, 225, 0.1);
}

.checkbox-replace.color-green .cb-wrapper,
.radio-replace.color-green .cb-wrapper {
  border: 1px solid #00a651;
}

.checkbox-replace.color-green .cb-wrapper .checked,
.radio-replace.color-green .cb-wrapper .checked {
  background: #00a651;
}

.checkbox-replace.color-green:hover .cb-wrapper,
.radio-replace.color-green:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(0, 166, 81, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(0, 166, 81, 0.1);
  box-shadow: 0 0 0 2px rgba(0, 166, 81, 0.1);
}

.checkbox-replace.color-gold .cb-wrapper,
.radio-replace.color-gold .cb-wrapper {
  border: 1px solid #fad839;
}

.checkbox-replace.color-gold .cb-wrapper .checked,
.radio-replace.color-gold .cb-wrapper .checked {
  background: #fad839;
}

.checkbox-replace.color-gold:hover .cb-wrapper,
.radio-replace.color-gold:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(250, 216, 57, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(250, 216, 57, 0.1);
  box-shadow: 0 0 0 2px rgba(250, 216, 57, 0.1);
}

.checkbox-replace.color-white .cb-wrapper,
.radio-replace.color-white .cb-wrapper {
  border: 1px solid #ffffff;
}

.checkbox-replace.color-white .cb-wrapper .checked,
.radio-replace.color-white .cb-wrapper .checked {
  background: #ffffff;
}

.checkbox-replace.color-white:hover .cb-wrapper,
.radio-replace.color-white:hover .cb-wrapper {
  -moz-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

.radio label,
.radio-inline label,
.checkbox label {
  min-height: 16px;
  padding-left: 0px;
  font-weight: normal;
}

.radio-inline .cb-wrapper {
  min-height: 16px;
}
/* ===== End Checkbox & Radio ===== */

/* ===== Setting Popup ===== */
.help-icon {
  font-size: 18px;
  color: #ffdb6f;
}

.help-icon:hover,
.help-icon:focus {
  color: #ffaa00;
}

.my-def-help {
  position: absolute;
  right: 15px;
  top: 6px;
  height: 20px;
  width: 20px;
  text-align: center;
}

.upload-help {
  vertical-align: sub;
  padding-left: 5px;
}
/* ===== Begin Common Style ===== */
*:focus {
  outline: none !important;
}

h3 {
  font-size: 21px;
}

.marL03 {
  margin-left: 3px;
}

.marL05 {
  margin-left: 5px;
}

.marL06 {
  margin-left: 6px;
}

.marL20 {
  margin-left: 20px;
}

.marL30 {
  margin-left: 30px;
}

.marT0 {
  margin-top: 0px;
}

.marT2 {
  margin-top: 2px;
}

.marT5 {
  margin-top: 5px;
}

.marT10 {
  margin-top: 10px !important;
}

.marT20 {
  margin-top: 20px;
}

.marT14 {
  margin-top: 14px;
}

.marR03 {
  margin-right: 3px;
}

.marR05 {
  margin-right: 5px;
}

.marB05 {
  margin-bottom: 5px;
}

.marB15 {
  margin-bottom: 15px;
}

.marB25 {
  margin-bottom: 25px;
}

.padL00 {
  padding-left: 0px;
}

.padL5 {
  padding-left: 5px;
}

marLR03 {
  margin-right: 3px;
  margin-left: 3px;
}

.padR30 {
  padding-right: 30px;
}

.padT05 {
  padding-top: 5px;
}
.padT04 {
  padding-top: 4px;
}
.padB04 {
  padding-bottom: 4px;
}

.padB15 {
  padding-bottom: 15px;
}

.padT06 {
  padding-top: 6px;
}

.padT25 {
  padding-top: 25px;
}

.padL05 {
  padding-left: 5px;
}

.padL08 {
  padding-left: 8px;
}

.padL15 {
  padding-left: 15px;
}

.padL10 {
  padding-left: 10px;
}

.padR00 {
  padding-right: 0px;
}

.padR02 {
  padding-right: 2px;
}

.padR05 {
  padding-right: 5px;
}

.padR15 {
  padding-right: 15px;
}

.padR20 {
  padding-right: 20px !important;
}

.padT10 {
  padding-top: 10px;
}

.padB10 {
  padding-bottom: 10px;
}

.padB5 {
  padding-bottom: 5px;
}

.txt-ar {
  text-align: right;
}

.txt-ac {
  text-align: center;
}

.dis-inline {
  display: inline-block !important;
}

.btn-only {
  padding: 6px 12px !important;
}

input:focus {
  outline: none;
}

.float-none {
  float: none;
}

.btnuploadscancopy {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  background-color: transparent;
  color: transparent;
  width: 40%;
  height: 100%;
}

.btnuploadscancopyw100 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  background-color: transparent;
  color: transparent;
  width: 100%;
  height: 100%;
}

table th {
  border-bottom-width: 0px !important;
}

table td {
  vertical-align: middle !important;
  font-size: 12px;
  font-weight: 500;
}

table .tbl-btn-danger {
  font-size: 8px;
  border: none;
  padding: 7px 9px 5px;
  margin-left: 3px;
}

.menu-toggle-icon {
  font-size: 11px;
  width: 25px;
}

.height90 {
  height: 90%;
}

.width100 {
  width: 100%;
}

.min-height200 {
  min-height: 200px;
}

.va-supper {
  vertical-align: super;
}

.va-sub {
  vertical-align: sub;
}

.modal-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.modal-header .modal-title {
  color: #1a8fbf;
  font-size: 16px;
  font-weight: bold;
}

.h3Font {
  font-size: 14px;
}

.fontS15 {
  font-size: 15px;
}

.modal-header .close {
  font-size: 21px;
  padding-top: 1.25rem;
  font-family: Tahoma;
}

.pos-relative {
  position: relative;
}

.compulsory-star1 {
  color: #c50505;
  font-size: 6px;
  position: absolute;
  right: -10px;
  top: 12px;
}

.compulsory-star-only {
  color: #c50505;
  font-size: 6px;
}

.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  padding: 6px 12px !important;
  height: 34px;
  line-height: 24px;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate(-300px, 0) scale(4);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
}

.fileinput {
  margin-bottom: 9px;
  display: inline-block;
}

.form-control[readOnly] {
  cursor: not-allowed;
  background-color: #fefeff;
  box-shadow: none;
}

.readonly-background-color {
  background-color: #eeeeee !important;
}

/* ===== End Common Style ===== */
.settings-checkbox .checkbox-replace {
  display: inline-block;
}
/* ===== Begin Header, Leftside, Content Style ===== */
.wrapper {
  width: 100%;
  display: table;
  height: 100%;
  table-layout: fixed;
}

.navbar-container {
  display: table-row;
  border-bottom: 1px solid #ebebec;
  background: #fff;
  min-height: 57px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 150;
}

.navbar-container .navbar-brand-logo {
  display: block;
  padding: 6px 10px;
}

.navbar-container .navbar-brand-logo img {
  max-width: 145px;
}

.navbar-container .right-menu {
  text-align: right;
  padding: 0px;
}

.navbar-container .right-menu .nav li {
  display: inline-block;
}

.navbar-container .right-menu .nav li a {
  color: #51555d;
  padding: 20px 15px;
  display: inline-block;
  text-decoration: none;
}

.navbar-container .right-menu .nav li a:hover {
  color: #337ab7;
}

.navbar-container .right-menu .nav li span .dropdown-menu {
  background: #f5f5f6;
  border: none;
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.navbar-container .right-menu .nav li span .dropdown-menu li {
  display: block;
}

.navbar-container .right-menu .nav li span .dropdown-menu li:nth-child(2) {
  border-bottom: 1px #e5e5e5 solid;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a {
  padding: 10px 10px;
  font-size: 12px;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a i {
  padding-right: 10px;
  font-size: 14px;
  color: #17a2b8;
  text-shadow: white 1px 0px 1px;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a i:hover {
  color: #bee5eb;
  text-shadow: grey 0px 1px 1px;
}

.navbar-container .right-menu .nav li span .dropdown-menu li a i {
  padding-right: 10px;
}

.navbar-container .right-menu .nav li .navbar-toggle {
  float: none;
  margin-right: 5px;
}

.navbar-container .right-menu .nav li .navbar-toggle {
  float: none;
  margin-right: 5px;
}

.page-container {
  width: 100%;
  display: table;
  height: 100%;
  table-layout: fixed;
}


.main-content {
  width: 100%;
  display: table-cell;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 57px 15px;
}

.footer {
  border-top: 1px solid #ebebec;
  background: #ffffff;
  position: fixed;
  bottom: 0px;
  left: 225px;
  right: 0px;
  z-index: 15;
}

.footer .copyright {
  padding: 10px 15px;
}

.finish-processing-modal .modal-dialog {
  width: 40%;
}

div.icon-div {
  padding: 4px 10px 5px 40px;
  position: relative;
  background: #fff;
  color: #333;
  cursor: pointer;
  border: 1px solid #d2d2d2;
  border-radius: 4px 0 0 4px;
}

div.icon-div i {
  position: absolute;
  left: 0;
  padding: 8px;
  top: 0;
  font-size: 10px;
  background: #e6e6e6;
}

.elementDragging {
  z-index: 1000;
  width: 42%;
  border: 0px;
  transform: scale(0.85);
  transition: all 100ms;
}

.esignatureControlbackground {
  position: absolute !important;
  width: 90%;
}

.groupPanel {
  max-height: inherit !important;
}

.groupCanvas {
  display: block;
  height: 160px;
  width: 160px;
}

.esignControlWrapper {
  position: relative;
  display: inline-flex;
}

.esignControlPageWrapper {
  text-align: center;
}

.esignEROStamp {
  width: 85px;
  /*height: inherit;*/
}

.esignEROStamp img {
  height: 100%;
  width: 100%;
}

.esignEROStamp .remove-handle {
  position: absolute;
}

.esignControlDropFocus {
  cursor: crosshair;
}

.groupMenuItemFocus {
  -webkit-box-shadow: 0px 0px 56px 5px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 56px 5px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 0px 56px 5px rgba(0, 0, 0, 0.39);
}

.groupItemDragging {
  cursor: move;
  z-index: 99 !important;
}

.groupItemDragging.dragging {
  margin-left: 65px;
  margin-top: -95px;
  width: 75px !important;
}

.groupItemDragging .dragging {
  height: auto !important;
  width: auto !important;
}

.groupItemDragging .dragging .groupCanvas {
  height: 91px !important;
  width: 70px !important;
  box-shadow: 0px 0px 10px #ffc400;
}

.groupItemDragging .dragging a {
  display: none !important;
}

.groupItemDragging .dragging input {
  display: none !important;
}

.groupItemDragging .dragging label {
  display: none !important;
}

.groupTabContentDragging {
  overflow-y: initial !important;
}

.groupPanelOuterDragging {
  overflow: initial !important;
}

#trasmittals-panel-all .canvas-outer,
#transmittals-panel .canvas-outer {
  box-shadow: 0px 0px 10px #ffc400;
}

#efile-panel-all .canvas-outer,
#efile-panel .canvas-outer {
  box-shadow: 0px 0px 10px #f1af5e;
}

#vouchers-panel-all .canvas-outer,
#vouchers-panel .canvas-outer {
  box-shadow: 0px 0px 10px #e59fa1;
}

#k1-panel-all .canvas-outer,
#k1-panel .canvas-oute {
  box-shadow: 0px 0px 10px #dd88ee;
}

#tax-returns-panel-all .canvas-outer,
#tax-returns-panel .canvas-outer {
  box-shadow: 0px 0px 10px #8dc63f;
}

#invoice-panel-all .canvas-outer,
#invoice-panel .canvas-outer {
  box-shadow: 0px 0px 10px #a0bdfa;
}

#deleted-panel-all .canvas-outer,
#deleted-panel .canvas-outer {
  box-shadow: 0px 0px 10px #000;
}

.thumbnailFormName {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.thumbnail-view-canvas {
  display: block;
  height: 80px;
  width: 100px;
}

/* ===== Begin Responsive ===== */
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
 
  .main-content {
    display: block;
    padding: 60px 15px 0px 15px;
  }

  .footer {
    position: relative;
    left: 0px;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .main-content {
    display: block;
    padding: 57px 15px 0px;
  }

  .footer {
    position: relative;
    left: 0px;
  }

  .navbar-container .nav li a {
    padding: 20px 15px;
  }

  .navbar-container .navbar-brand-logo {
    padding: 10px 0px;
  }

  .navbar-container .navbar-brand-logo img {
    max-width: 125px;
  }

  .mobile-hidden {
    display: none;
  }

  .visible {
    display: block !important;
  }
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
  .main-content {
    display: block;
    padding: 57px 15px 0px;
  }

  .footer {
    position: relative;
    left: 0px;
  }

  .mob-hide-txt {
    display: none;
  }

  .navbar-container .nav li a {
    padding: 20px 15px;
  }

  .navbar-container .navbar-brand-logo {
    padding: 10px 0px;
  }

  .navbar-container .navbar-brand-logo img {
    max-width: 125px;
  }

  h3 {
    font-size: 18px;
  }

  .mobile-hidden {
    display: none;
  }

  .visible {
    display: block !important;
  }
}

.filters {
  display: inline-block;
  position: absolute;
  right: 0px;
  z-index: 10;
}

.filters .dropdown-menu li button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  box-shadow: none;
}

.filters .dropdown-menu li button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.filters .dropdown-menu li button i {
  padding: 5px;
  font-size: 14px;
  margin-left: 10px;
}

.filter-icon {
  padding: 6px 6px 6px 28px !important;
}

.btn-filter {
  padding: 6px 8px;
}

.btn-border {
  border: 1px #dddddd solid;
  background: #ffffff;
}

.btn-border:hover {
  background: #f5f5f6 !important;
}

.doc-pagination .pagination {
  margin: 0px 0px 5px 0px !important;
}

.hypControl {
  padding-left: 32px !important;
  padding-top: 0px !important;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
}

.btn-border {
  border: 1px #dddddd solid;
  background: #ffffff;
}

.btn-border:hover {
  background: #f5f5f6 !important;
}

.doc-pagination .pagination {
  margin: 0px 0px 5px 0px !important;
}

.send_voc_reminders {
  position: relative;
  padding-left: 35px;
}

.send_voc_reminders .svr_checkbox {
  position: absolute;
  top: 10px;
  left: 0px;
  height: 20px;
  width: 35px;
}

/*Invoice tab CSS start*/
.invoices-tab .form-horizontal .display-invoice-button {
  display: none;
}
/*Invoice tab CSS end*/

/*Process return tab CSS start*/
.process-return-container .clearfix .parent-tab-head .dispaly-invoice-tab {
  display: none;
}

.process-return-container .clearfix .parent-tab-head .dispaly-k1-tab {
  display: none;
}
/*Process return tab CSS end*/

/* UserManagement */
.member-of .group_members ul {
  margin: 0px;
  padding: 0px;
  border: 1px solid #ebebeb;
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.member-of .group_members ul li {
  margin: 0px;
  padding: 5px 7px;
  list-style-type: none;
  margin-bottom: 1px;
}

.member-of .group_members ul li:hover {
  background: #f7f7f7;
  cursor: pointer;
}

.member-of .icon_box_space {
  margin-top: 80px;
  margin-left: 50px;
}

.member-of .icon_box {
  background: #dbdbdd;
  border: 1px solid #d0d0d3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  width: 40px;
  display: block;
}

.member-of .icon_box i {
  font-size: 26px;
}

.member-of .icon_box:hover {
  background: #d0d0d3;
  color: #333;
  cursor: pointer;
}

.member-of .activeUser {
  background: #e9e9ee !important;
  color: #51555d !important;
}

.addUserModal .modal-header {
  border-bottom: none;
}

/*.editUserModal .modal-header {
    border-bottom: none
}*/

.memberOfModal .modal-header {
  border-bottom: none;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .addUserModal .modal-dialog {
    width: 70% !important;
  }

  .memberOfModal .modal-dialog {
    width: 60% !important;
  }

  .editUserModal .modal-dialog {
    width: 60% !important;
  }

  .ero-signature .modal-dialog {
    width: 60% !important;
  }
}
/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .addUserModal .modal-dialog {
    width: 70% !important;
  }

  .memberOfModal .modal-dialog {
    width: 60% !important;
  }

  .editUserModal .modal-dialog {
    width: 75% !important;
  }
  .ero-signature .modal-dialog {
    width: 70% !important;
  }
}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .addUserModal .modal-dialog {
    width: 90% !important;
  }

  .memberOfModal .modal-dialog {
    width: 85% !important;
  }

  .editUserModal .modal-dialog {
    width: 100% !important;
  }

  .ero-signature .modal-dialog {
    width: 90% !important;
  }
}

/* UserManagement end*/

/* MySettings */
.my-settings .userscanneruploadsign {
  padding: 2%;
  border: 1px solid;
  border-radius: 9px;
  min-height: 100px;
  min-width: 300px;
  max-width: 300px;
}

.ero-signature .userscanneruploadsign {
  padding: 2%;
  border: 1px solid;
  border-radius: 9px;
  min-height: 100px;
  min-width: 300px;
  max-width: 300px;
}

.signature-delegation .group_members ul {
  margin: 0px;
  padding: 0px;
  border: 1px solid #ebebeb;
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.signature-delegation .group_members ul li {
  margin: 0px;
  padding: 5px 7px;
  list-style-type: none;
  margin-bottom: 1px;
}

.signature-delegation .group_members ul li:hover {
  background: #f7f7f7;
  cursor: pointer;
}

.signature-delegation .icon_box_space {
  margin-top: 80px;
}

.signature-delegation .icon_box {
  background: #dbdbdd;
  border: 1px solid #d0d0d3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  width: 40px;
  display: block;
}

.signature-delegation .icon_box i {
  font-size: 26px;
}

.signature-delegation .icon_box:hover {
  background: #d0d0d3;
  color: #333;
  cursor: pointer;
}

.signature-delegation .activeUser {
  background: #e9e9ee !important;
  color: #51555d !important;
}

/* MySettings end*/
.client-instruction-dropdown-width {
  width: 100%;
}

.btnuploadsettings {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  background-color: transparent;
  color: transparent;
  width: 100%;
  height: 100%;
}
/* MySettings end*/

/* Delivered Returns */
.delivered-return-download-link {
  font-size: 14px;
  font-weight: bold;
  color: #88c656;
  cursor: pointer;
  list-style-type: none;
}

.preparer-message .display-button {
  display: none;
}

/* filter start*/
.filter-dropdown {
  width: 360px;
}

.filter-name {
  width: 100%;
  padding: 3px;
}
/*Filter ends */
/*client Tracking start*/
.client-tracking-modal .modal-dialog {
  width: 900px;
  margin: 30px auto;
}
/*client Tracking end*/

/*reminder update start*/
.reminder-update-modal .modal-dialog {
  width: 830px;
}
/*reminder update end*/

/*upload form start*/
.upload-forms-modal .modal-content {
  width: 1135px;
}

.upload-forms-modal .modal-dialog {
  width: 1142px;
}
/*upload form end*/

/* Client info tab overpayment applied start */
.overpayment-applied-hide {
  display: none;
}
/* Client info tab overpayment applied end */
/*upload form end*/

/*resend acces link start*/
.button-group .list-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.list-disabled {
  pointer-events: none;
  opacity: 0.6;
}
/*resend acces link end*/
.k1-tab .btn-new {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 48%,
    rgba(236, 236, 236, 1) 48%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ececec',GradientType=0 );
  border: 1px #4c4c4c solid;
  color: #4c4c4c;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  padding: 7px 0px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
}

/* Finish processing start*/
.finish-processing-menu-name {
  padding-left: 10px;
}
/* Finish processing end*/
#tax-year-dropdown + ul.dropdown-menu {
  min-width: 10px;
}

.company-logo {
  max-width: 145px !important;
  height: 45px;
}

.company-logo-header {
  width: 215px !important;
  float: left;
}

/*Invoice tab*/
.align-button {
  text-align: center;
}
/*Invoice tab*/

/*ClientInfo*/
.element-padding {
  padding: 1px;
}
/*ClientInfo*/

/*EFile tab*/
.legend {
  background-color: #ebebeb;
  padding: 15px;
  margin-bottom: 20px;
  color: #000;
  font-size: inherit;
}
/*EFile tab*/

div.filepicker {
  /*background-image: url("../../images/droparea.PNG");  image not coming if giving image path directly*/
  height: 180px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
}

div.dz-message {
  display: none;
}

.content-Loader::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
  z-index: 100;
}

.content-loader-text {
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  font-weight: bold;
  font-size: 100%;
  margin-top: 25%;
}

.overlay-loader {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.overlay-loader::after {
  content: "";
  display: block;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #1a8fbf;
  border-top-color: #f5f5f5;
  border-width: 2px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
  position: absolute;
}

.overlay-loader-text {
  text-align: center;
  /*background-color: rgba(255,255,255,0.0);*/
  /*font-weight: bold;*/
  font-size: 110%;
  color: #aaaaaa;
  position: relative;
  top: 50%;
  left: 0;
  margin-top: 4%;
}

#process-return-dialog .modal-dialog {
  width: 100%;
  margin: auto auto;
  padding: 10px;
}

.panel-heading-icon {
  color: #ccc;
  margin-right: 10px;
  text-shadow: #fcfcfc 0px 2px 1px;
}

.modal-heading-icon {
  color: #555;
  margin-right: 10px;
  font-size: 16px;
  vertical-align: text-top;
}

.form-group-tile {
  background: white;
  border-style: solid solid solid solid;
  padding: 16px 15px;
  margin-top: 10px;
  border-width: 2px 2px 2px 32px;
  border-radius: 5px;
}

.form-group-tile i {
  margin-right: 15px;
  margin-left: -39px;
  font-size: 14px;
  color: #333;
  text-shadow: #f7f7f7 0px 1px 2px;
  /*vertical-align: text-top;*/
}

.form-group-header i {
  margin-right: 15px;
  vertical-align: text-top;
  color: #333 !important;
  text-shadow: white 0px 1px 1px !important;
}

.page-thumbnail {
  margin-bottom: 10px;
  box-shadow: #808080 4px 4px 6px;
}

.page-thumbnail .panel-heading {
  padding: 5px 10px;
}

.right-pointer {
  border: solid;
  border-width: 2px 2px 2px 32px;
  position: relative;
  font-size: 13px;
  background: white;
  height: 50px;
  line-height: 40px;
  margin-bottom: 20px;
  vertical-align: middle;
  color: white;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px 5px 5px 5px;
  width: 91% !important;
}

.right-pointer span {
  color: #888;
  margin-left: 10px;
}

.right-pointer.None {
  border-color: grey;
}

.right-pointer.Vouchers {
  border-color: #e59fa1;
}

.right-pointer.EFile {
  border-color: #f1af5e;
}

.right-pointer.Deleted {
  border-color: #595959;
}

.right-pointer.Transmittals {
  border-color: #ffc400;
}

.right-pointer.TaxReturns {
  border-color: #8dc63f;
}

.right-pointer.Invoice {
  border-color: #a0bdfa;
}

.right-pointer.K1 {
  border-color: #dd88ee;
}

.arrow-icon {
  font-size: 16px;
  color: #333;
  text-shadow: #f7f7f7 0px 1px 2px;
  margin-top: 14px;
}

.arrow-right:before {
  content: "";
  position: absolute;
  right: -30px;
  top: -2px;
  border-top: 26px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 25px solid transparent;
  width: 31px;
}

.arrow-right:after {
  content: "";
  position: absolute;
  right: -31px;
  top: 0px;
  border-top: 24px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 23px solid white;
  width: 32px;
}

.arrow-right.None:before {
  border-left-color: grey;
}

.arrow-right.Transmittals:before {
  border-left-color: #ffc400;
}

.arrow-right.Vouchers:before {
  border-left-color: #e59fa1;
}

.arrow-right.EFile:before {
  border-left-color: #f1af5e;
}

.arrow-right.Deleted:before {
  border-left-color: #595959;
}

.arrow-right.TaxReturns:before {
  border-left-color: #8dc63f;
}

.arrow-right.Invoice:before {
  border-left-color: #a0bdfa;
}

.arrow-right.K1:before {
  border-left-color: #dd88ee;
}

/* thumbnail page */

.item.list-group-item {
  float: none;
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
}

.item.list-group-item:nth-of-type(odd):hover,
.item.list-group-item:hover {
  background: #428bca;
}

.item.list-group-item .list-group-image {
  margin-right: 10px;
}

.item.list-group-item .thumbnail {
  margin-bottom: 0px;
}

.item.list-group-item .caption {
  padding: 9px 9px 0px 9px;
}

.item.list-group-item:nth-of-type(odd) {
  background: #eeeeee;
}

.item.list-group-item:before,
.item.list-group-item:after {
  display: table;
  content: " ";
}

.item.list-group-item img {
  float: left;
}

.item.list-group-item:after {
  clear: both;
}

.list-group-item-text {
  margin: 0 0 11px;
}

.thumbnail {
  margin-bottom: 20px;
  padding: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: grey 1px 1px 5px;
}

.thumbnail .caption {
  padding: 9px;
  color: #333;
  background-color: gainsboro;
}

.thumbnail .caption h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  text-shadow: white 0px 1px 0px;
}

.thumbnail .caption p {
  font-weight: 900;
  margin-top: 6px;
  color: #888181;
  text-shadow: white 0px 1px 0px;
}
/* thumbnail page */
.fillWidth {
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.button-dropdown-width {
  max-width: 175px;
  min-width: 172px;
}

.disable-tinymce-div {
  pointer-events: none;
}

dl {
  margin-top: 15px;
  font: inherit;
}

dl dt {
  float: left;
  width: 100px;
  text-align: left;
  margin-left: 15px;
}

dt dd {
  margin-left: 115px;
}

pre {
  display: block;
  /*font-family: monospace;*/
  white-space: pre;
  margin: 1em 0;
}

/* Report Filter Changes Starts here*/
ul.filter-item {
  margin: 0px 11px 0px 11px;
}

ul.filter-item i {
  margin: 3px;
  cursor: pointer;
}

ul.filter-item table:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

ul.filter-item table.active {
  background-color: #dfdfea;
}

/* Report Filter Changes Ends here*/

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: -95%;
  max-width: 180px;
  margin-top: -6px;
  margin-right: -1px;
  -webkit-border-radius: 6px 6px 6px 6px;
  -moz-border-radius: 6px 6px 6px 6px;
  border-radius: 6px 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: left;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 5px 5px 0;
  border-right-color: #999;
  margin-top: 5px;
  margin-right: 10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}

.dropdown-submenu.float-left {
  float: none;
}

.dropdown-submenu.float-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 6px 6px 6px;
  -moz-border-radius: 6px 6px 6px 6px;
  border-radius: 6px 6px 6px 6px;
}

.dropdown-menu-right {
  margin-left: 0;
}

.dropdown-menu-ul {
  right: 100% !important;
  max-width: none !important;
  left: unset !important;
}

.dropdown-submenu-right > a:after {
  float: right !important;
}

.defaul-filter-lable {
  padding: 3px 20px;
}

.applied-filter {
  margin-right: 15px;
  font-style: italic;
}

.ddl-icon {
  margin-right: 10px;
  margin-left: -10px;
  color: #17a2b8;
  width: 15.75px;
}

.ddl-icon:hover {
  color: #bee5eb;
  text-shadow: grey 0px 1px 1px;
}

.padB7 {
  padding-bottom: 7px !important;
}

.label-blue {
  background-color: #00b0f0 !important;
  color: #fff;
}

.custom-toggle-switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 75px;
  height: 25px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, #eeeeee, #ffffff 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  box-sizing: content-box;
}

.custom-toggle-switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  box-sizing: content-box;
}

.custom-toggle-switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
}

.custom-toggle-switch-sidelabel {
  color: #9098a7;
  padding-right: 5px;
  display: inline-block;
  padding-top: 5px;
  font-size: 12px;
}

.custom-toggle-switch-label:before,
.custom-toggle-switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  box-sizing: content-box;
}

.custom-toggle-switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaaaaa;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.custom-toggle-switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #ffffff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.custom-toggle-switch-input:checked ~ .custom-toggle-switch-label {
  background: #21a9e1;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-input:checked ~ .custom-toggle-switch-label:before {
  opacity: 0;
}

.custom-toggle-switch-input:checked ~ .custom-toggle-switch-label:after {
  opacity: 1;
}

.custom-toggle-switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 21px;
  height: 22px;
  background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #ffffff);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.custom-toggle-switch-input:checked ~ .custom-toggle-switch-handle {
  left: 56px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

/* Transition
========================== */
.custom-toggle-switch-label,
.custom-toggle-switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

/*  
========================== */
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput--focused {
  border-color: #a5d24a;
}

.react-tagsinput-tag {
  background-color: #fff2ca;
  border-radius: 2px;
  border: 1px solid #ffc107;
  color: black;
  display: inline-block;
  font-family: sans-serif;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 2px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100%;
}

/* BUG 23653, Fix for: Some part of filter and export to excel button container 
	is overlapped by the react-bs-table-tool-bar div*/
/*.react-bs-table-tool-bar > div.row > div:first-child {
    display: none;
}*/

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-title i {
  color: #aaa;
  margin-right: 10px;
  text-shadow: #ffffff -2px 2px 1px;
}

.accordion .card {
  overflow: visible;
}

.h5,
h5 {
  font-size: 1.25rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.window {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 700px;
  height: 400px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  z-index: 5;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  transition: top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
    width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s,
    -webkit-transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1);
  transition: top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
    transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
    width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s;
  transition: top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
    transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
    width 0.5s 0.5s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s,
    -webkit-transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1);
}

.window .window-controls {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  height: 30px;
  width: 60px;
}

.window .window-controls .maximize {
  background-color: #e8eaec;
  position: absolute;
  top: -30px;
  opacity: 0;
  left: 0;
  z-index: 9;
  width: 200px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #b4b9be;
  cursor: pointer;
  transition: opacity 0.3s 0.5s, top 0s 0.8s;
}

.window .window-controls .close,
.window .window-controls .minimize {
  opacity: 0.5;
  cursor: pointer;
}

.window .window-controls .close:hover,
.window .window-controls .minimize:hover {
  opacity: 0.7;
}

.window .window-controls .close:active,
.window .window-controls .minimize:active {
  opacity: 1;
}

.window .window-controls .close {
  width: 30px;
  height: 30px;
  float: left;
}

.window .window-controls .close:before,
.window .window-controls .close:after {
  position: absolute;
  content: "";
  width: 12px;
  height: 2px;
  background-color: black;
  position: absolute;
  border-radius: 2px;
  top: 14px;
  left: 9px;
}

.window .window-controls .close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.window .window-controls .close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.window .window-controls .minimize {
  width: 30px;
  height: 30px;
  float: left;
  position: relative;
}

.window .window-controls .minimize:before,
.window .window-controls .minimize:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background-color: black;
  position: absolute;
  border-radius: 2px;
  top: 14px;
  left: 10px;
}

.window .window-controls .minimize:before {
  -webkit-transform: translateX(-3px) rotate(45deg);
  transform: translateX(-3px) rotate(45deg);
}

.window .window-controls .minimize:after {
  -webkit-transform: translateX(3px) rotate(-45deg);
  transform: translateX(3px) rotate(-45deg);
}

.window .window-sidebar {
  width: 200px;
  height: 400px;
  background-color: #e8eaec;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 30px;
  z-index: 4;
}

.window .window-sidebar .content-block {
  height: 60px;
  width: 100%;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.window .window-sidebar .content-block:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  top: 10px;
  left: 10px;
  opacity: 0.05;
}

.window .window-sidebar .content-block:after {
  position: absolute;
  content: "";
  width: 120px;
  height: 10px;
  background-color: black;
  top: 25px;
  left: 60px;
  opacity: 0.05;
  border-radius: 5px;
}

.window .window-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  height: 400px;
}

.window .window-content:before {
  position: absolute;
  content: "";
  width: 460px;
  height: 200px;
  background-color: black;
  top: 20px;
  left: 20px;
  opacity: 0.05;
  border-radius: 5px;
}

.window .window-content:after {
  position: absolute;
  content: "";
  width: 380px;
  height: 10px;
  background-color: black;
  top: 240px;
  left: 60px;
  opacity: 0.05;
  border-radius: 5px;
}

.minimized .window {
  top: 100%;
  -webkit-transform: translate(-50%, -30px);
  transform: translate(-50%, -30px);
  width: 200px;
  opacity: 0.5;
}

.minimized .window:hover {
  opacity: 0.75;
}

.minimized .window .maximize {
  top: 0;
  opacity: 1;
  transition: opacity 0.3s 0.5s, top 0s 0.5s;
}

.text {
  position: fixed;
  top: 50%;
  left: 50%;
  color: white;
  -webkit-transform: translate(350px, -200px);
  transform: translate(350px, -200px);
  z-index: 10;
  padding-left: 20px;
}

.text .title {
  font-size: 20px;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}

.text .name {
  display: block;
  opacity: 0.5;
}

.text-italic {
  font-style: italic !important;
}

/*   Process Return Modal  */

.modal-header .modal-header-button {
  padding: 3px 5px;
  float: right;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  opacity: 0.4;
  margin-right: 2px;
  border: 1px solid transparent;
  background-color: transparent;
}

.modal-header .modal-header-button:hover:not([disabled]) {
  background-color: #efeaea;
  text-shadow: 0 1px 0 #fff;
  border: 1px solid #c1c1c1;
}

.custom-modal .custom-modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

.custom-modal .modal-title:hover {
  cursor: move;
}

.custom-modal .bottom-bar {
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  z-index: 2000;
  width: 10%;
  position: absolute;
  cursor: pointer;
}

.custom-modal .bottom-bar-item {
  background-color: #4caf50;
  color: white;
  height: 33px;
  border-radius: 3px 3px 0px 0px;
  align-items: stretch;
  margin-right: 10px;
  padding: 9px;
  display: flex;
}

.custom-modal .bottom-bar-item-icon {
  float: right;
  margin-left: 15px;
}

.custom-modal .bottom-bar-item-title {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.custom-modal .bottom-bar-item:hover {
  background-color: #76bd79;
}

.custom-modal .modal-footer {
  min-height: 60px;
  bottom: 0px;
  position: absolute;
  width: inherit;
}

.custom-modal .modal-body {
  height: 88%;
  overflow: auto;
  padding: 10px !important;
}

.custom-modal .modal-header {
  border-bottom: 1px solid #e5e5e5;
}

.custom-modal .bottom-bar-item-title-icon {
  color: #ffeb3b;
  line-height: initial;
}

.custom-list-group-item-singleline {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px dotted #ddd;
  height: 37px;
  white-space: nowrap;
}

.custom-list-group-item-singleline.custom-active,
.custom-list-group-item-singleline.custom-active:hover,
.custom-list-group-item-singleline.custom-active:focus {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #b8daff;
}

.modalIcon {
  color: grey;
  margin-right: 10px;
  vertical-align: text-bottom;
  font-size: 20px;
}

.custom-list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px dotted #ddd;
}

/* custom toggle button css*/
.switch {
  position: relative;
  /* Fixed Calendar issue*/
  /*display: inline-block;*/
  width: 44px;
  height: 18px;
  margin: 0 0 10px 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/**/

/*Hiding tiny-mce status bar*/
.mce-statusbar .mce-container-body {
  display: none !important;
}
/*End Hiding tiny-mce status bar*/

/*Start custom-check-box*/
.custom-checkbox {
  padding-left: 3px;
}

.custom-checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}

.custom-checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.custom-checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
}

.custom-checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.custom-checkbox input[type="checkbox"]:checked + label::after {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  font-weight: 900;
}

.custom-checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.custom-checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.custom-checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.custom-checkbox.checkbox-inline {
  margin-top: 0;
}

.custom-checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}

.custom-checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.highlighted .right-pointer {
  border-color: red;
  border-style: dashed;
}

.draggable-ghost-list-item-icon {
  float: left;
  margin-right: 10px;
  margin-top: 3px;
}

.draggable-ghost-list-center {
  padding-top: 50%;
}

/*End custom-check-box*/

.appTitle {
  display: inline;
  vertical-align: middle;
  margin-left: 15px;
  font-size: 26px;
  /*text-shadow: #585858 -1px -1px 1px;
    color: #dedede;*/
}

.wordbreak {
  word-break: break-word;
}

/* Left Panel style starts here */
.bookmarksPane .card button,
.accordion .card button {
  border: none;
}
.bookmarksPane .card .card-title,
.accordion .card .card-header,
.accordion .card .card-header .card-title {
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
  font-weight: 400;
  margin: 0;
}
.bookmarksPane .accordion .card .card-header {
  display: flex;
  justify-content: space-between;
}
.bookmarksPane .accordion .card .card-header .float-right {
  display: inline-block;
  margin-top: -3px;
}

.card-header {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

/* Right Panel style starts here */
/*Right Panel style starts here*/
.left-panel,
.right-panel {
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bookmarksPane {
  overflow-y: auto;
  overflow-x: hidden;
}

.bookmarksPane .panel,
.right-panel .panel {
  border-radius: 0px !important;
  margin-bottom: 5px !important;
}

.right-panel .panel-title {
  cursor: pointer;
}

.bookmarksPane .panel-default .panel-heading,
.right-panel .panel-default .panel-heading {
  color: #fff;
  background-color: #c0bfcf;
  border-color: #ddd;
  border-radius: 0px !important;
  background-color: #eeeeee;
  color: #696969;
}

.right-panel .panel-title i {
  color: #808080;
  margin-right: 10px;
  text-shadow: 1px 1px 1px white;
}
/*Right Panel style ends here*/

ul[aria-labelledby="move-to-dropdown"] {
  z-index: 100000;
}

.user-management-content table td.button-cell .button-group > .btn-toolbar {
  float: left;
}

.user-management-content table td.button-cell {
  overflow: visible;
}

.user-management-content .react-bs-container-body {
  overflow: inherit;
}

.pageTitle:hover {
  text-decoration-color: #337ab7 !important;
  text-decoration: underline;
}

.btn-plus {
  padding: 4px 4px;
  font-size: 12px;
  text-shadow: 0px 1px 0px #ebebeb;
}

.btn-primary-color {
  background-color: #337ab7 !important;
  border-color: #007bff !important;
}

.padLR-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.padLR-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.marB0 {
  margin-bottom: 0px;
}

.padL8 {
  padding-left: 8px;
}

.trash-icon {
  color: #1a8fbf;
}

.cursor-pointer {
  cursor: pointer;
}

.textalign-center {
  text-align: center;
}

.textalign-right {
  text-align: right;
}

.marL0 {
  margin-left: 0px;
}

.padTB7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.marL8 {
  margin-left: 8px;
}

/*uploaded files table cell styles start*/
.uploaded-file-remove-btn {
  padding-top: 14px;
  padding-bottom: 14px;
}

.uploaded-file-remove-btn i {
  padding: 6px 6px;
}

.uploaded-file-progressbar {
  height: 28px;
  margin-bottom: 5px;
  margin-top: 5px;
}

/*uploaded files table cell styles End*/

/*Start Price Tag*/

.tags {
  float: left;
  position: relative;
  width: 70px;
  height: 26px;
  margin-left: 20px;
  padding: 0 12px;
  line-height: 27px;
  background: rgb(20, 112, 137);
  color: white;
  font-size: 15px;
  text-decoration: none;
  text-align: center;
}

.tags:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.tags:after {
  content: "";
  position: absolute;
  top: 11px;
  width: 4px;
  height: 4px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  -moz-box-shadow: -1px -1px 2px #004977;
  -webkit-box-shadow: -1px -1px 2px #004977;
  box-shadow: -1px -1px 2px #004977;
}

.left-tag {
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}

.left-tag:before {
  left: -15px;
  border-color: transparent rgb(20, 112, 137) transparent transparent;
  border-width: 15px 15px 15px 0;
}

.left-tag:after {
  left: -2px;
}

.right-tag {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}

.right-tag:before {
  right: -13px;
  border-color: transparent transparent transparent rgb(20, 112, 137);
  border-width: 13px 0 13px 13px;
}

.right-tag:after {
  right: -2px;
}

.tags[class*="#147089"] {
  background: rgb(20, 112, 137);
}

.left-tag[class*="#147089"]:before {
  border-color: transparent rgb(20, 112, 137) transparent transparent;
}

.right-tag[class*="#147089"]:before {
  border-color: transparent transparent transparent rgb(20, 112, 137);
}

.tags[class*="#E86650"] {
  background: rgb(232, 102, 80);
}

.left-tag[class*="#E86650"]:before {
  border-color: transparent rgb(232, 102, 80) transparent transparent;
}

.right-tag[class*="#E86650"]:before {
  border-color: transparent transparent transparent rgb(232, 102, 80);
}

/*End Price Tag*/

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.imageBorder {
  border: 1px solid grey;
  border-radius: 16px;
  padding: 12px;
}

.image-upload > input {
  display: none;
}

.imageupload {
  width: 40px;
  cursor: pointer;
  background: #d3d3d3;
  float: left;
  cursor: pointer;
}

.span-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
}
/* Added refund start*/
.addedRefund,
.addedVoucher {
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

#popover-added-refund {
  text-align: center;
}

#popover-added-refund .icon-textbox {
  text-align: initial;
}

#popover-added-refund .form-label {
  white-space: nowrap;
}

/* Added refund end*/
/*height File uploaded Success list */
.Modal-UploadUl {
  margin-left: 8px;
  max-height: 150px;
  overflow-y: auto;
}

.passwordHeader {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  color: #1a8fbf;
}

.borderBottom01 {
  border-bottom: 1px solid #d3d3d3;
}

.marL15 {
  margin-left: 15px;
}

.marR15 {
  margin-right: 15px;
}

.marL60 {
  margin-left: 60px;
}

.marL33 {
  margin-left: 33px;
}

.member-of .nav-button {
  margin-top: 87px;
  text-align: center;
  font-size: 26px;
}

.member-of .nav-button a {
  color: #373e4a;
}

.member-of .nav-button a:hover,
.member-of .nav-button a:focus {
  color: #818da2;
  cursor: pointer;
}

/*Custom radio button start*/
.magic-checkbox + label,
.magic-radio + label {
  font-weight: normal !important;
}

@keyframes hover-color {
  from {
    border-color: #c0c0c0;
  }

  to {
    border-color: #3e97eb;
  }
}

.magic-radio,
.magic-radio-sm,
.magic-checkbox,
.magic-checkbox-sm {
  position: absolute;
  display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled],
.magic-radio-sm[disabled],
.magic-checkbox-sm[disabled] {
  cursor: not-allowed;
}

.magic-radio + label,
.magic-checkbox + label,
.magic-radio-sm + label,
.magic-checkbox-sm + label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
  vertical-align: middle;
}

.magic-radio-sm + label,
.magic-checkbox-sm + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}

.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before,
.magic-radio-sm + label:hover:before,
.magic-checkbox-sm + label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio + label:before,
.magic-checkbox + label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  border: 1px solid #9d9d9d;
}

.magic-radio-sm + label:before,
.magic-checkbox-sm + label:before {
  position: absolute;
  top: -5px;
  left: -1px;
  display: inline-block;
  width: 15px;
  height: 15px;
  content: "";
  border: 1px solid #9d9d9d;
}

.checkbox-padding .magic-radio-sm + label:before,
.checkbox-padding .magic-checkbox-sm + label:before {
  top: -9px;
}

.magic-radio + label:after,
.magic-checkbox + label:after,
.magic-radio-sm + label:after,
.magic-checkbox-sm + label:after {
  position: absolute;
  display: none;
  content: "";
}

.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label,
.magic-radio-sm[disabled] + label,
.magic-checkbox-sm[disabled] + label {
  cursor: not-allowed;
  color: #e4e4e4;
}

.magic-radio[disabled] + label:hover,
.magic-radio[disabled] + label:before,
.magic-radio[disabled] + label:after,
.magic-checkbox[disabled] + label:hover,
.magic-checkbox[disabled] + label:before,
.magic-checkbox[disabled] + label:after,
.magic-radio-sm[disabled] + label:hover,
.magic-radio-sm[disabled] + label:before,
.magic-radio-sm[disabled] + label:after,
.magic-checkbox-sm[disabled] + label:hover,
.magic-checkbox-sm[disabled] + label:before,
.magic-checkbox-sm[disabled] + label:after {
  cursor: not-allowed;
}

.magic-radio[disabled] + label:hover:before,
.magic-checkbox[disabled] + label:hover:before,
.magic-radio-sm[disabled] + label:hover:before,
.magic-checkbox-sm[disabled] + label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}

.magic-radio[disabled] + label:before,
.magic-checkbox[disabled] + label:before,
.magic-radio-sm[disabled] + label:before,
.magic-checkbox-sm[disabled] + label:before {
  border-color: #e4e4e4;
}

.magic-radio:checked + label:before,
.magic-checkbox:checked + label:before,
.magic-radio-sm:checked + label:before,
.magic-checkbox-sm:checked + label:before {
  animation-name: none;
}

.magic-radio:checked + label:after,
.magic-checkbox:checked + label:after,
.magic-radio-sm:checked + label:after,
.magic-checkbox-sm:checked + label:after {
  display: block;
}

.magic-radio + label:before,
.magic-radio-sm + label:before {
  border-radius: 50%;
}

.magic-radio + label:after,
.magic-radio-sm + label:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3e97eb;
}

.magic-radio:checked + label:before,
.magic-radio-sm:checked + label:before {
  border: 1px solid #3e97eb;
}

.magic-radio:checked[disabled] + label:before,
.magic-radio-sm:checked[disabled] + label:before {
  border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled] + label:after,
.magic-radio-sm:checked[disabled] + label:after {
  background: #c9e2f9;
}

.magic-checkbox + label:before,
.magic-checkbox-sm + label:before {
  border-radius: 4px;
}

.magic-checkbox + label:after {
  top: 2px;
  left: 7px;
  box-sizing: border-box;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox-indeterminate + label:after {
  top: 4px !important;
  left: 9px !important;
  width: 0px !important;
  height: 12px !important;
  transform: rotate(90deg) !important;
}

.magic-checkbox-sm + label:after {
  top: -5px;
  left: 3px;
  box-sizing: border-box;
  width: 6px;
  height: 11px;
  transform: rotate(45deg);
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-top: 0;
  border-left: 0;
}

.magic-checkbox-sm-indeterminate + label:after {
  top: -3px !important;
  left: 5px !important;
  width: 0px !important;
  height: 10px !important;
  transform: rotate(90deg) !important;
}

.magic-checkbox:checked + label:before,
.magic-checkbox-sm:checked + label:before {
  border: var(--brand-light-blue);
  background: var(--brand-light-blue);
}

.magic-checkbox:checked[disabled] + label:before,
.magic-checkbox-sm:checked[disabled] + label:before {
  border: #c9e2f9;
  background: #c9e2f9;
}

/*Custom radio button end*/
.pos-absolute {
  position: absolute;
}

.marL-20 {
  margin-left: -20px;
}

/*finish popover start*/

.finishPopover {
  max-width: none; /* Max Width of the popover (depending on the container!) */
  font-size: 12px;
}

.finishPopover .arrow {
  top: 100%;
}

.finishPopover .custom-list-group-item {
  padding: 6px 15px;
}

.finishPopover hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.finishPopover .warning {
  color: #8a6d3b;
}

.finishPopover .info {
  color: #31708f;
}

.finishPopover .rdo-btn-group {
  display: inline-flex;
  margin-bottom: 10px;
  padding-left: 5px;
}

.finishPopover .popover-content {
  height: 95%;
}

.finishPopover .rdo-btn-group div + div {
  margin-left: 20px;
}

/*finish popover end*/

.tabs-left,
.tabs-right {
  border-bottom: none;
  padding-top: 2px;
}

.tabs-left {
  border-right: 1px solid #ddd;
}

.tabs-left > li,
.tabs-right > li {
  float: none;
  margin-bottom: 2px;
}

.tabs-left > li {
  margin-right: -1px;
  width: 100%;
}

.tabs-left > li.active > a,
.tabs-left > li.active > a:hover,
.tabs-left > li.active > a:focus {
  border-bottom-color: #ddd;
  border-right-color: transparent;
  border-top-color: #ddd;
  border-left-color: #ddd;
  color: black;
}

.tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
}

.selected-message-view {
  background-color: #efefef;
  min-height: 100px;
  color: black;
  margin-bottom: 0;
  overflow: scroll;
  height: 120px;
}

/* PDFJS Viewer with text layer*/
.pdfTextLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 15px;
  bottom: 15px;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
  user-select: text;
}

.annotationLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 15px;
  bottom: 15px;
  overflow: hidden;
  opacity: 1;
}

.pdfTextLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.pdfTextLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: rgb(180, 0, 170);
  border-radius: 4px;
}

.pdfTextLayer .highlight.begin {
  border-radius: 4px 0px 0px 4px;
}

.pdfTextLayer .highlight.end {
  border-radius: 0px 4px 4px 0px;
}

.pdfTextLayer .highlight.middle {
  border-radius: 0px;
}

.pdfTextLayer .highlight.selected {
  background-color: rgb(0, 100, 0);
}

.pdfTextLayer ::-moz-selection {
  background: rgb(0, 0, 255);
}

.pdfTextLayer ::selection {
  background: rgb(0, 0, 255);
}

.pdfTextLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0px;
  top: 100%;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pdfTextLayer .endOfContent.active {
  top: 0px;
}

.btn-default-active {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.pdfTextLayer .endOfContent.active {
  top: 0px;
}

.group-view-content {
  height: 70vh;
  overflow-y: auto;
}

.group-view-hedear {
  background-color: #f9f7f7;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #eae7e7;
  border-radius: 3px;
  display: block;
}

.background-light-yellow {
  background-color: #f9f8ed;
}

.color-change-animation {
  animation: colorchange 2s;
  -webkit-animation: colorchange 2s;
}

.group-toolbar .pages-selected {
  padding-left: 4px;
  border-left: 1px solid #b1a2c1;
  margin-left: 5px;
  color: #3f51b5;
}

.page-thumbnail-checkbox {
  margin: 5px;
  top: 0px;
  right: 5px;
  position: absolute;
  z-index: 1;
}

@keyframes colorchange {
  0% {
    background: #f9f7f7;
  }

  25% {
    background: #eff992;
  }

  100% {
    background: #f9f7f7;
  }
}

.popover-footer {
  padding: 10px;
  text-align: right;
  display: flex;
}

.popover-footer > .btn {
  margin-left: 5%;
}

.voucher.payment-voucher {
  color: blue;
}

.voucher.estimated-voucher {
  color: pink;
}

.zIndex10 {
  z-index: 10;
}

.header-delivery-options {
  color: #1a8fbf;
}

.divUploadZone {
  border: 4px dashed #bababa;
  padding: 8px;
}

.fit-to-screen {
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
}

.pg-viewer-wrapper {
  height: 700px !important;
  overflow-y: auto !important;
}
/* Custom Pagination*/

.custom-pagination {
  border-radius: 4px !important;
  margin-bottom: 0px !important;
}

.custom-pagination > li {
  display: inline;
}

.custom-pagination > li > a,
.custom-pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  background: #ffffff;
  border: 1px #c9edfe solid;
  color: black;
}

.custom-pagination > li:first-child > a,
.custom-pagination > li:first-child > span {
  margin-left: 0;
}

.custom-pagination > li > a:hover,
.custom-pagination > li > span:hover,
.custom-pagination > li > a:focus,
.custom-pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.custom-pagination > .active > a,
.custom-pagination > .active > span,
.custom-pagination > .active > a:hover,
.custom-pagination > .active > span:hover,
.custom-pagination > .active > a:focus,
.custom-pagination > .active > span:focus {
  z-index: 3;
  cursor: default;
  color: white;
  background-color: #1d9bff;
}

.custom-pagination > .disabled > span,
.custom-pagination > .disabled > span:hover,
.custom-pagination > .disabled > span:focus,
.custom-pagination > .disabled > a,
.custom-pagination > .disabled > a:hover,
.custom-pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.custom-pagination-lg > li > a,
.custom-pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  overflow-y: auto;
}

.custom-pagination-lg > li:last-child > a,
.custom-pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-pagination-sm > li > a,
.custom-pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.custom-pagination > .active {
  z-index: 0 !important;
  border-color: #47a7f3 !important;
}

.custom-pagination-container {
  display: inline-flex;
}

.pagination-label-container {
  margin: auto;
  margin-left: 10px !important;
}

.custom-pagination-container .search-field {
  width: 30px;
  height: 25px;
  margin: auto;
  text-align: center;
}

.group-panel-body {
  margin-left: 0;
  background-color: #fefefe;
  padding-top: 0px !important;
  padding-left: 15px;
  padding-right: 15px;
}

/* End */
.datePicker {
  padding-right: 70px;
}

.filePreviewModalContent {
  max-height: 800px;
  max-width: 800px;
}

#group-process-return-tab ul li a {
  z-index: 10 !important;
  background-color: #fff;
}

#group-process-return-tab ul.custom-pagination li.active a {
  background-color: grey !important;
}

#process-return-groups ul li a {
  z-index: 10 !important;
  background-color: #fff;
}

#process-return-groups ul.custom-pagination li.active a {
  background-color: grey !important;
}

.taxpayer-sign-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
  box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
}

.spouse-sign-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
  box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
}

.partnership-sign-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(245, 237, 86, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(245, 237, 86, 1);
  box-shadow: 0px 0px 10px 5px rgba(245, 237, 86, 1);
}

.ero-sign-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
  box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
}

.filePreviewModalBody {
  height: 800px;
  max-width: 800px;
}

.filePreviewModalContent {
  overflow-y: initial !important;
}

.div-height {
  height: 30px;
}

.div-flex {
  display: flex;
}

.year-dropdown-align {
  justify-content: flex-end;
}

.year-dropdown-width {
  width: 68px;
}

.fontfamily-dropdown-width {
  width: 100px;
}

div#attachmentTable td.react-bs-table-no-data {
  border: 0px;
}

.copyIcon {
  display: none;
}

.copy:hover .copyIcon {
  display: inline-block;
}

.datePicker {
  padding-right: 70px;
}

.eye-icon {
  float: right;
  margin-top: -25px;
  padding-right: 15px;
  position: relative;
}

.page-thumbnail > span {
  display: block;
  position: relative;
}

.page-thumbnail img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.page-thumbnail:hover .action-buttons span {
  background-color: rgba(0, 0, 0, 0.5) !important;
  opacity: 1 !important;
  transition: all 0.3s ease 0s !important;
}

.overlay-content .page-number {
  color: white !important;
  font-size: 12px;
  font-weight: 700;
  padding: 0px 5px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0px;
  background: rgba(6, 0, 250, 0.38);
}

.overlay-content .action-buttons {
  bottom: 40%;
  left: 50%;
  position: absolute;
  margin-left: -43px;
}

.action-buttons span {
  border: 1px solid #afafaf;
  display: block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
  background-color: #afafaf;
  border-radius: 25px;
  color: white;
  opacity: 0.2;
  cursor: pointer;
}

.action-buttons a:hover {
  background-color: #8c8c8c !important;
  opacity: 1 !important;
  transition: all 0.3s ease 0s !important;
}

.overlay-content .action-buttons ul {
  display: flex;
  list-style: outside none none;
}

.overlay-content .action-buttons ul li {
  margin-right: 10px;
}

.overlay-content h2 {
  color: #5a5a5a;
  font-size: 20px;
  font-weight: 700;
  left: 40px;
  position: absolute;
  top: 20px;
  transition: all 0.3s ease 0s;
}

.page-thumbnail .title-bar {
  background: rgb(215, 208, 255);
  color: black !important;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 0px;
  text-align: center;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0px;
}

.page-thumbnail:hover .title-bar {
  background: rgba(215, 208, 255, 0.33);
}

.modal-GroupFilePreview {
  min-width: 70%;
  margin: auto;
  overflow-y: initial !important;
  max-height: 100%;
}

.modal-GroupFilePreviewBody {
  height: 75vh;
  overflow-y: auto;
}

.btn-download-bri {
  margin-right: 2%;
}

#popover-voucher-add {
  text-align: center;
}

#popover-voucher-add .icon-textbox {
  text-align: initial;
}

.textMessageview {
  background-color: rgb(241, 241, 241);
  height: 132px;
  overflow-y: auto;
  padding: 5px;
}

.word-Visible {
  overflow-wrap: break-word;
  white-space: initial !important;
}

.progress {
  margin-bottom: 0px !important;
}

.pg-viewer-wrapper video {
  width: 100%;
  height: 100%;
}

.taxcaddy-icon {
  font-size: x-large;
  cursor: pointer;
  text-decoration: none !important;
}

.padT08 {
  padding-top: 8px;
}

#group-process-return-tab-pane-1 .react-datepicker-wrapper {
  display: initial !important;
}

#group-process-return-tab-pane-1 .react-datepicker__input-container {
  position: relative;
  display: initial !important;
}

#process-return-groups-pane-1 .react-datepicker-wrapper {
  display: initial !important;
}

#process-return-groups-pane-1 .react-datepicker__input-container {
  position: relative;
  display: initial !important;
}

.height-inherit {
  height: inherit;
}

.full-height {
  height: 100%;
}

#process-return-groups .dropdown-menu > div > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

#group-process-return-tab .dropdown-menu > div > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

/*=======Dropdown start===========*/
#combodefault .col-lg-9 {
  min-height: 250px;
}

#default {
  margin: 0 auto;
  width: 250px !important;
  padding-top: 15px;
}

#combodefault .property-panel-content,
#combopanel .property-panel-content {
  overflow: hidden;
}

.attachmentDownload {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 1px;
  padding-top: 4px;
  border: 0px;
}

/*=======Dropdown end===========*/

.text-message {
  margin-top: 10px;
  border: 1px solid rgb(221, 221, 221);
  padding: 5px;
  height: 207px;
  padding-top: 0px;
}

.uploaded-documents-table-container {
  overflow: auto;
  max-height: 70vh;
}

.uploaded-documents-table-container .table th,
.uploaded-documents-table-container .table td {
  padding: 0.25rem;
}

.text-bold {
  font-weight: bold !important;
}

.zindexzero input[type="text"] {
  z-index: 0 !important;
}

.addingvoucher-loader {
  position: absolute !important;
  height: 100%;
}

.upload {
  background-color: #eee;
  color: black;
  padding: 5px 4px;
}

.overflowHidden {
  overflow: hidden;
}

.overflowVisible {
  overflow: visible !important;
}

.bookmark-item {
  padding: 8px 8px;
}

.bookmark-name {
  display: inline;
  margin-right: 8px;
}

.bookmark-name:hover {
  text-decoration-color: #337ab7 !important;
  text-decoration: underline;
}

.bookmark-row {
  display: inline-flex;
  width: 100%;
}

.bookmark-icon {
  margin-right: 8px;
  line-height: unset;
}

#process-return-groups-pane-1 .react-datepicker__input-container {
  position: relative;
  display: initial !important;
}

#group-process-return-tab-pane-1 .react-datepicker__input-container {
  position: relative;
  display: initial !important;
}

.height-inherit {
  height: inherit;
}

.full-height {
  height: 100%;
}

#process-return-groups .dropdown-menu > div > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

#group-process-return-tab .dropdown-menu > div > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu-ul {
  right: 100% !important;
  max-width: none !important;
  left: unset !important;
}

.dropdown-submenu-right > a:after {
  float: right !important;
}

.bold-label > div > .magic-checkbox + label,
.boldlable > div > .magic-radio + label {
  font-weight: bold !important;
}

.zero-amount-badge {
  background-color: grey;
  border-radius: 25px;
  width: 26px;
  max-height: 19px;
  color: white !important;
}

.delete-zero-amount-button {
  margin-right: 10px;
  background: orange;
  height: 24px;
  width: 24px;
  border-color: #ffc400;
}

.delete-zero-icon {
  padding-bottom: 6px;
  height: 22px;
  width: 17px;
  vertical-align: middle;
}

.access-code-note {
  text-align: left;
  padding-top: 19px;
  font-size: smaller;
  color: #e31313;
  padding-left: 5px;
}

.replace-invoice {
  font-size: 12px;
  border-radius: 0px !important;
  border: 1px solid #ccc;
  color: #eee;
}

.bookmark-right {
  margin-left: auto;
  white-space: nowrap;
}

.bookmark-right i {
  line-height: unset;
}

.bookmark-right i:hover {
  background-color: #d0d0d3;
  border: solid 1px #595959;
  border-radius: 3px 3px;
}



.bookmark-right .client-info-payments {
  font-family: "'Lucida Console', 'Monaco', 'monospace'";
  margin-right: 8px;
  text-align: right;
  color: red;
}



.bookmark-right .client-info-refunds {
  font-family: "'Lucida Console', 'Monaco', 'monospace'";
  margin-right: 8px;
  text-align: right;
  color: green;
}

.textarea {
  overflow: auto;
  resize: vertical;
}

.text-green {
  color: #5cb85c;
}

.text-yellow {
  color: #ffd700;
}

.text-right {
  text-align: right !important;
}

#div-delivered-returns-search,
#div-archive-returns-search,
#div-inuse-returns-search,
#div-Assignment-Table-search {
  float: left;
}

.react-bs-table-search-form {
  width: 100%;
}

.modal.view-access-code-modal .modal-dialog {
  width: 60%;
}

.bookmark-icon-image {
  max-width: 16px !important;
  max-height: 16px !important;
  margin-right: 8px;
  margin-top: 3px;
}

.overflowTextClientTracking > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

#ero {
  font-size: 14px !important;
}

#report-problem-text {
  resize: none;
}

.display-checkBox {
  display: inline-block !important;
}

.zeroborderRadius > span {
  border-radius: 0px !important;
}

.fa-stack {
  font-size: 0.45em;
}

.activeSelected {
  background: #3399ff !important;
  color: white !important;
}

.group_user ul li {
  list-style-type: none;
}

.group_user ul li:hover {
  cursor: default !important;
  background: #c3c3eb;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-input-group input,
.e-float-input.e-control-wrapper input,
.e-float-input.e-control-wrapper.e-input-group input,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-control-wrapper {
  border-radius: 0px !important;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0px !important;
}

.padding-left-6 {
  padding-left: 6%;
}

th[data-field="clientApp"] {
  width: 30%;
}

.modal.signed-Details-modal .modal-dialog {
  width: 35%;
}

.modal.sf-esign-details-modal .modal-dialog {
  width: 45%;
}

.modal.recall-return-modal .modal-dialog {
  width: 40%;
}

.recall-return-table {
  border-collapse: collapse;
  width: -webkit-fill-available;
}

table.recall-return-table th,
table.recall-return-table td {
  border: 1px solid #ccc;
  text-align: left;
  padding: 5px;
  padding-left: 8px;
}

.custom-multiselect-status {
  margin-left: 3px;
  vertical-align: baseline;
  position: relative;
  bottom: 3px;
}

.ssr-default {
  pointer-events: none;
  background-color: rgb(241, 236, 236);
}

.minimum-length {
  padding-top: 20px;
  padding-bottom: 20px;
}

.select-age {
  padding-top: 20px;
  padding-bottom: 10px;
}

.age-text {
  float: left;
  font-size: 12px;
  margin-left: 15px;
}

.select-age-dropdown {
  width: 8%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.password-req-lable {
  color: red;
  /*text-decoration: underline;*/
}

.inputinput-mask {
  display: flex;
}

.inputinput-mask > .eye-icon {
  position: absolute;
  right: 10px;
  margin-top: 12px;
}

.line-space {
  padding-top: 10px;
  padding-left: 30px;
}

.list-special-char {
  color: green;
  padding-left: 45px;
}

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -50px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.Select .Select-menu-outer {
  width: 100% !important;
  overflow: visible !important;
}

.voucher-value {
  width: 180px;
}

.k1settings-value {
  width: 204px;
}

.select-control {
  width: 140px;
  float: left;
  padding-right: 15px;
}

.settings-subheading {
  font-size: 16px;
  text-decoration-line: underline;
  margin-left: 20px;
}

.marT09 {
  margin-top: 9px;
}

.select-reminder-control {
  min-width: 120px !important;
}

.bannerHeader {
  color: #ff0020;
  font-size: 1.5rem !important;
  font-weight: bold;
  padding-left: 50px;
  flex: 6;
}

.marTNeg03 {
  margin-top: -3px !important;
}

.Select.is-disabled > .Select-control {
  background-color: #eeeeee !important;
}

.columnvalue {
  overflow: hidden;
  width: 80%;
  display: inline-block;
}

.sub-menu-icon {
  color: #f7f6f6;
  padding-right: 4px;
}

ul.moveable li {
  list-style-image: none;
  border-radius: 4px;
  padding: 4px;
  color: #666;
  cursor: move;
}

ul.moveable li:hover {
  background-color: #eee;
}

.draggable-input:hover {
  cursor: move;
}

span.action-icons > i:hover,
span.action-icons > i {
  cursor: pointer !important;
  pointer-events: auto;
}

div.optionlist {
  position: absolute;
  z-index: 200;
  background-color: #fff;
  left: auto;
  text-align: left;
  list-style: none;
  border: 1px solid #aaa;
  right: 21px;
  white-space: nowrap;
}

div.optionlist > div.item {
  line-height: 24px;
  padding: 0 10px;
}

div.optionlist > div.item-highlighted {
  color: #fff;
  background-color: #0078d7;
}

/* Below styles are for multiple voucher popover alert. */
#popover-voucher-add {
  padding: 0px;
}

#popover-voucher-add .popover-content {
  padding: 0px;
}

#popover-voucher-add .new-main-content {
  padding: 9px 14px;
}

#popover-voucher-add .popover-content .alert {
  border-radius: 0px;
}
/* Multiple voucher popover alert style end */

.resize-vertical {
  resize: vertical;
}

.resize-horizontal {
  resize: horizontal;
}

.recycle-checkbox {
  margin-top: 4px;
}

.div-chip-list {
  align-items: center;
  border: 2px solid #d4d5d6;
  padding: 4px;
  overflow-y: auto;
  max-height: 100px;
}

.chip-input {
  height: 34px;
  padding: 0 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: none;
  color: #565656;
  -webkit-appearance: none;
}

.chip {
  border: 1px solid #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 3px 0;
  background: #f6ee934f;
}

.chip > button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chip > .drag {
  cursor: move;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute;
  z-index: 1;
  background-color: white;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: aliceblue;
  cursor: pointer;
}

#sf-txt-area-msg {
  height: 300px;
  margin-top: 2%;
  padding-left: 5px;
}

.sfSigners {
  color: #337ab7 !important;
}

.sfSigners:hover {
  text-decoration: underline !important;
}

.sigflow-doc-access-list {
  text-align: center;
  padding-top: 5%;
}

.sfSigners:hover {
  text-decoration: underline !important;
}

/* The switch - the box around the slider */
.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 60px;
}

/* Hide default HTML checkbox */
.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.columnFilterSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  width: 40px;
  height: 12px;
  display: block;
  margin: 10px 0px 0px 0px;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3) !important;
  -webkit-transition: 0.5s ease background-color;
  background-color: #d9e3f4;
  border-radius: 20px;
  transition: 0.5s ease background-color;
}

.columnFilterSlider:before {
  position: absolute;
  content: "";
  width: 17px;
  height: 17px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 6px 25px #d7d7d7;
  transform: translate(-2px, -2px);
  transition: 0.6s ease transform, 0.6s box-shadow;
}

input[type="checkbox"]:checked + .columnFilterSlider {
  background-color: #92d9f8;
}

input:focus + .columnFilterSlider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .columnFilterSlider:before {
  box-shadow: 7px 6px 25px #115980 inset;
  transform: translate(25px, -2px);
}

input:checked + .columnFilterSlider:after {
  width: 24px;
  border-radius: 50%;
  transform: translate(64px, 0px);
}

/* Rounded sliders */
.columnFilterSlider.round {
  border-radius: 34px;
}

.columnFilterSlider.round:before {
  border-radius: 50%;
}

.noOutline {
  outline: none;
  border: 0px solid !important;
  border-top-style: hidden;
  background-color: transparent !important;
  box-shadow: none;
  font-weight: bold;
  font-size: 14px;
}

.noOutline:hover {
  background-color: #eee;
  outline: none;
  opacity: 1;
}

.cancelDocumentTextArea {
  border: solid 2px #21a9e1;
  min-height: 100px;
  width: 100%;
  margin: 3px 0px 3px 0px;
  font-size: inherit;
}

.viewWidth50 {
  width: 50vw !important;
}

.viewWidth70 {
  width: 70vw !important;
}

.viewWidth85 {
  width: 85vw !important;
}

.viewWidth90 {
  min-width: 90vw !important;
}

.separatorLine {
  border: 1.3px solid black;
  height: 35px;
}

.popup-btn-white {
  background: #fafafa;
  border: 0px #e0e0e0 solid;
  color: #303641;
  margin-right: 2px;
}

.popup-btn-white:hover {
  background: #ebebeb;
}

.popup-btn-white i {
  text-shadow: 0px 1px 0px #ebebeb;
}

.textfield-control {
  margin-left: 14px;
  margin-right: -14px;
}

#ddlUploadSFDocument .Select-arrow {
  margin-top: 10px;
}

div.formNameList {
  position: absolute;
  z-index: 200;
  background-color: #fff;
  left: auto;
  text-align: left;
  list-style: none;
  border: 1px solid #aaa;
  white-space: nowrap;
}

div.formNameList > div.item {
  line-height: 24px;
  padding: 0 10px;
}

div.formNameList > div.item-highlighted {
  color: #fff;
  background-color: #0078d7;
}

.colorPaletteCoverPage {
  width: 300px;
  margin: auto;
  margin-top: 50px;
  border: 2px solid;
  border-radius: 8px;
  min-height: 220px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.colorPaletteCoverPage:after {
  border: solid transparent;
  border-width: 20px;
  top: 200px;
  left: 74%;
  content: " ";
  height: 244px;
  width: 300px;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: inherit;
  border-radius: 0px 0px 0px;
  margin-left: 0px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(0deg);
  z-index: 0;
  box-shadow: 10px;
}

.previewContainer {
  min-height: 350px;
}

.headerContainer {
  padding: 5px;
}

.documentInfoContainer {
  background-color: white;
  border: 2px solid #d3d3d3;
  margin: 15px;
  text-align: center;
}

.cpaInfo {
  font-size: 12px;
}

.companyLogoDiv {
  max-width: 80px;
  max-height: 36px;
  margin: auto;
}

.companyInfoDiv {
  font-size: 12px;
  padding: 10px;
  word-break: break-all;
}

.getStarted {
  position: absolute;
  top: 244px;
  right: 89px;
  background-color: #89c05a;
  font-size: 10px;
  transform: rotate(90deg);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 1;
  width: 78px;
  text-align: center;
  border: 2px solid;
  border-bottom: 0;
  color: #fff;
  font-weight: 600;
}

.previewContact {
  margin-top: -24px;
  color: white;
  padding-left: 420px;
}

.sfSigner > .Select--single > .Select-control .Select-value {
  max-width: 97%;
}

.sf-compulsory-field {
  color: #c50505;
  font-size: 9px;
  position: absolute;
  padding-left: 7px;
  top: 8px;
}

.doc-access-auth-title {
  padding-top: 20px;
  padding-left: 20px;
}

/* Notification Settings  */
.notificationPopover {
  max-width: none; /* Max Width of the popover (depending on the container!) */
  font-size: 12px;
  overflow: hidden;
  width: 500px;
  height: 550px;
  resize: both;
  min-width: 300px;
  min-height: 250px;
}

.notificationPopover .popover-content {
  height: 100%;
}

#inbox-tab {
  height: 100%;
}

#inbox-tab .tab-content {
  height: 90%;
}

#inbox-tab .tab-content .tab-pane {
  height: 94%;
}

.settingsPanel {
  max-height: 47vh;
  overflow-y: auto;
}

.settings {
  color: grey;
  padding: 4px 2px;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  font-size: medium;
}

.settings:hover {
  color: grey;
}

.pad-settings {
  padding: 4px 8px;
}

.pad00 {
  padding: 0px;
}

.btn-settings {
  width: 40px;
}

/* Notification Settings  */

.inbox-count {
  background-color: #ff6666;
  font-size: 8px;
  padding: 3px;
}

.support-inbox-tabs {
  padding: 15px 0px 0px 0px;
  height: 96%;
}

.support-inbox-active-tab {
  background-color: #f5f5f6;
}

ul.support-inbox-tab-title > li {
  display: inline;
  padding: 15px;
  cursor: pointer;
}

ul.support-inbox-tab-title > li:hover {
  background: #f5f5f6;
}

.support-inbox-knownissue {
  color: #ff6666;
}

.support-inbox-releaseupdate {
  color: forestgreen;
}

.support-inbox-processingitem {
  color: dodgerblue;
}

.support-inbox-others {
  color: #ff9900;
  font-size: 8px;
  vertical-align: top;
}

.support-inbox-count {
  vertical-align: sub;
  margin-left: -3px;
  font-weight: bold;
}

.support-inbox {
  position: relative;
  height: 96%;
}

.support-inbox-heading {
  text-align: center;
  color: white;
  padding: 5px;
  background-color: darkgrey;
  font-size: 14px;
}

.support-inbox-content {
  overflow-y: scroll;
  border: antiquewhite;
  border-width: thin;
  border-style: solid;
  min-height: 340px;
}

.support-message {
  width: 100%;
  padding: 0px 10px;
  display: inline-table;
  cursor: pointer;
  margin-bottom: 5px;
  border: 1px solid lightgoldenrodyellow;
  background-color: lightgoldenrodyellow;
}

.support-message-break {
  margin-top: 0px;
  margin-bottom: 0px;
}

.support-message-header {
  padding-top: 5px;
  display: flex;
}

.support-message-title {
  overflow: hidden;
  width: 75%;
  cursor: inherit;
  font-weight: 700;
}

.support-message-date {
  font-size: 10px;
  color: darkgrey;
}

.support-message-body {
  padding-bottom: 5px;
  text-align: justify;
}

.support-message-seemore {
  color: dodgerblue;
  margin-bottom: 0px;
}

.support-message-footer {
  position: fixed;
  background: white;
  bottom: -4px;
  font-weight: bold;
}

.firm-inbox-content {
  overflow-y: scroll;
  max-height: 460px;
  border-width: thin;
  border-bottom-style: groove;
}

.firm-message {
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.firm-message-body {
  display: inline-flex;
}

.firm-message-text {
  width: 95%;
  padding-right: 10px;
}

.firm-message-clear {
  width: 5%;
}

.firm-message-date {
  font-size: 10px;
  color: steelblue;
}

.firm-message-clear-icon {
  padding: 5px;
}

.firm-message-unsubscribe-icon {
  color: orangered;
  padding-top: 5px;
}

.text-dodgerblue {
  color: dodgerblue;
  cursor: pointer;
}

.unreadMessage {
  background-color: #ffffcc;
}

.support-message-clear {
  font-size: initial;
  font-weight: 700;
  margin-top: -9px;
  color: #c50505;
}

.w30 {
  width: 30px;
}

.checkbox-helper {
  display: inline-block;
  position: absolute;
  right: 0px;
  z-index: 10000000;
}

.disableAnchor {
  pointer-events: none;
}

.enableAnchor {
  pointer-events: all;
}

.canvasProperty {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  border: 3px solid #037996;
}

.divCanvasProperty {
  border: 2px outset black;
  background: white;
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0px;
}

.watermarkDraggerProperty {
  display: flex !important;
  border: solid 2px #037996;
  background: white;
  opacity: 0.6;
}

.watermarkIconwidth {
  width: 1.5em !important;
}

.divMainProperty {
  border: 2px outset black;
  background: white;
  text-align: center;
  height: 792px;
  width: 612px;
  position: relative;
  margin: 20px;
}

.moveableWatermark {
  font-family: "Roboto", sans-serif;
  position: relative;
  width: 300px;
  height: 100px;
  text-align: center;
  font-size: 40px;
  margin: 0px auto;
  font-weight: 100;
  letter-spacing: 1px;
  left: -150px;
}

.moveableWatermark span {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(0px, 0px);
  white-space: nowrap;
}

.switchToggle {
  position: relative;
  width: 44px;
  height: 18px;
  margin: 5px 0 4px 0;
}

.switchToggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.miniToggleSwitch {
  position: relative;
  width: 44px;
  height: 18px;
  margin: 5px 0 4px 0;
}

.miniToggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.w40 {
  width: 40px;
}

.list-title {
  font-weight: 600 !important;
  font-size: 15px !important;
  text-transform: capitalize;
}

.list-sub-title {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  color: rgba(0, 0, 0, 0.55) !important;
  font-size: 14px !important;
  text-transform: capitalize;
}

.disabled {
  cursor: not-allowed !important;
}

.disabled > div {
  cursor: not-allowed !important;
}

.checkbox label input[type="checkbox"][disabled] {
  cursor: not-allowed;
}

a.btn-default input[disabled] {
  cursor: not-allowed;
}

.toggle-switch-text {
  font-size: 16px;
  font-weight: normal;
  padding-top: 4px;
}

.contactIcon {
  width: 14px;
  margin: 0px 5px -5px 0px;
}

.senderdelegation-glyphicon {
  font-size: initial;
  top: 4px;
  padding-left: 5px;
}

/* My Downloads*/
.my-download-title {
  color: grey;
  margin-right: 5px;
}

.my-download-file-icon {
  color: #1a8fbf;
  margin-left: 20px;
  margin-top: 10px;
}

.my-download-row-toggle {
  color: grey;
  margin-right: 5px;
  margin-top: 16px;
  margin-left: 24px;
  cursor: pointer;
}

.my-download-delete {
  margin-top: 16px;
  font-size: 20px;
  color: #dc3545 !important;
  cursor: pointer;
}

.my-download-expand {
  color: grey;
  text-align: left;
  margin-left: 37px;
  font-size: 12px;
}

.my-download-filter {
  border: hidden;
  color: grey;
}

.my-download-filter-row {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 244px;
}

.my-download-status-icon {
  color: #23527c;
  font-size: 20px;
  margin-top: 16px;
}

.my-download-popover {
  color: grey;
  font-size: 12px;
}

.senderdelegation-glyphicon {
  font-size: initial;
  top: 4px;
  padding-left: 5px;
}

/*---TaxDocument Upload FAB button style starts here---*/

.cch-btn,
.ut-btn,
.gs-btn,
.lt-btn {
  cursor: pointer;
  position: absolute;
  right: 1.5em;
  padding: 10px 0px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  z-index: 1;
  color: #fff;
  align-items: center;
  text-align: center;
}

.cch-btn {
  background-color: #7abf48;
}

.ut-btn {
  background-color: #454545;
}

.gs-btn {
  background-color: #f49011;
}

.lt-btn {
  background-color: #c09550;
}
/*---TaxDocument Upload FAB button style ends here---*/

#delegatee-table {
  width: 98%;
  background: #fff;
  margin-left: 21px;
  margin-top: -1px;
}

#delegatee-table tr {
  height: 20px;
}

#delegatee-table tr td {
  border: 1px solid #e5e5e5;
  padding-left: 10px;
}

.sf-upload-delete-confirm {
  margin-bottom: 0px;
}

.download-return-pop-up {
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*--Sort Icon allignment Styling in Grid for Delivered and Archive Return view  ---*/
.table-text-sort {
  display: inline-block;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.devilvered-archived-screen th span.order {
  float: right;
}

.devilvered-archived-screen .react-bs-table table th {
  overflow: visible !important;
}

.checkbox-padding {
  padding-top: 8px;
}

.signatureflow-report .sort-column[data-field] {
  overflow: visible !important;
}

.exedownload-icon {
  font-size: x-large;
  cursor: pointer;
  text-decoration: none !important;
  margin-left: 10px;
}

.full-height {
  height: 100%;
}

.btn-border {
  border: 1px #e0e0e0 solid;
}

.bg-color-green {
  background-color: green !important;
}

.text-color-white {
  color: white !important;
}

.text-color-black {
  color: black !important;
}

.tabFinishRightPanel {
  overflow: auto;
  position: relative;
  height: 100%;
  border-left: 1px solid #ddd;
}

.password-info {
  font-weight: normal;
  background-color: rgb(215, 240, 247);
  padding-right: 11px;
  padding-left: 5px;
}

.purchase-item-wrap {
  display: flex;
}

.purchase-item-summary {
  float: left;
  border: 1px solid;
  border-color: white;
  padding: 10px 6px 5px 6px;
}

.stripe-text {
  text-align: center;
  color: #b2afbf;
}

.stripe-content {
  text-align: center;
  color: #5469d4;
  font-weight: bolder;
  font-size: 80px;
}

.stripe-btn {
  background-color: #5469d4;
  font-weight: bold;
  font-size: 14px;
  padding-left: 12px;
}

.stripe-btn-div {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.width20per {
  width: 20% !important;
}

.width22per {
  width: 22% !important;
}

.width11per {
  width: 11% !important;
}

.width-240 {
  min-width: 240px;
}

.invoice-header-text {
  font-size: 21px;
  color: #373e4a;
  margin-top: 12px;
}

.invoice-toggle-switch-label {
  margin-right: 25px;
  font-weight: normal;
  font-size: 14px;
}

.stripe-label-text {
  margin-right: 100px;
  font-weight: normal;
  font-size: 14px;
  margin-top: 6px;
}

.linked-url-label {
  margin-right: 8px;
  font-weight: normal;
  font-size: 14px;
  margin-top: 6px;
}

.invoice-label {
  margin-right: 25px;
  font-weight: normal;
  font-size: 14px;
}

.diplay-div {
  display: flex;
}

.input-left-padding {
  padding: 0px 0px 0px 5px;
}

.span-left-float {
  float: left;
}

.child-grid-row > td {
  overflow: visible !important;
}

.report-problem-field {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
}

.font700 {
  font-weight: 700;
}

.li-font-styling {
  font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system",
    BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px !important;
}

.reminerToggleDisable .custom-toggle-switch-label {
  background: lightgrey !important;
}

.quick-report-selection {
  display: inline-block;
  color: #333;
  background-color: #f0f0f1;
  border: none;
  padding: 5px 8px;
  float: left;
}

.no-border {
  border: none;
}

.SignatureArchivecheckbox {
  margin-top: 5px;
}

.SignatureRecyclecheckbox {
  margin-top: 5px;
}

.delegation-settings {
  margin-left: 15px;
  margin-top: 4px;
}

.modal .form-group {
  display: flex;
  margin-bottom: 0rem;
}

.button-group .dropdown-menu {
  padding: 0.5rem;
}
.button-group .dropdown-menu .ddl-icon {
  margin-left: 5px;
}

.modal-dialog .modal-footer .modal-footer-left {
  position: absolute;
  bottom: 20px;
  left: 10px;
}

.text-primary {
  color: #337ab7 !important;
}

.alignItemsBaseline {
  align-items: baseline;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.marRauto {
  margin-right: auto;
}

.hidden {
  display: none !important;
}

.dispFR {
  display: flow-root;
}

.dispFlex {
  display: flex;
}

.dispBlock {
  display: block !important;
}

label {
  font-weight: 700;
}

.badge {
  color: #fff;
  background-color: #777;
  font-size: 12px;
  padding: 3px 7px;
  border-radius: 10px;
}

.popover-header {
  margin-top: 0px;
  font-size: 14px;
}
/*--------bootbox Start--------------*/
.bootbox .modal-dialog {
  max-width: 600px;
}

.bootbox .modal-dialog .modal-content {
  max-width: 600px;
}

.bootbox .modal-header {
  display: initial;
}

.bootbox-body {
  font-size: 14px;
}

/*--------bootbox End--------------*/

.react-bs-table .table th,
.react-bs-table .table td {
  padding: 0.5rem;
}

.dropdown,
.dropup {
  position: relative;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}

.custom-control-label::before {
  display: none;
}

.custom-control {
  padding-left: 0px;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.hide {
  display: none !important;
}

.alertemail-remove {
  padding-top: 5px;
  float: right;
}

.apievent-restart {
  text-align: center;
  float: right;
}

.not-spam-icon {
  font-size: 15px !important;
  margin-top: 2.4px !important;
  color: #929292;
}

.folderDropdown,
.retention {
  width: 160px;
}

.folderIconColumn {
  width: 0 !important;
}



.react-bootstrap-table table {
  table-layout: auto !important;
}

.change-password-modal > .modal-content {
  width: 600px;
  margin: auto;
}

.dragging {
  border: 4px dashed var(--brand-black-20per) !important;
  height: 250px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--brand-black-20per);
}

/* Rename Modal CSS */

.rename-modal-container {
  margin-top: 50px;
}

.rename-modal-container .modal-header {
  background-color: #1e3d7b;
  padding: 10px 20px 10px 20px;
}

.rename-modal-container .modal-title {
  color: white;
}

.rename-modal-container .modal-content {
  margin: auto;
  width: 350px;
}

.rename-modal-container input {
  width: 100%;
  padding: 2px;
}

.rename-modal-container button {
  padding: 6px 15px 6px 15px;
}

.rename-modal-container .modal-body {
  height: 100px;
}

.rename-modal-container .modal-body > div p {
  position: absolute;
  padding-left: 1px;
  font-size: 12px;
}

.change-retention-modal-container {
  margin-top: 50px;
}

.change-retention-modal-container .modal-header {
  background-color: #1e3d7b;
  padding: 10px 20px 10px 20px;
}

.change-retention-modal-container .modal-title {
  color: white;
}

.change-retention-modal-container .modal-content {
  margin: auto;
  width: 350px;
}

.change-retention-modal-container input {
  width: 100%;
  padding: 10px;
}

.change-retention-modal-container button {
  padding: 6px 15px 6px 15px;
}

.change-retention-modal-container .alert {
  margin: 0px;
}

/*********************************************/

.folder-actions-container{
  width: auto !important;
  background: #fff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.54);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.54);
  border: none;
  font-weight: 700;
  color: #8d8d95;
  border-radius: .25rem;
}

.folder-actions-container span {
  padding-right: 6px;
  font-size: 12px;
}

.folder-actions-container i {
  font-size: 15px;
  color: "#606060";
  margin: 10px;
  cursor: pointer;
}

.folder-actions-container svg {
  margin: 8px;
}
.folder-action-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px;
}
.folder-action-wrapper:hover {
  background-color: var(--non-branded-light-blue_hover);
}

.folder-action-wrapper > i {
  font-size: 12px;
}


/* Authmethod dilog css */
.auth-method-dialog-body {
  width: 80%;
  display: flex;
  align-items: center;
}
.auth-method-dialog-body .AuthMethodCard-wrapper:nth-child(2){
  margin-left: 15%;
}
.AuthMethodCard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-top: 1rem;
  border-radius: 10px;
  border: 1px solid var(--brand-grey);
  position: relative;
  height: 120px;
  user-select: none;
}

.AuthMethodCard-wrapper:hover {
  box-shadow: 2px 2px 8px var(--brand-light-blue);
  border-color: var(--brand-light-blue);
}

.AuthMethodCard-wrapper > p {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 14px;
}

.AuthMethodCard-wrapper .special-title-text{
  margin-top: -20px;
}

.mark-default-link {
  visibility: hidden;
  margin-bottom: 1rem !important;
  margin-top: 0 !important;
  cursor: pointer;
  font-size: 10px;
}

.AuthMethodCard-wrapper:hover > .mark-default-link {
  visibility: visible;
}
.always-show {
  visibility: visible;
  font-size: 12px !important;
}
.active-card {
  border-color: var(--brand-light-blue);
  background-color: var(--brand-light-blue);
  color: white;
}
.active-card > .mark-default-link {
  visibility: visible;
  color: white !important;
  font-size: 12px !important;
  font-weight: 600;
}

.active-card > .always-show {
  color: white;
}
.auth_method_img {
  width: 50px;
}


/*------------------- View Inbox dropdown--Start--------------*/
.viewInbox-dropdown-wrapper {
  display: flex;
  font-size: 20px;
}

.viewInbox-dropdown-wrapper > .dropdown-menu {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 4px;
  width: 240px;
}

.viewInbox-dropdown-title {
  background-color: var(--brand-header-white) !important;
  box-shadow: none !important;
  padding: 0;
}
.viewInbox-dropdown-wrapper > .viewInbox-dropdown-title::after {
  display: none;
}
.viewInbox-dropdown-wrapper > .viewInbox-dropdown-title:focus {
  background-color: var(--brand-header-white);
  border-color: none;
}
.viewInbox-dropdown-wrapper > .viewInbox-dropdown-title:hover {
  background-color: var(--brand-header-white);
}
.viewInbox-dropdown-wrapper > .viewInbox-dropdown-title:hover > svg {
  fill:var(--theme_shamrock) !important
}

.settings-icon{
  padding-inline: 0 !important;
}
.viewInbox-dropdown-item{
  overflow-x: hidden;
  text-overflow: ellipsis;
}
/*------------------- View Inbox dropdown--End----------------*/

.others-inbox-info-label{
  background-color: var(--brand-green);
  color: white;
  font-size: 13px;
  padding-left: 2rem;
  margin-bottom: 0;
}


.dropdown-group-heading{
  padding: 4px;
}
.inline-Actions-Wrapper.show > .btn-primary.dropdown-toggle {
  background-color: rgba(0, 0, 0, 0.05);
}

.inline-Actions-Wrapper > .dropdown-toggle {
  background-color: white !important;
  box-shadow: none !important;
  color: #737373 !important;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.inline-Actions-Wrapper > .dropdown-toggle::after {
  display: none;
}
.inline-Actions-Wrapper > .dropdown-toggle::before {
  display: none;
}
.inline-Actions-Wrapper > .dropdown-toggle:focus {
  background-color: white;
  border-color: none;
}
.inline-Actions-Wrapper > .dropdown-toggle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.inline-Actions-Wrapper > .dropdown-menu {
  padding: 0 !important;
  min-width: unset;
  width: 120px;
}
.inline-Actions-Wrapper > .dropdown-menu > .dropdown-item {
  padding: 0px 0px 2px 5px;
}
.inline-Actions-Wrapper > .dropdown-menu > .dropdown-item:active {
  background-color: white !important;
}
.inline-Actions-Wrapper .dropdown-item:hover {
  background-color: var(--non-branded-light-blue_hover);
}


