@import "../../../styles/variables";
.settings-wrapper {
    display: flex;
    height: calc(100vh - 85px);
    nav {
        display: flex;
        flex-direction: column;
        width: 270px;
        background-color: #f6f6f6;
        padding: 1rem 0;
        & > h3 {
            margin: 0 0 3rem 1rem;
            font-size: $heading-size-1;
            font-weight: 500;
            color: $brand-secondary-black;
        }
        .settings-menu-item {
            font-size: $font-size-md;
            color: $brand-secondary-black-60;
            font-weight: 700;
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;
            position: relative;
            cursor: pointer;
            &.active {
                background-color: #fff;

                &::before {
                    background-color: $brand-primary-green;
                    content: "";
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 6px;
                }
            }
        }
        .drop-off-sideMenu-wrapper {
            margin-bottom: 0;
        }
    }
    main {
        width: calc(100% - 270px);
        padding: 1rem;
        overflow-y: auto;
        header {
            padding-bottom: 1rem;
            h3 {
                margin-top: 0;
                margin-bottom: 1rem;
                font-size: $heading-size-1;
                font-weight: 500;
                color: $brand-primary-blue-2;
            }
            span {
                font-size: 12px;
                color: $brand-secondary-black-60;
                font-weight: 500;
            }
        }
        section {
            padding: 1rem 0;
        }
    }
}
.settings-label-toggle-wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;
    h5 {
        font-size: $font-size-md;
        color: $brand-secondary-black;
        white-space: nowrap;
        max-width: 95%;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}
.settings-label-toggle-wrapper-large {
    @extend .settings-label-toggle-wrapper;
    width: 90%;
}
.settings-label-dropdown-wrapper {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 5px;
    h5 {
        font-size: $font-size-md;
        color: $brand-secondary-black;
    }
    .setting-dropdown {
        margin-left: 10%;
        width: 40%;
    }
    .drop-up {
        .Dropdown-menu {
            top: unset;
            bottom: 105%;
        }
    }
}
.user-settings-dropdown-wrapper {
    width: 70%;
    .secondary-dropdown {
        width: 40%;
        .Dropdown-control {
            font-size: $base-font-size !important;
        }
        .Dropdown-menu {
            font-size: $base-font-size;
        }
    }
    .dropdown-values-disabled-container {
        .Dropdown-menu {
            cursor: not-allowed;
            .Dropdown-option:nth-child(2) {
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed !important;
            }
            .Dropdown-option {
                cursor: pointer !important;
            }
        }
    }
}

.support-contact-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.contact-label-dropdown-wrapper {
    justify-content: unset;
    width: unset;
    & > :nth-child(2) {
        margin-left: 170px;
    }
    .contact-list {
        margin-left: 120px;
    }
}

.support-contact-dropdown-menu {
    font-size: $base-font-size;
    color: $brand-secondary-black !important;
    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: $brand-seconday-gray-35;
        color: $brand-secondary-black;
    }
}
.drop-off-config-dropdown {
    padding: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > :first-child {
        width: 50%;
    }

    .dropdown-group-heading {
        padding: 4px;
    }
    .action-button-container {
        display: flex;
        justify-content: flex-end;
    }
}

.dotted-sublist-icon {
    border-left: 1px dotted;
    border-bottom: 1px dotted;
    height: 20px;
    width: 15px;
    position: absolute;
    left: 9px;
    top: -5px;
}
.sub-list-settings-item {
    position: relative;
    padding-left: 38px;
}
.sublist-wrapper {
    position: relative;
}
.sublist-wrapper > h5 {
    margin-left: 42px;
}
.top-grey-border {
    border-top: $gray-border;
}

.my-settings-wrapper {
    height: calc(100vh - 186px);

    nav {
        width: 28%;
    }
    main {
        width: 73%;
    }
}

.delegation-list-wrapper {
    border: $gray-border;
    padding: 1rem;
    padding-top: 8px;
    border-radius: 2px;
    margin-top: 15px;
    font-size: $base-font-size;
    color: $brand-secondary-black;
    font-weight: 500;
    hr {
        margin-top: 8px;
    }
    .scrollable-list {
        height: calc(100vh - 440px);
        overflow-y: auto;
    }
    .magic-checkbox-sm + label:before,
    .magic-checkbox-sm + label:after {
        top: -12px;
    }
    .magic-checkbox-sm-indeterminate + label:after {
        top: -10px !important;
    }
    .delegatee-item {
        display: flex;
        padding: 0 2px;
        p {
            max-width: 35vw;
            margin-bottom: 0;
        }
    }
    .delegatee-item:not(:last-child) {
        margin-bottom: 10px;
    }
}

.notification-option-container {
    .notification-helper-text {
        color: $brand-secondary-black;
        font-weight: 500;
        font-size: $font-size-md;
    }
}
.request-notification{
    
    
 .settings-label-toggle-wrapper{
    margin-left: 1.9px !important;
 }
}
.settings-container {
    height: calc(100% - 14px);
    width: 100%;
    overflow: hidden;
    padding: 10px 20px;
    
    .settings-title {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $brand-body_black;
        padding: 10px;
    }
}
.toggle-wrapper{
    padding-top: 1rem !important;
}

.savedMessage{
    overflow-y: auto;
    .saved-message-container {
        display: flex;
        width: 100%;
    }

    .settings-title {
        font-family: 'Mulish';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $brand-body_black;
        padding: 10px 10px 10px 0px;
    }

    .switch-wrapper {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1px;

        h5 {
            font-size: $font-size-md;
            color: $brand-secondary-black;
            white-space: nowrap;
            max-width: 95%;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        font-family: 'Mulish';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        height: 24px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 100px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 100px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
        background-color: $sapphire;
    }


    .saved-message-list-box {
        width: 29%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 34px;
        font-family: 'Mulish';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        .btn-with-icon {
            margin-top: 20px;
        }

        .saved-message-list {
            width: 100%;
            overflow-y: auto;
            max-height: 275px;
            min-height: 273px;
            border-radius: 0px 0px 3.2px 3.2px;
            border: 1px solid $gray-400;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            cursor: pointer;
        }

        .message-wrapper {
            display: flex;
            width: 100%;
            padding-left: 9px;
            min-height: 39px;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $gray-300;
            
              span {
                background-color: $gray-100;
                border: 1px solid $black;
                padding-inline: 8px;
                border-radius: 4px;
                margin-right: 5px;
                font-weight: 400;
              }

        }

        p {
            margin: 0;
        }

        .d-inline-block {
            display: inline-block !important;
        }

        .text-truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .btn-with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $sapphire;
        background: white !important;
        border-radius: 2px !important;
        color: $sapphire !important;
        width: 98px;
        height: 31px;
        padding: 5px;
        font-size: 16px;

        &>svg {
            margin-right: 8px;
        }
    }

    .message-box-wrapper {
        width: 435px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 81px;

        header {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 12px;
        }

        &>div {
            width: 100%;
            border-radius: 0px 0px 3.2px 3.2px;
            border: 1px solid $gray-400;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 275px !important;
            padding: 5px 24px 8px 11px;

            .item-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 9px;
                width: 100%;

                &>p {
                    font-size: 14px;
                    padding: 7px 13px;
                    background-color: $gray-100;
                    border: 1px solid $gray-400;
                    color: $gray-400;
                    border-radius: 3.2px;
                    width: 100%;
                    text-align: left;
                    font-style: italic;

                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 7px;
                }
            }

            .body-field {
                &>p {
                    height: 90px;
                    overflow-y: auto;
                }
            }
        }

        footer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 21px;

            &>button:last-child {
                margin-left: 11px;
            }
        }

    }

    .settings-form-item {
        display: flex;

        label {
            font-weight: 400 !important;
            font-size: 16px !important;
            margin-bottom: 0;
            color: $gray-400;
        }

        .form-check-input {
            height: 16px;
            width: 16px;
            margin-right: 10px;
        }
    }

}
.form-header{
    font-family: 'Mulish';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin-top:5px;
    }

.variable-list-accordion {
    padding-left: 0px !important;

    ul{
        padding:0px !important;
    }
    .accordion-body{
        padding-left: 0px !important;
    }

    .accordion-button {
        background-color: none;
        border: none;
        box-shadow: none;
        background-color: white !important;
        padding-left: 0px !important;


        &::after {
            display: none;
        }

        &:active {
            transform: none;
        }
    }

    .variable-list-header {
        font-family: 'Mulish';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $brand-primary-blue-2;

    }

    .variable-item-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        li {
            list-style: none;
            position: relative;

            span {
                margin-left: 4px;
                position: absolute;
                top: -2px;
                cursor: pointer;
            }
        }

        .main-text{
            font-family: 'Mulish';
            font-size: 14px;
            font-weight: 600;
            line-height: 26.74px;
            text-align: left;
            width: 25%;
            cursor: pointer;

        }
        .help-text{
            color: #6B7075;
            font-family: 'Mulish';
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 27.2px;
            text-align: left;
            width: 75%;
            
        }

    }
}

.settings-form-item {
    display: flex;

    label {
        font-weight: 400 !important;
        font-size: 16px !important;
        margin-bottom: 0;
    }
    .form-check-input {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        margin-top:4px;
        cursor: pointer;
    }
}

.active-msg {
    background-color: $sapphire-100 !important;
  }

.input-disabled{
    cursor:not-allowed;
    background-color: $gray-100 !important;
}

.input-disabled-list{
    cursor:not-allowed;
    opacity: 0.4;
}

.sm-admin{
    max-height: calc(100vh - 230px);
}