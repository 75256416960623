:root {
    /* ---Primary colors ----*/
    --brand-dark-blue: #05386b;
    --brand-light-blue: #0973ba;
    --brand-green: #88c656;
    --brand-dark-blue-10per: rgba(5, 56, 107,0.1);
    --brand-header-white:rgb(248, 249, 250);
    --theme_shamrock:#669440;
    /*---- Secondary colors--- */
    --brand-grey:  rgba(217, 217, 214,0.35);
    --brand-dark-grey: #ccc;
    --brand-black:rgb(16, 24, 32);
    --brand-black-50per:rgba(16, 24, 32,0.50);
    --brand-black-20per:rgba(16, 24, 32,0.20);
    --non-branded-light-blue_hover: #0d6efd40;
  }