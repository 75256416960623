.message-item {
    height: 67px;
    margin-bottom: 12px;
    border-radius: 5px;
    background: $white;
    font-size: $base-font-size;
    border: none;
    transition: all 0.3s;
    cursor: pointer;

    &-body {
        display: flex;
        padding: 5px;
    }

    &-group {
        width: calc(100% - 25px);
        &-row {
            display: flex;
            justify-content: space-between;

            .message-item-date-div {
                padding: 0px 10px 0px 0px;
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding-right: 0px;
    }
    &-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        div {
            display: flex;
            color: $brand-secondary-black;
            align-items: center;
            span {
                margin-left: 8px;
                font-size: $font-size-md;
                font-weight: 500;
            }
        }
    }
    &-selected {
        .message-item-footer {
            div {
                color: white;

                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
    &-title {
        color: $brand-secondary-black;
        font-weight: 700;
        margin-bottom: 5px;
    }

    &-checkbox {
        margin-right: 5px;

        .magic-checkbox-sm + label {
            &:before {
                width: 12px;
                height: 12px;
                top: -10px;
                left: 5px;
            }

            &:after {
                top: -9px;
                left: 9px;
                width: 4px;
                height: 8px;
            }
        }

        .magic-checkbox-sm:checked + label:before {
            border: 1px solid $white;
        }
    }

    &-subject {
        color: $brand-secondary-black;
        white-space: pre;
    }

    &-messagebody {
        color: $dark-gray;
        max-width: 480px;
        white-space: pre;
    }

    &-datetime {
        color: $dark-gray;
    }

    &-selected {
        background-color: $brand-primary-blue-2;
    }

    &.message-item-selected {
        .message-item-title,
        .message-item-subject {
            color: $white;
            white-space: pre;
          
        }

        .message-item-datetime,
        .message-item-messagebody {
            color: $white-90;
            white-space: pre;
        }
    }

    .message-list > &:nth-child(1) {
        margin-top: 3px;
    }

    &:hover {
        box-shadow: $hover-blue-shadow;
    }
}

// attachment

.spam-disabled {
    color: lightslategray;
}

.attachments {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    overflow: hidden;
    overflow-y: scroll;
}

.attachment-card {
    border: solid 1px #e1dfdd;
    font-size: 12px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;

    &.attachment-disabled {
        cursor: not-allowed;
    }
}

.attachment-icon {
    position: relative;
    width: 45px;

    img {
        top: -0;
        left: -8px;
        position: relative;
    }
}
.document-list-item {
    .message-item-title {
        font-size: $font-size-md;
        font-weight: 700;
    }
    .message-item-title {
        font-weight: 500;
        max-width: 76%;
    }
}

.text-ellipsis{
    display: block;
}