@import "../../../styles/variables";

.reports-container {
    height: calc(100% - 14px);
    width: 100%;
    overflow: hidden;

    .report-header-container {
        padding: 10px 20px;

        .title-container {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            align-items: center;

            .actions {
                font-size: $font-size-md;
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon-wrapper {
                    border: $gray-border;
                    padding: 5px 10px;
                }
                &>span {
                    cursor: pointer;
                    padding: 5px;
                    color: $icon-normal-state;

                    &:not(:last-child) {
                        margin-right: 5px;
                    }

                    &:hover {
                        color: $icon-hover-state;
                    }


                    &.disabled {
                        cursor: not-allowed;
                        opacity: 0.4;
                    }
                }
            }
        }

        .report-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            align-items: center;

            .metrics-container {
                display: flex;
                & > div {
                    margin-right: 25px;
                }
                .metrics-card {
                    display: flex;
                    flex-direction: column;
                    border: 1px solid $brand-secondary-black-30;
                    padding: 5px;
                    min-width: 200px;
                    border-radius: 10px;

                    .metrics-title {
                        font-size: $font-size-md;
                    }

                    .metrics-count {
                        font-size: $heading-size-1;
                        font-weight: 800;
                        color: $brand-primary-blue-2;
                    }
                }
            }

        }
    }

    .report-table-container {
        height: 100%;

        .total-records {
            margin-left: 20px;
            position: absolute;
            top: 20px;
            left: 239px;
        }

        .react-bootstrap-table {
            max-height: calc(100% - 16vh);
            overflow-y: scroll;
            padding: 0 12px;
            cursor: default;

            td{
                div {
                    cursor: default;
                }
                .with-badge {
                    cursor: pointer;
                }
            }

            th {
                white-space: nowrap;
            }

            tr.tr-hightlight {
                background-color: $brand-seconday-gray-35;
            }
        }
    }

    .report-table-container.clickable {
        .react-bootstrap-table {
            cursor: pointer !important;
            td{
                div {
                    cursor: pointer !important;
                }
            }
        }
    } 
        
    

    .react-bootstrap-table-pagination {
        position: absolute;
        bottom: 0%;
        background-color: white;
        padding-top: 0px !important;
        width: 100%;

        & > div:nth-child(1) {
            padding-left: 20px;
            padding-top: 5px;
        }
        .react-bootstrap-table-pagination-list {
            & > ul {
                margin-bottom: 0px;
            }
        }
    }
}

.filter-modal-container {
    display: flex !important;
    flex-direction: row-reverse;

    .modal-dialog {
        height: 100%;
        margin: 0px;
        transform: translate(100%, 0) !important;
    }

    .modal-content {
        width: 385px;
        height: 100%;
    }

    .modal-title {
        color: var(--brand-black);
    }

    
    .modal-header {
        padding: 10px 20px 10px 20px;
        background-color: white;
        padding: 16px;
        border-bottom-color: #dee2e6;

        .modal-title {
            color: $brand-body_black;
            font-size: 20px;
            line-height: 120%;
            font-weight: 700;
        }
    }
    .modal-footer {
        button {
            border-radius: 2px;
            font-size: 16px;
        }
    }
    .modal-content {
        border-style: none;
    }

    button {
        padding: 6px 15px 6px 15px;
    }

    &.show {
        pointer-events: auto;

        * {
            pointer-events: auto;
        }

        .modal-dialog {
            transform: translate(0, 0) !important;
        }
    }

    .filter-loader {
        .dropdown-container {
            display: flex;
            justify-content: space-between;
            padding: 10px 10px 10px 10px;

            .dropdown {
                display: flex;
                align-items: center;

                .clear-button {
                    padding: 0px 10px 0px 10px;
                    margin-left: -56px;
                    z-index: 1;
                    color: $white;
                    cursor: pointer;
                }
            }

            .Dropdown-control {
                width: 200px;
            }

            .Dropdown-menu {
                width: 200px;
            }

            .Dropdown-placeholder {
                max-width: 135px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $white;
            }
        }

        .warning-text {
            padding-left: 10px;
        }

        .warning-highlight {
            border: 2px solid $error-color;
        }

        .header-icons-container {
            display: flex;
        }

        .list-items-wrapper {
            height: 145px;
            overflow-y: auto;

            .search-not-found-text {
                margin-top: 10px;
            }
        }

        .search-input-wrapper {
            margin-bottom: 15px;

            .search-input {
                height: 21px;

                &::placeholder {
                    font-style: normal;
                }

            }
        }

        .checkbox-list-item {
            display: flex;
            padding: 0 2px;

            .display-checkBox {
                margin-bottom: 6px;
            }
        }

        .checkbox-list-item:not(:last-child) {
            margin-bottom: 10px;
        }

        .list-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 5px;

            label {
                font-size: 14px;
                font-weight: 400;
                padding-left: 10px;
                padding-right: 10px;
                color: #212529;
            }
        }

        .date-accordion {
            margin-bottom: 10px;

            .date-container {
                position: relative;

                .date-item {
                    display: flex;
                    align-items: center;
                }

                p {
                    position: absolute;
                    font-size: 10px;
                    right: 0px;
                    color: $error-color;
                    margin-top: 4px;
                    padding-bottom: 10px;
                }
            }
        }

        h2 {
            margin-top: 0px;
        }

        .accordion {
            margin-top: 20px;

            .accordion-item:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, .125) !important
            }
        }

        .calendarContainer {
            margin: 0px !important;
        }
    }
}

.manage-columns-modal-container {
    .modal-body {
        padding: 0px;
    }
    .sortable-container {
        padding: 10px 0px;

        .draggable-item {
            border-bottom: $gray-border;
            padding: 0px 20px;
            margin-bottom: 0px !important   ;

            .label {
                border: 0px;
            }
        }
    }

}
.save-filter {
    .modal-footer-button-container {
        display: flex;
        gap: 8px;
        width: 100%;

        button {
            margin-left: 0px !important;
        }
    }
    .modal-body {
        height: 100px !important;
    }
}

.filter-result-container {
    margin: 5px 0px;

    .filter-badge {
        cursor: pointer;
        text-align: center;
        border-radius: 41px;
        padding: 4px 10px 4px 10px;
        margin-left: 10px;
        background-color: $brand-primary-blue-2;
        color: $white;

        svg {
            margin: 0px 0px 1px 10px;
        }


    }
}

.filter-action-modal {
    display: flex !important;
    flex-direction: row-reverse;

    .modal-dialog {
        margin: 10px;
        max-width: 365px !important;
    }

    .modal-body {
        max-height: 100px;
    }

    .modal-content {
        margin: auto;
        width: 365px;
    }

    input {
        width: 100%;
        padding: 10px;
    }

    p {
        position: absolute;
        margin-top: 10px;
        padding-left: 1px;
    }
}

.sortable-container {
    padding: 10px;

    .draggable-item {
        display: flex;
        width: 100%;
        align-items: center;
        cursor: move;
        /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;

        &:active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .label {
            padding: 10px;
            border: $gray-border;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: all .3s;

           
            span.visibility-icon {
                cursor: pointer;

                i {
                    color: $icon-normal-state;
                    padding-right: 5px;

                    &.visible {
                        color: $brand-primary-blue-1;
                    }
                }
                &.disabled {
                    cursor: not-allowed;

                    i {
                        color: $dark-gray;
                    }
                }
            }
        }
    }
}

.reports-popover-body {
    font-size: $font-size-md;
    max-height: 200px;
    overflow: auto;

    div {
        white-space: nowrap;
    }

    li {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-width: 100%;
        list-style-position:inside;
     
    }
}

#popover-files,
#popover-recipients {
    min-width: 150px;
    max-height: 300px;
    max-width: 440px;

    .popover-body {
        overflow: hidden;
        overflow-y: auto;
    }

    ul {
        margin-bottom: 1px;
        padding-left: 0;
    }
}

#context-menu-popover {
    .popover-body {
        padding: 0px;

        .resend-access-menu {
            svg {
                margin-top: 4px;
            }
        }
        .menu-item-container {
            width: 170px;
            font-size: 14px;
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 10px;

            &:hover {
                background-color: $brand-secondary-gray;
            }

            .menu-option-text {
                margin-left: 10px;
            }

            .right-arrow {
                position: absolute;
                right: 13px;
            }

        }
        .disabled {
            cursor: not-allowed;
            color: $icon-normal-state;
        }
    }
    /* Popover arrow style
    .popover-arrow::after{
        background:transparent !important;
        z-index: 99;
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid lightcyan;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: -13px;
        bottom: 0px;
    }
    */
}

#resend-link-popover {
    .popover-body {
        padding: 0px;
        max-height: 125px;
        overflow: hidden;
        overflow-y: auto;

        ul {
            cursor: pointer;
            padding: 0px;
            margin: 0px;
        }

        li {
            list-style: none;
            padding: 10px;

            &:hover {
                background-color: $brand-secondary-gray;
            }
        }
    }
}

#popover-activity {
    max-width: 700px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    font-size: $font-size-md;

    thead,
    tbody {
        border: 1px solid;
    }

    th {
        white-space: nowrap;
        text-align: center;
        padding: 10px;
        font-size: 12px;
        font-weight: 700;
        border-right: 1px solid;
    }

    td {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid;
        padding: 5px 5px;
        cursor: default;
        text-align: center;
    }
}