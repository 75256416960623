.accordion-with-crud {
    .accordion-item {
        border: none !important;

        .accordion-button {
            color: $brand-primary-blue-1;
            padding: 5px 30px;
            position: relative;
            background-color: transparent;
            box-shadow: none;

            &:after {
                position: absolute;
                left: 5px;
            }

            &:focus {
                border: none;
                box-shadow: none;
            }
        }

        .accordion-body {
            padding: 5px 30px;
        }
    }

    &:not(:last-child) {
        border-bottom: $gray-border;
    }
}

.settings-accordian {
    .accordion-item {
        h2 {
            margin: 0;
        }
        .accordion-button {
            padding: 5px 10px;
        }
    }

    .accordion-title-info-container {
        padding: 8px;
        h4 {
            font-size: $font-size-md;
            color: $brand-primary-blue-2;
            font-weight: 700;
        }
        span {
            font-size: $base-font-size;
            color: $brand-secondary-black-60;
            font-weight: 500;
        }
    }
    .accordion-item:last-child {
        border-bottom: $gray-border;
        border-top: none !important;
    }
}

.settings-accordian-container > .accordion:nth-child(2) .accordion-item {
    border-top: 0 !important;
}
.settings-accordian > .second-accordian-item {
    border-top: 0 !important;
    border-bottom: $gray-border !important;
}
.first-accordian{
    border-left: none !important;
    border-right: none !important;
}