@import "../../../../../styles/variables";

.drop-off-sideMenu-wrapper{
    display: flex;
    align-items: baseline;
    padding: 0.5rem 1rem;
    position: relative;
    & > div{
      padding: 0!important;
    }
  }
  .drop-off-title{
    width: 85%;
  }
  
  .drop-off-sideMenu-wrapper {
    .dropdown{
        .dropdown-toggle{
          color: grey;
          font-size: $font-size-md;
          --bs-text-opacity: 1;
          color: $brand-secondary-black-60;
          font-weight: 700;
          padding: 0 !important;
        }
        .dropdown-menu{
            background-color: unset;
            border: none;
            width: 271px;
            left: -44px !important;
        }
    }
  }

  .drop-off-sideMenu-wrapper > .dropdown > .dropdown-toggle::after{
    display: none;
  }
  
  .drop-off-sideMenu-wrapper > .dropdown > .dropdown-toggle:hover{
    background: transparent ;
  }
  
  .drop-off-sideMenu-wrapper > .dropdown >.dropdown-menu >.dropoff-dropdown-item:first-child{
    margin-top: 8px;
  }
  
  .dropoff-dropdown-item{
    position: relative;
    font-size:$font-size-md;
    --bs-text-opacity: 1;
    color: $brand-secondary-black-60 !important;
    font-weight: 700!important;
    padding-left: 5rem;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
  }
  
  .dropoff-dropdown-item.active-item{
  background-color: white !important;
  }
  .dropoff-dropdown-item.active-item:before {
    content:"";
    width: 6px;
    height: 32px;
    background-color: $brand-primary-green;
    position: absolute;
    left: 15%;
    bottom: 0;
  }
  
  .dropoff-dropdown-item:active{
    background-color: unset;
  }
  .dropoff-dropdown-item:hover{
    background-color: unset;
  }

  .sender_accordian_help_text{
    white-space: pre;
    line-height: 2;
  }