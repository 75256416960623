@import "../../../../styles/variables.scss";

.tab-container{
    height: calc(100% - 14px);
    width: 100%;
    overflow: hidden;
    margin: 10px;

    .tab-header-container {
        border-bottom: 1px solid $gray-200;
        height: 44px;
    }
    
    .tab-header-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        position: absolute;
        margin-top: 11px;
    }

    .tab-wrapper {
        height: 32px;
        margin:0px 15px;
    }

    .tab-active {
        border-bottom: 3px solid $brand-primary-blue-2;
    }

    .tab-selected {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: $brand-primary-blue-2;
    
    }
    
    .tab-default {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        cursor: pointer;
    
    }

    .tab-content{
        margin-top: 10px;
        margin-bottom: 8px;
        height: 100%;
        overflow: hidden;
        section{
            margin-top: 10px;
        }

    }
}
