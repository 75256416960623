.side-menu-button {
    cursor: pointer;
    height: 60px;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    &.active:before {
        content: "";
        border-left: $selected-button-border;
        position: absolute;
        height: 60px;
        left: 0px;
    }
}

.button-default {
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    text-align: center;
    border: none;
    padding: 5px 20px;
    min-width: 100px;
    cursor: pointer;
    line-height: normal;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.button-primary-green {
    @extend .button-default;
    background-color: $brand-primary-green;
    color: $white;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
}

.button-primary-green-outlined {
    @extend .button-default;
    background-color: $white;
    border: 1px solid $brand-primary-green;
    color: $brand-primary-green;
}

.button-white {
    @extend .button-default;
    background-color: $white;
    border: 1px solid #e0e0e0;
    color: $brand-secondary-black;
    border-radius: 2px;
    min-width: 80px;
}

.button-grey {
    @extend .button-default;
    background-color: $gray-100 !important;
    border: 1px solid $gray-400;
    color: $brand-body_black !important;
    border-radius: 2px;
}
.button-primary-green-rounded {
    @extend .button-primary-green;
    border-radius: 50px;
    box-shadow: none;
}

.button-primary-blue {
    @extend .button-default;
    color: $white;
    background-color: $brand-primary-blue-1;
}

.button-primary-blue-rounded {
    @extend .button-primary-blue;
    border-radius: 18px;
}

.button-primary-blue-2 {
    @extend .button-default;
    border: 1px solid $brand-primary-blue-2;
    color: $white;
    background-color: $brand-primary-blue-2;
}

.button-primary-blue-2-rounded {
    @extend .button-primary-blue-2;
    border-radius: 18px;
}

.button-primary-blue-2-outlined {
    @extend .button-default;
    background-color: $white;
    border: 1px solid $brand-primary-blue-2;
    color: $brand-primary-blue-2;
}

.add-recipient {
    border-bottom: 1px dashed $brand-primary-blue-2;
    cursor: pointer;
    display: block;
    padding: 10px 5px;
    position: relative;
    text-align: center;
    text-decoration: none;
}
.button-secondary-blue-outlined {
    @extend .button-default;
    background-color: white;
    outline: 1px solid $brand-primary-blue-2;
    color: $brand-primary-blue-2;
    padding: 5px;
    min-width: unset;
    padding-inline: 1em;
    &:hover {
        color: white;
        background-color: $brand-primary-blue-2;
    }
}
.button-secondary-grey-outlined {
    @extend .button-default;
    background-color: white;
    outline: 1px solid $brand-secondary-black-50;
    color: $brand-secondary-black-50;
    padding: 5px;
    min-width: unset;
    padding-inline: 1em;
    &:hover {
        color: white;
        background-color: $brand-secondary-black-50;
    }
}
.button-secondary-blue-default {
    @extend .button-default;
    color: $white;
    background-color: $brand-primary-blue-2;
    padding: 5px;
    visibility: visible !important;
    min-width: unset;
    padding-inline: 1em;
}

.button-green-default{
    color: var(--Shamrock-300, #A3BF8C);
    font-family: Mulish;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 24px */
    visibility: visible !important;
}

.button-blue-enable-disable{
    color: var(--Theme-Navy, #05386B);
    /* Body/Regular */
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    visibility: visible !important;
}
.action-dropdown-btn {
    color: var(--Gray-900, #151617);

    /* Body/Regular */
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    width:100%;
}

.filter-dropdown-text {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    gap: 160px;
    align-self: stretch;
    color: rgba(21, 22, 23, 1);
}

.button-Layout-default{
    display: flex;
    width: 96px;
    padding: 8px 8px 9px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-width: 0px;
    background: var(--HitBox, rgba(255, 255, 255, 0.00));
}

.action-button-layout{
    display: flex;
    padding: 4px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.btn-group-vertical {
    display: inline-flex !important;
}

#recipients-inline-action-dropdown {
    padding: 2px 10px;
    padding-top: 4px;
    margin-top: -5px;
    height: 30px;
    border-radius: 0px !important;
    font-size: smaller;
    background: transparent;
    color: #303641;
    box-shadow: none;
    visibility: visible !important;
}

#recipients-inline-action-dropdown:after{
    content: none
}

.dropdown-menu.show.dropdown-menu-end {
    position: absolute;
    inset: 0px 0px auto auto;
    transform: translate3d(0px, 27.2px, 0px);
    width: 145px;
    padding:0px;
    align-items: center;
}


.button-underlined {
    @extend .button-default;
    background-color: transparent;
    text-decoration: underline;
    color: $brand-secondary-black;
}

.add-question-btn {
    width: 160px;
    box-shadow: 0px 0px;
    padding: 0px;
    border-radius: 4px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-primary-blue-2;
    color: $white;
    border: none;

    &::before {
        content: "+";
        font-size: 16px;
        padding: 0px 4px 3px 0px;
    }
}
.add-folder-btn {
    @extend .add-question-btn;
    width: 100px;
    margin-left: 10px !important;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.search-dropdown-button {
    .dropdown-toggle {
        color: $brand-secondary-black;
        background-color: white !important;
        width: 125px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-left: $gray-border !important;
        border-radius: 0 8px 8px 0 !important;
        &:hover,
        &:focus,
        &:active {
            color: $brand-secondary-black;
            background-color: white !important;
            box-shadow: none !important;
        }
        &:hover {
            color: white;
            background-color: $brand-secondary-black-30 !important;
        }
    }
    .dropdown-item {
        color: $brand-secondary-black;
        &:hover {
            background-color: $brand-secondary-black-30;
        }
        &:focus,
        &:active {
            background-color: $brand-secondary-black-30;
        }
    }
    .dropdown-menu {
        width: 125px;
    }
}

.breadcrumb-dropdown-container {
    @extend .search-dropdown-button;
    .dropdown-toggle {
        width: unset;
        border-radius: 0 !important;
        border: $gray-border !important;
        padding: 2px 8px;
    }
}

#compose-button {
    border-radius: 15px !important;
    color: $white;
    background-color: $brand-primary-blue-2;
    width: 100%;
    padding: 10px 0;
    font-weight: 600;
    text-align: center;

    i {
        float: inherit;
        position: inherit;
        top: inherit;
        padding: 0;
        background: none;
        line-height: 1.5;
    }
}

.document-status-button {
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    text-align: center;
    padding: 1px 8px;
    min-width: 80px;
    border: 1px solid;
}
.document-pending-upload {
    color: $orange-500;
    border-color: $orange-500;
    background-color: $orange-100;
}
.document-partially-uploaded {
    color: $info-500;
    border-color: $info-500;
    background-color: $info-100;
}
.document-completed {
    color: $success-500;
    border-color: $success-500;
    background-color: $success-100;
}
.document-overdue {
    color: $prince-500;
    border-color: $prince-500;
    background-color: $prince-100;
}
.document-failed {
    color: $error-500;
    border-color: $error-500;
    background-color: $error-100;
}
.add-files-btn {
    all: unset;
    width: auto;
    color: $brand-primary-blue-1;
    padding: 0 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
        margin-right: 5px;
    }
}
.new-request-button{
    @extend .add-question-btn;
    display: flex;
    align-items: center;
    width: 140px;
    padding: 3px;
    font-size: $font-size-md;
    svg{
        margin-right: 8px;
    }
    &::before{
        display: none;
    }
}

.clear-icon {
    cursor: pointer;
    font-size: 10px;
    background-color: lightgray;
    border-radius: 50%;
    color: $white;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.switch-button-group {
    list-style: none;
    display: flex;
    border: solid 1px $brand-primary-blue-2;
    border-radius: 2px;
    padding: 0px;
    margin-bottom: 0;

    li:last-child {
        margin-right: 0;
    }

    li {
        width: 93px;
        height: 31px;
        text-align: center;
        flex-grow: 100;
        cursor: pointer;
        font-size: 12px;
        line-height: 23px;
        padding: 4px 8px;
    }

    li.active {
        background-color: $brand-primary-blue-2;
        color: #fff;
    }

    li.disabled {
        cursor: not-allowed;
        color: rgb(161, 159, 157) !important;
        background: transparent;
    }
}