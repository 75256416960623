@import "../../../styles/variables";

#archive-container {
    height: 100%;
    padding: 0 1.3rem 0 1rem;

    header {
        .others-inbox-info-label {
            margin: -1rem -1rem 0.5rem -1rem;
        }

        .folder-main-title-container {
            display: flex;
            align-items: center;
            margin-top: 5px;
            justify-content: space-between;

            .folder-name {
                display: flex;
                align-items: center;

                span {
                    font-size: $heading-size-1;
                    font-weight: 500;
                    color: $brand-secondary-black-60;
                    margin-left: 1%;
                    white-space: pre;
                }

                i {
                    font-size: $heading-size-1;
                    color: $brand-secondary-black-60;
                    margin-left: 8px;
                }
            }

            .file-view-toggle-container {
                display: flex;
                align-items: center;

                &>*:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
        .button-secondary-blue-default {
            display: flex;
            align-items: center;
            padding: 8px;
            border-radius: 2px;
            gap:8px;
        }

    }

    main {
        border-top: $gray-border;
    }

    .file-view-loader {
        height: calc(100% - 116px);
    }

    .header-toolbar-action {
        right: 3px;
        top: 2px;
        padding-right: 10px;

        span {
            margin-left: 0px;
        }
    }

    .create-folder-action {
        i {
            font-size: 20px;
            padding-top: 6px;
            color: $brand-primary-blue-2;
        }
    }

    .message-list {
        height: calc(100vh - 200px);
    }

    .document-list {
        height: calc(100vh - 266px) !important;
    }
    .archive-table-container {
        max-height: calc(100vh - 228px);
        overflow-y: auto;

        .settings-table {
            table-layout: fixed !important;

            td {
                overflow: hidden;
            }
        }
    }

    .toolbar-icon-disabled {
        opacity: 0.4;
    }

    .folder-table {
        table-layout: fixed !important;

        .folder-description {
            white-space: pre;
        }

        .sharedWith {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.breadcrumb-container {
    font-size: 13px;
    height: 22px;

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 0;

        &>li {
            &:first-child::before {
                content: "";
                display: none;
            }

            &::before {
                content: ">";
                padding-inline: 4px;
            }
        }
    }

    li {
        cursor: pointer;
        white-space: pre;
        overflow-x: hidden;
        font-size: $base-font-size;
        color: $brand-secondary-black-60;
        font-weight: 700;

        &:hover {
            font-weight: bold;
            color: $black;
        }
    }
}

.folder-name-container {
    display: flex;
    align-items: center;

    .folder-name {
        max-width: 80%;
        white-space: pre;
    }

    p {
        margin-bottom: 0;
        margin-left: 10px;
        font-size: $font-size-md;
        font-weight: 500;
        color: $brand-primary-blue-2;
        white-space: pre;

        cursor: pointer;

        &:hover {
            text-decoration: underline;
            text-decoration-color: $brand-primary-blue-2;
        }
    }

    .folder-path-text {
        margin-left: 10px;
        font-size: 12px;
        color: $gray-400;
        font-weight: 400;
        max-width: 20vw;
        white-space: pre;
        cursor: pointer;
    }
}

.my-downloads {
    .description {
        font-size: $base-font-size;

        i {
            color: $brand-primary-blue-2;
        }
    }
}

.move-to-info-description{
    padding-right: 60px;
    font-size: 12px;
}

.move-to-info-icon{
    color: "#0973ba" !important;
}
.download-type-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;

    .download-card {
        padding: 40px;
        border: $gray-border;
        min-height: 100px;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:not(:last-child) {
            margin-right: 10%;
        }

        &.selected {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            background-color: $brand-primary-blue-2;
            color: $white;
        }

        .title {
            margin-bottom: 10px;
        }

        .download-description {
            position: absolute;
            inset: 0 0 0 0;
            background-color: $brand-primary-blue-2;
            opacity: 0;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            transition: all 0.5s;
        }

        &:hover {
            .download-description {
                opacity: 1;
            }
        }
    }
}

.share-folder-modal {
    .modal-dialog {
        max-width: 550px;
    }
    .modal-body {
        overflow: visible !important;
    }
    .modal-content {
        min-width: 525px;

        .share-folder-text {
            display: block;
            margin-bottom: 10px;
        }

        .simple-dropdown {
            right: 0;
            width: 72px;
            border: none;
            box-shadow: none;
            cursor: pointer;

            .share-folder-dropdown__value-container {
                margin: 0px;
                padding: 0px;
            }

            .share-folder-dropdown__control,
            .share-folder-dropdown__single-value {
                display: flex;
                align-items: center;
                border-bottom: none;
                font-weight: 500;
                background-color: white;
                color: $brand-body_black;
                border: none;
                box-shadow: none;
                max-width: 72px;
                cursor: pointer;
            }

            .share-folder-dropdown__menu {
                width: 100px;
                max-height: 102px;
                border-radius: 4px;
                overflow: hidden;
                border: 1px solid $gray-200;
                background: $sapphire-100;
                right: 5px;

                .share-folder-dropdown__option {
                    display: flex;
                    padding: 4px 13px;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;

                    &:hover {
                        background-color: $sapphire-200;
                    }
                }

                .share-folder-dropdown__option--is-selected {
                    background-color: transparent;
                    color: $brand-body_black;
                }

                .share-folder-dropdown__option:nth-child(3) {
                    border-top: 1px solid #c4c6c8;
                }
            }
        }

        .header-dropdown {
            .share-folder-dropdown__menu {
                width: 75px;
                top: 27px;
                right: 0px;
            }
        }

        .inline-action-dropdown {
            .share-folder-dropdown__option--is-selected {
                &:before {
                    content: "\f00c";
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin-right: 3px;
                    margin-bottom: 8px;
                    color: $theme_shamrock;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 600;
                }
            }
        }

        .share-folder-top-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .recipients-container {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .recipient-wrapper {
                    width: 82%;
                    border: 1px solid #898d91;
                }

                // Below class name to be replaced with CSS selector technique
                .css-1jfjov-menu {
                    max-height: 300px;
                    overflow-y: auto;
                }

                &>div>div>div:first-child {
                    max-height: 92px;
                    overflow-y: auto;
                }
            }
        }

        .button-primary-blue-2 {
            display: flex;
            min-width: 85px;
            padding: 6px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            line-height: 24px;
            font-size: 16px;
        }
    }

    .share-folder-users-section {
        margin-top: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;

        .no-users-found {
            text-align: center;
        }

        .user-card {
            display: flex;
            align-items: center;
            height: 40px;
            border-bottom: 1px solid #c4c6c8;
            justify-content: space-between;

            span {
                cursor: default;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 85%;
                overflow: hidden;
            }

            &:last-child {
                border-bottom: none !important;
            }
        }
    }
}

.more-users-exception {
    .modal-body {
        overflow: hidden;
    }

    .user-card {
        position: relative;

        .inline-action-dropdown {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}

#sharedFolderPopover {
    .popover-arrow {
        display: none;
    }
}

.shared-folder-popover-body {
    padding: 0;

    .list-group {
        .list-group-item {
            border-left: none;
            border-right: none;
            max-width: 200px;

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.folder-actions-container {
    .toolbar-icon-disabled {
        opacity: 0.6;

        svg {
            opacity: 0.4;
        }
    }
}

/*create folder model*/
.create-folder-modal {
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 570px;
        pointer-events: auto;
        background-clip: padding-box;
        outline: 0;
    }

    .modal-body {
        padding: 20px;
        max-height: 100% !important;
        .label {
            color: $brand-body_black;
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
        }

        .additional-info-label {
            text-wrap: nowrap;
        }
    }

    .radio-options {
        display: flex;
        height: 76px;
        flex-shrink: 0;
        background-color: rgba(230, 235, 240, 0.5);
        place-items: center;
        padding: 16px;

        .radio-label-text {
            color: $brand-body_black;
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            margin-left: 8px;
        }

        .radio-option:nth-child(2) {
            margin-left: 87px;
        }

        .bold-text {
            font-weight: 700;
        }
    }

    .custom-radio {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .pointer-cursor {
        cursor: pointer;
    }

    .additional-info {
        margin-left: 21px;
        color: #898d91;
    }

    .selected-radio-label {
        font-weight: 700;
        color: #212529;
    }

    .folder-retention-dropdown {
        width: 221px;
        font-size: 14px;
        cursor: pointer;
    }

    .create-folder-modal-container {
        .create-folder-input {
            margin-bottom: 20px;
            position: relative;

            .error {
                border-color: $error-color !important;
            }

            .text-danger {
                position: absolute;
                color: $error-color;
                margin-top: 0.25rem;
                font-size: 0.875em;
            }

            .folder-input-text {
                border-radius: 2px !important;
                border: 1px solid var(--gray-400, #898d91);
            }
        }
    }

    @media (max-width: 768px) {
        .settings-table .overflowText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .settings-table .header-toolbar-action {
            flex-direction: column;
        }

        .settings-table .add-folder-btn {
            margin-top: 1rem;
        }
    }
}

.refresh-button-wrapper-col {
    padding-left: 2px;
}
.refresh-button-wrapper {
    margin-top: 7px;
    padding: 5px 7px;
    width: 40px;
    height: 33px;
    border: $gray-border;
    border-radius: 0px;
    cursor: pointer;
    text-align: center;
}