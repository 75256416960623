@import './brand_colors.css';
body {
  overflow: hidden !important;
}

.right {
  float: right !important;
}

.container-fluid {
  width: 100%;
  height: 100%;
  padding: 0;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
  position: relative;
  margin: 3px 0 3px 0;
}

.layout-dropdown {
  width: 80%;
  margin: 5px 18px 5px 18px;
}

.layout-dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.layout-dropdown-toggle:empty::after {
  margin-left: 0;
}

.layout-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  /* padding: 0.5rem 0; */
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-toggle > i{
  padding-inline: 5px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link span {
  text-shadow: none;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  height: 48px;
  margin: 0px !important;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ml-auto {
  float: right;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 1.5rem !important;
  font-weight: 600;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .layout-dropdown-menu {
  position: static;
  float: none;
}

.navbar-nav .layout-dropdown-menu.show {
  display: block;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .layout-dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .modal-dialog {
    max-width: 600px;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .layout-dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .modal-dialog {
    max-width: 900px;
  }

  .modal-sm {
    max-width: 600px;
  }

  .overlayPopup .modal-sm {
    max-width: 450px;
  }

  .modal-xs {
    max-width: 300px;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .layout-dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .layout-dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-right: 5px;
}

.navbar-expand .navbar-nav .layout-dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1.75rem 2rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: white;
  border-radius: 0px !important;
  border-bottom: 1px solid #e8e8e8;
  height: 50px;
  font-size: 1.25rem;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0 8px #dcdcdc;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.breadcrumb-item a {
  color: teal;
}

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#wrapper #content-wrapper {
  overflow-x: hidden;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 67px);
}

.navbar-nav .form-inline .input-group {
  width: 100%;
}

.navbar-nav .nav-item.active .nav-link {
  color: #fff;
}

.navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle::after {
  text-align: center;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  color: #f7f6f6;
  position: absolute;
  right: 12px;
  top: 15px;
  font-size: 1rem;
}

.navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span,
.navbar-nav .nav-item.layout-dropdown .layout-dropdown-menu span {
  margin: 10px;
}

.navbar-nav .nav-item.layout-dropdown.show .layout-dropdown-toggle::after {
  content: "\f107";
}

.navbar-nav .nav-item.layout-dropdown.no-arrow .layout-dropdown-toggle::after {
  display: none;
}

.navbar-nav .nav-item .nav-link:focus {
  outline: none;
}

.navbar-nav .nav-item .nav-link .badge {
  position: absolute;
  margin-left: 0.75rem;
  top: 0.3rem;
  font-weight: 400;
  font-size: 0.5rem;
}

@media (min-width: 768px) {
  .navbar-nav .form-inline .input-group {
    width: auto;
  }
}

.archive-tree .rc-tree-node-selected{
  background-color: #021f54 !important;
}

@media (min-width: 768px) {
 
  #archive-add-folder {
    border-radius: 10px !important;
    float: right;
    color: var(--brand-green);
    border: 1px var(--brand-green) solid;
    padding-left: 35px;
  }

  #archive-add-folder {
    font-size: 14px;
    font-weight: bold;
  }

  #archive-add-folder i {
    padding: 2px 0px 0px 8px;
    -webkit-text-stroke: 1px white;
    font-size: 18px;
    background-color: transparent;
  }
}

footer.sticky-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 20px;
  background-color: rgb(241, 236, 236);
  width: 100%;
  font-size: 11px;
  padding: 0px 7px 0px 7px;
}

footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}

.nav-item > .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: white;
}

.nav-item button {
  padding-right: 10px !important;
  font-size: 14px;
  line-height: 0.5;
}

.layout-dropdown-menu .layout-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem 0.5rem 2.5rem;
  clear: both;
  font-weight: 600;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  font-size: 14px;
  text-decoration: none;
  margin: 0.5rem 0;
}

.layout-dropdown-menu .layout-dropdown-item:hover {
  text-decoration: none;
}

.toggled .layout-dropdown .layout-dropdown-menu .layout-dropdown-item {
  padding-left: 1.5rem;
}

.react-bs-table-bordered,
.Select-control {
  border-radius: 0px !important;
}

.btn i,
.btn-sm,
.btn-group-sm > .btn {
  border-radius: 0px !important;
}


.table-hover > tbody > tr:hover table-hover > tbody > tr {
  background-color: #edf9ff;
}

.table-hover > tbody > tr:hover {
  background-color: #edf9ff;
  cursor: pointer;
}

.row-selected {
  background-color: #caedff !important;
}

label,
table {
  font-size: 12px;
}

.vertical-middle{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.nav-tabs.nav-justified > li > a {
  border-radius: 0px !important;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border-top: 4px solid #0af;
}

.nav.nav-tabs > a.nav-item.active,
.nav.nav-tabs > a.nav-item:hover,
.nav.nav-tabs > a.nav-item:focus {
  border-top: 4px solid #0af;
}

.bootstrap-grid-no-padding {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bootstrap-grid-sm-padding {
  padding-right: 5px !important;
  padding-left: 5px !important;
  padding-bottom: 10px;
}

.bootstrap-grid-md-padding {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.tag-label {
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 5px;
  font-size: smaller;
}

.tab-content-container {
}

.tab-content-container-left-card {
}

.tab-content-container-middle-card {
  padding: 15px 15px 0px 15px;
  background-color: grey;
  text-align: center;
  border-right: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.tab-content-container-middle-card-no-content {
  padding: 15%;
  text-align: center;
  border-right: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

.tab-content-container-right-card {
}

.tab-no-content-message {
  color: white;
}

/* ===== Begin Procee Return Modal ===== */
.contentClassName {
  top: 0px !important;
}
/* Need to remove the header*/

.contentClassName {
  top: 0px !important;
}
/* Need to remove the header*/
.custom-window {
  position: relative;
  height: 100%;
  font-size: 14px;
}

.custom-window .custom-window-header {
  background: rgb(241, 236, 236);
  padding: 10px 15px;
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  line-height: 30px;
}

.custom-window .custom-window-header .header-inner {
  position: relative;
  padding-right: 130px;
  padding-left: 35px;
}

.custom-window .custom-window-header .header-inner .fa-rocket {
  color: gray;
  font-size: 22px;
  position: absolute;
  left: 0px;
  top: 4px;
}

.custom-window .custom-window-header .header-inner .title {
  color: #1a8fbf;
  font-size: 16px;
  font-weight: bold;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.custom-window .custom-window-header .header-inner .header-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.custom-window .custom-window-header .header-inner .header-btn button {
  width: 24px;
  height: 24px;
  font-size: 14px;
  margin-left: 2px;
  background: transparent;
  border: 1px solid transparent;
  color: #000000;
  opacity: 0.4;
  line-height: 24px;
}

.custom-window .custom-window-header .header-inner .header-btn button:hover {
  border: 1px solid #c1c1c1;
  opacity: 0.6;
}

.custom-window .custom-window-content {
  padding: 65px 15px 85px;
  height: 100%;
}

.custom-window .custom-window-content .nav-tabs {
  margin-bottom: 10px;
}

.custom-window .tab-content {
  height: calc(100% - 50px);
}

.custom-window .tab-content .tab-pane,
.custom-window .tab-content .tab-pane .h100 {
  height: 100%;
}

.custom-window .custom-window-content .pr-right-pannel,
.custom-window .custom-window-content .pr-left-pannel,
.custom-window .custom-window-content .pr-center-pannel {
  position: relative;
  height: 100%;
}

.custom-window .custom-window-content .scroll {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  padding-right: 1px;
}

.custom-window .custom-window-content .box {
  height: 320px;
  display: block;
  background: #ccc;
  margin-bottom: 10px;
}

.custom-window .custom-window-footer {
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  background-color: white;
}

.custom-window .custom-window-footer .btn + .btn {
  margin-left: 10px;
}

.custom-window .accordion {
  margin-bottom: 10px;
}

.custom-window .tab-content .btn-toolbar {
  display: block;
  padding-bottom: 5px;
}

.custom-window .bookmarksPane .card-header h3 {
  font-size: 16px;
  font-weight: 400;
  background-color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}

#process-return-groups {
  height: 100%;
  position: relative;
}

.input-group.date > input[type="text"] {
  z-index: 0 !important;
}

.multi-select-widthauto .Select-menu-outer {
  z-index: 999 !important;
  width: auto !important;
  font-size: 11px !important;
}

.multi-select-width100 .Select-menu-outer {
  width: 100% !important;
  font-size: 11px !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.svg-shadow {
  filter: drop-shadow(10px 10px 5px #dadada);
}

.sort-column[title="Status"],
.sort-column[title="Tax Year"],
.sort-column[data-field="documentStatus"],
.sort-column[data-field="signatureStatus"] {
  overflow: inherit !important;
}

.react-bs-container-header {
  overflow: inherit !important;
}

.Select-multi-value-wrapper span:not(:first-child) {
  display: none;
}

.textalign-right {
  text-align: right;
}

.color-lgreen {
  color: #4ab14a;
}

.upload-doc-modal .prosystem-upload-doc-body {
  overflow: auto;
}

/* SF */
.sf-container {
  width: 100%;
  margin-top: 8px;
}

.sf-progressbar {
  counter-reset: step;
}

.sf-progressbar li {
  list-style: none;
  display: inline-block;
  width: 25%;
  position: relative;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
}

.sf-progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 27px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}

.sf-progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.sf-progressbar li:first-child:after {
  content: none;
}

.sf-progressbar li.sfstep-active:before {
  border-color: #1d9bff;
  background-color: #1d9bff;
  color: white;
}

.sf-progressbar li.sfstep-completed {
  color: rgb(0, 128, 0);
}

.sf-progressbar li.sfstep-completed:before {
  border-color: rgb(0, 128, 0);
  content: "\2713";
  background-color: rgb(0, 128, 0);
  color: white;
}

.sf-progressbar li.sfstep-completed + li:after {
  background-color: rgb(0, 128, 0);
}

.panel-footer .btn + .btn {
  margin-left: 10px;
}

.sf-panel-header {
  background-color: white;
  position: sticky;
  top: 0px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: white;
  /* font-size: 3rem; */
  /* position: relative; */
  z-index: 10;
  height: 100px;
}

.sf-panel-footer {
  position: absolute;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: right;
  -ms-flex-align: center;
  align-items: right;
  -ms-flex-pack: center;
  justify-content: flex-end;
  background-color: white;
  background-color: white;
  text-align: right;
  margin: -1px;
}

.tran-div-pad {
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0px;
}

.height-150p {
  height: 150px;
}

.txt-right-align {
  text-align: right;
}

.txt-font-bold {
  font-weight: bold;
}

.width15per {
  width: 15% !important;
}

.width10per {
  width: 10% !important;
}

.padding-left {
  padding-left: 0px;
}

.padding-right-15{
  padding-right: 15px;
}

.pad-bottom-10p {
  padding-bottom: 10px;
}

.text-center-align {
  text-align: center !important;
}

.height-300p {
  height: 300px;
}

.text-left-align {
  text-align: left !important;
}

#sfUploadDocumentTable
  .react-bs-table-container
  .react-bs-table
  .react-bs-container-body {
  overflow: inherit;
}

.sfPanel-body {
  position: relative;
  overflow-x: hidden;
  overflow: auto;
  height: calc(100vh - 230px);
}

.custom-sfPanel-body {
  position: relative;
  overflow-x: hidden;
  overflow: hidden;
  height: calc(100vh - 230px);
}

.custom-sfPanel-body .tab-content-container {
  height: 100%;
}

.custom-sfPanel-body .tab-content-container-left-card {
  height: 100%;
}

.custom-sfPanel-body .tab-content-container-middle-card {
  height: 100%;
}

.custom-sfPanel-body .tab-content-container-right-card {
  height: 100%;
}

.custom-sfPanel-body .tab-content-container .pr-right-pannel,
.custom-sfPanel-body .tab-content-container .pr-left-pannel,
.custom-sfPanel-body .tab-content-container .pr-center-pannel {
  position: relative;
  height: 100%;
}

.custom-sfPanel-body .scroll {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
}

.custom-sfPanel-body .box {
  height: 320px;
  display: block;
  background: #ccc;
  margin-bottom: 10px;
}

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle:after {
  /* symbol for "opening" panels */
  font-style: normal;
  font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
  content: "\e114"; /* adjust as needed, taken from bootstrap.css */
  float: right; /* adjust as needed */
  color: grey; /* adjust as needed */
  font-style: normal;
}

.accordian-panel
  .panel
  .panel-heading
  .panel-title
  .accordion-toggle.collapsed:after {
  /* symbol for "collapsed" panels */
  font-style: normal;
  font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
  content: "\e080"; /* adjust as needed, taken from bootstrap.css */
  font-style: normal;
}

.sf-receipient-signer-sign-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
  box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
}

.sf-sender-signer-sign-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
  box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
}

.mar-B0 {
  margin-bottom: 0px !important;
}

.bookmarksPane .accordian-panel .panel {
  border-radius: 0px !important;
  margin-bottom: 0px !important;
}

#mailMergeUploadCsvTable {
  padding-top: 7%;
}

#mailMergeUploadCsvTable
  .react-bs-table-container
  .react-bs-table
  .react-bs-container-body {
  overflow: inherit;
}

#mailMergeUploadCsvTable .react-bs-container-header.table-header-wrapper {
  display: none;
}

.invalidMailMergeCellValue {
  color: red;
  cursor: pointer;
}

#div-validation-btn {
  height: 29vh;
  background-color: lightgrey;
  text-align: center;
  padding-top: 8vh;
}

#div-validation-btn div {
  margin-top: 10px;
}

.mailmerge-custom-field {
  border: none;
}

.mailmerge-custom-field-tag {
  background-color: #66afe930;
  border-radius: 5px;
  border: 1px solid #3791d8;
  color: black;
  display: inline-block;
  font-family: sans-serif;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 2px;
  cursor: pointer;
}

.mailmerge-custom-field-tag-remove {
  color: #274c96;
  padding-left: 5px;
  cursor: pointer;
}

.custom-field-highlight {
  border: 1px #666 solid;
  -webkit-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
  -moz-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
  box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
}

#pdfpreview .modal-content {
  display: inline-block;
}

.csv-table-td-error {
  outline-color: red;
  outline-style: solid;
  outline-width: 3px;
  outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-error {
  outline: 3px solid red !important;
  outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-warning {
  outline: 3px solid orange !important;
  outline-offset: -1px;
}

.margin-tbl-20 {
  margin: 20px 0 20px 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.beforeContent {
  position: relative;
}

.beforeContent::before {
  content: "-";
  position: absolute;
  left: -10px;
}

@media screen and (min-width: 1360px) {
  #ddlUploadSFDocument .Select-arrow-zone {
    width: 15px;
  }
}

.fa-btn-icon {
  padding: 4px 0;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.no-padding-left {
  padding-left: 0px !important;
}

.add-customauthority-btn {
  padding: 6px;
  min-width: 30px;
}

#group-process-return-tab {
  height: 100%;
  position: relative;
}

.multi-select-control .Select-menu-outer {
  width: 100%;
  font-size: 11px;
}

.disable-link {
  pointer-events: none;
}

.group-input {
  height: 36px;
}

#datepicker_input div {
  min-width: 0px;
}

#datepicker_input div .react-datepicker {
  top: 100%;
}

.react-bs-table .table-bordered > thead > tr:first-child > th {
  overflow: visible;
}

.zero-margin {
  margin-bottom: 0 !important;
}

.overlay-height {
  height: 500px;
}

.phone-number-input {
  height: 36px;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.calendarInput {
  position: absolute;
  z-index: 1000;
  top: 30px;
}

.calendarClass {
  min-width: 0px;
  display: inline-block;
  position: relative;
  width: 100%;
}

.calender-input-disabled {
  pointer-events: none !important;
}

.inputClass {
  width: 100%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-size: 12px;
  padding-left: 6px;
}

.minusClass {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  border-radius: 0px 3px 3px 0px;
  height: 100%;
  font-family: monospace;
}

.minusClass:hover {
  cursor: pointer;
}

.cursor-pointer{
  cursor: pointer;
}

#dateId .dateClass .react-datepicker-container .react-datepicker-top {
  background: #ddd;
  color: black;
  border-color: #ddd;
}

.react-bs-table-container
  .react-bs-table
  .react-bs-container-body
  td
  .button-group
  button
  i,
.react-bs-table-container
  .react-bs-table
  .react-bs-container-body
  .button-cell
  button
  i {
  padding: 4px;
}

.btn-group-vertical {
  display: inline-flex !important;
}

.react-bs-table-container
  .react-bs-table
  .react-bs-container-body
  td
  .button-group
  .dropdown-toggle::after,
.app-header-menu-item .dropdown-toggle::after {
  display: none;
}

/*---------------User Management Start----------*/

.user-group-roles-label {
  display: inline;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.label-success {
  background-color: #5cb85c;
}

.label-info {
  background-color: #5bc0de;
}

.label-danger {
  background-color: #d9534f;
}

.label-warning {
  background-color: #f0ad4e;
}

.addUserModal .modal-dialog,
.editUserModal .modal-dialog,
.ero-signature .modal-dialog {
  max-width: 100%;
  font-size: 14px;
}

.ero-signature-delegation .modal-content {
  width: 600px !important;
  margin: auto;
}

.user-management-edit-password .modal-content,
.edit-company-information .modal-content,
.edit-primary-admin .modal-content {
  width: 600px !important;
  margin: auto;
}

/*---------------User Management End----------*/

/*---------------App Header Start-------------*/

.change-password-modal .modal-content,
.logout-confirmation .modal-content,
.header-my-download .modal-content,
.header-my-account-modal .modal-content {
  width: 600px !important;
  margin: auto;
}

.report-problem-modal .modal-content {
  width: 800px !important;
  margin: auto;
}

.my-settings .modal-content,
.my-login-history .modal-content {
  width: 900px !important;
  margin: auto;
}

.app-header-menu-item ul li a {
  text-decoration: none;
}

.app-header-menu-item ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
/*---------------App Header Stop--------------*/

.modal-content {
  font-size: 14px;
}

/*---------------Pagination Start----------------*/
.page-item.active .page-link {
  background-color: #337ab7;
  border-color: #337ab7;
}

a {
  color: #337ab7;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.15;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.react-bs-table-pagination p {
  font-size: 14px;
}
/*---------------Pagination End----------------*/

/*---------------Settings Start----------------*/
.mfa-settings .modal-content,
.o365-group .modal-content,
.add-edit-message .modal-content,
.manual-address-popup .modal-content,
.edit-column-value .modal-content,
.taxCaddy-reporting-channel .modal-content,
.ssr-signature-settings .modal-content,
.ssr-organizer-settings .modal-content,
.stripe-account-details .modal-content,
.edit-company-logo .modal-content,
.edit-company-white-logo .modal-content,
.edit-color-palette .modal-content,
.stripe-integration-modal .modal-content {
  width: 600px !important;
  margin: auto;
}

.add-edit-message .modal-content .form-group {
  display: block;
  margin-bottom: 1rem;
}

.file-preview-modal .modal-content {
  width: 800px !important;
  margin: auto;
}

.mfa-settings .react-bs-table-tool-bar .row {
  display: block;
}

.manual-signature-options .row {
  display: block;
}

.manual-address-popup .form-group {
  margin-bottom: 0.75rem;
}

.watermark-settings {
  min-width: 85vw !important;
}
/*---------------Settings End----------------*/

/*---------------Reports Start--------------*/
.delivered-resend-access-link .modal-content,
.report-change-status-modal .modal-content,
.set-column-value-modal .modal-content,
.modify-retention-period-modal .modal-content,
.download-returns-modal .modal-content,
.column-options-modal .modal-content,
.group-configuration-modal .modal-content,
.change-status-modal .modal-content,
.edit-client-info .modal-content,
.screen-share-modal .modal-content,
.preparer-message .modal-content,
.edit-controller-info .modal-content,
.grouped-resend-access-link .modal-content,
.grouped-uploaded-documents .modal-content {
  width: 600px !important;
  margin: auto;
}

.attachment-instruction-modal .modal-content {
  width: 700px !important;
  margin: auto;
}

.react-bs-container-body
  .button-cell
  .button-group
  .btn-group-vertical
  .dropdown-menu {
  position: fixed !important;
}

.react-bs-container-body
  .button-cell
  .button-group
  .btn-group-vertical
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu {
  position: absolute !important;
}

.react-bs-container-body
  .button-cell
  .button-group
  .btn-group-vertical
  .dropdown-menu
  li {
  padding-top: 2px;
}

.react-bs-container-body
  .button-cell
  .button-group
  .btn-group-vertical
  .dropdown-menu
  li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.react-bs-container-body
  .button-cell
  .button-group
  .btn-group-vertical
  .dropdown-menu
  li
  a {
  color: black;
  font-size: 14px;
  padding-right: 5px;
  text-decoration: none;
}

.add-multiple-payment .table th,
.add-multiple-payment .table td {
  padding: 0.3rem;
}

.tab-content-container-right-card
  .pr-left-pannel
  .scroll
  .accordion
  .card-body {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0;
}

.tab-content-container-right-card
  .pr-left-pannel
  .scroll
  .accordion
  .card-body
  .form-group
  .form-label {
  margin-bottom: 0;
  word-wrap: normal;
}

#move-to-dropdown {
  padding: 6px;
  margin-right: 3px;
  background-color: #f0ad4e;
  color: white;
}

.InternalRouting-dropdown-Menu .dropdown-toggle::after,
.Delivery-dropdown-Menu .dropdown-toggle::after {
  display: none;
}
/*---------------Reports Start--------------*/
/*---------------Billing Start--------------*/
.billing-custom-date-handler {
  display: contents;
}

.billing-custom-date-handler .control-pane {
  width: 100%;
}

.add-card-modal .modal-content,
.transaction-successful-modal .modal-content {
  width: 600px !important;
  margin: auto;
}

.purchase-return-modal .modal-content {
  width: 900px !important;
  margin: auto;
}

.purchase-return-modal .purchase-item-wrap .heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px;
}
/*---------------Billing End----------------*/

/* Folder toolbar */
.folder-toolbar__action {
  padding: 5px 10px;
}

.folder-toolbar__action>span {
  cursor: pointer;
}

.folder-toolbar__action i {
  font-size: 15px;
  padding: 5px 5px 0px 5px;
}

.archive-tree {
  padding: 0 24px 0 24px;
  font-size: 13px;
  color: #f7f6f6;
  margin: 5px 18px 6px 30px;
}

.archive-tree.rc-tree .rc-tree-treenode span.rc-tree-switcher {
  background-image: none;
  vertical-align: baseline;
}

.rc-tree-node-content-wrapper,
.rc-tree-title {
  width: 90%;
}

.menu-archive-folder-icon {
  float: right;
  padding-top: 4px;
}

.archive-tree.rc-tree .rc-tree-treenode:hover,
.archive-tree.rc-tree .rc-tree-treenode.rc-tree-treenode-selected,
.archive-tree.rc-tree-node-content-wrapper:hover {
  background-color: #021f54;
}

.archive-tree .rc-tree-node-selected {
  opacity: unset;
  box-shadow: unset;
}




button.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
}

#dd-change-folder-retention,
#dd-archive-retention {
  width: inherit;
}

.btn-archive-folder-type {
  width: 48%;
}



#btn-archive-create-folder {
  background-color: rgb(9, 115, 186);
}

.btn-ok-change-expiry {
    width: 51px;
    margin-left: 10px;
    margin-bottom: 2px;
}

.rc-tree-list-holder-inner .rc-tree-treenode {
  text-align: left;
  border-radius: 12px;
}

/*------------------ Compose Message Starts--------------*/

.btn-modal{
  width: 100px !important;
  line-height: 12px;
}

.btn-success-fill {
  background-color: #88c656;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  border: none;
  border-radius: 8px !important;
  box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 30%);
  padding: 10px 25px;
  text-align: center;
}

.btn-success-fill:hover {
  color: #fff;
}

.btn-success-outline {
  background-color: #fff;
  color: #88c656;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  border: solid 1px #88c656;
  border-radius: 8px !important;
  /* box-shadow: 0px 6px 8px 0px rgb(0 0 0 / 30%); */
  padding: 8px 25px;
}

.btn-success-outline:hover {
  color: #88c656;
}

#uploadFile {
  display: none;
}

.btn-attach {
  position: absolute;
  top: -2px;
  right: 0;
  cursor: pointer;
}

.upload-progress-bar {
  height: 4px;
  position: absolute;
  background: #88c656;
  bottom: 0;
  -webkit-transition: all 600ms;
  -moz-transition: all 600ms;
  -ms-transition: all 600ms;
  -o-transition: all 600ms;
  transition: all 600ms;
}

.attachment-remove {
  position: absolute;
  right: 0;
  font-size: 20px;
  width: 26px;
  height: 26px;
  text-align: center;
  color: #929292;
}

.attachment-detail {
  width: 80%;
  white-space: pre;
}

.default-select {
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #ddd;
}

.loading-message {
  text-align: center;
}

.qna-selected.qna__email-input {
  background-color: #88c656;
  color: #fff;
  font-weight: 600;
}
/*------------------ Compose Message Ends--------------*/

.breadcrumb-container {
  margin: 0;
  font-size: 15px;
  color: #8d8d95;
}

.breadcrumb-container ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-container li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  max-width: 200px;
}
#folder-title {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #737373;
  padding: 22px;
  margin-bottom: 10px;
}

#folder-title .folder-name {
  padding-right: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

#folder-title span {
  padding-right: 10px;
}

.folder-toolbar {
  display: flex;
  align-items: center;
  
}

.react-bs-table-no-data{
  text-align: center;
}


.tox-editor-header {
  z-index: 0 !important;
}

.fixed-height-modal-wrapper {
  height: 75vh;
  padding: 0;
 overflow: auto;
}
.tox-tinymce{
  border: none !important;
}
.tox-toolbar__group{
  border-right: none !important;
}
.subject-row{
  width: 100%;
}
.row-modal-wrapper{
 margin-left: 0;
 margin-right: 0;
}

.row-full-width{
  width: 87%;
}

.popover-header{
  background-color: #05386b;
  color: #f7f6f6;
}

.bg-grey {
  background-color: #f6f6f6;
}

.form-check-input.lg {
  height: 1.5em;
  width: 1.5em;
}

.dropdown-menu {
  max-height: 300px;
  width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  color: #8d8d95;
}
.contact-list .dropdown-toggle {
  width: 250px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0973ba;
  border-color: #0973ba;
}
.contact-list .dropdown-toggle > p{
  width: 90%;
  margin-bottom:0;
  height: 18px;
}
.border-top{
  border-top: 1px solid var(--brand-black-20per) !important;
}
.dropdown-search {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px solid #ccc;
}


#customTable .selection-cell-header {
  position: absolute;
  top: -6px;
  background-color: transparent;
}

#customTable .selection-cell {
  width: 52px;
}

#customTable tbody tr td:last-child {
  color: rgb(77, 77, 77);
  text-decoration: none;
  visibility: hidden;
}

#customTable tbody tr:hover td:last-child,
.archive-folder-table-container #customTable tbody tr td:last-child {
  visibility: visible;
}

#customTable tbody tr:hover{
  background-color: var(--brand-grey);
}

#customTable tr {
  border-style: hidden;
}

#customTable tr .btn.btn-link {
  box-shadow: none;
  padding: 0px;
  text-decoration: none;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#customTable th {
  position: sticky;
  top: 0;
  background: white;
  font-size: 14px;
  color: #737373;
  z-index: 1;
}

#customTable th > span {
  margin-left: 5px;
  background-color: transparent !important;
}

.my-settings-modal .modal-header {
  background-color: var(--brand-dark-blue);
  color: #fff;
  border-radius: 0;
  font-size: 20px;
}

.my-settings-modal .modal-content {
  height: 90vh;
}

.my-settings-modal .tab-content{
  overflow: hidden;
}
.my-settings-modal .modal-body {
  height: calc(100% - 105px);
}

.my-settings-modal .modal-footer button {
  font-size: 15px;
}
.my-settings-tabs{
  flex-wrap: nowrap;
}
.my-settings-tabs .nav-item a {
  color: rgba(0,0,0,.5);
  font-weight: 700;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 10px 15px;
}

.my-settings-tabs .nav-item a:hover {
  background-color: transparent;
  color: rgba(0,0,0,.5);
}

.settings-sidebar .nav-item a.active,
.settings-sidebar .nav-link.active {
  background-color: #fff;
}

.my-settings-tabs .nav-item:not(:last-child) {
  margin-bottom: 1rem;
}

.my-settings-tabs .tab-content > .tab-pane{
  height: 100%;
}

.my-settings-tabs .tab-content .description {
  font-size: 12px;
}

.settings-sidebar {
  width: 270px;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.search-input-wrapper .search-input {
  border: 0;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 0px !important;
}
.search-input-wrapper .search-input:focus {
 box-shadow: none;
}

.search-input-wrapper .search-input::placeholder {
  font-style: italic;
}

.search-input-wrapper .search-textbox {
    margin: 7px;
    height: 33px;
    padding: 8px;
    border-radius: 0px !important;
    border:1px solid #ced4da;
}

.myfiles-request-list-margin{
  margin-left:0px !important;
}

.search-input-wrapper .search-icon{
  position: absolute;
  right:20px;
}

.dropdown-item-checked::before {
  position: absolute;
  left: .4rem;
  content: '✓';
  font-weight: 600;
}

.my-settings-sidebar {
  padding: 0;
}

.settings-sidebar .nav-link {
  position: relative;
}
.admin-settings-subMenu{
  margin-left: 25px;
}
.settings-sidebar .nav-link.active:before {
  content:"";
  width: 6px;
  height: 100%;
  background-color: var(--brand-green);
  position: absolute;
  top: 0;
  left: 0;
}

.admin-settings-subMenu.active:before{
  display: none;
}


.enable-mailbox-delegation {
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-copy {
  box-shadow: none;
  border: none;
  color: #929292;
  font-size: 15px;
}

.table-cell-with-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-cell-with-controls .folder-toolbar {
  visibility: hidden;
  padding-right: 20px;
}

.table-cell-with-controls:hover .folder-toolbar {
  visibility: visible;
}

.dropdown-group-heading {
  color: #fff;
  padding: 5px 0px;
  display: flex;
}

#report-form {
  height: 435px;
}

#report-form.custom-form > .row > div:first-child {
  padding-right: 20px;
}

#report-form textarea {
  height: 120px;
}

#report-form .attachment-card, #report-form .attachments {
  margin: 0;
}
.react-bootstrap-table-pagination-list-hidden {
  display: none;
}
.user-settings-table-container{
  max-height: unset;
}
.widget-wrapper .dropdown-menu{
  max-height: unset;
}
#ssuite-layout-wrapper > main {
  overflow: hidden !important;
}

.request-more-btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}