@import "../../../../styles/variables";

.treelist-container {
  padding: 10px 15px;

  .expand-button {
    font-size: 11px;
    color: $brand-primary-blue-2;
    cursor: pointer;
  }

  .treelist-expand-container {
    display: flex;
    justify-content: space-between;

    .add-folder-icon {
      cursor: pointer;
    }
    .disabled {
      cursor: not-allowed;
      color: $icon-normal-state;
      opacity: 0.4;
    }
  }
  .treelist-wrapper {
    border: 1px solid var(--brand-dark-grey);
    border-top: 0px;
    height: 210px;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    padding: 0px;
  }
  
  .treelist-header-container {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1000;
    align-items: center;
    display: flex;
    border: 1px solid var(--brand-dark-grey);
    padding: 0px;
    cursor: pointer;
   
    h4 {
      padding-left: 10px;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .d-tree-container {
    list-style: none;
    padding: 0;
  }
  
  .folder-list-item{
    padding: 5px 0px;
    align-items: center;
   
  }
  
  .folder-list-item:hover{
    background-color: var(--brand-grey);
  }
  
  .highlight-list-item {
    background-color: var(--brand-grey);
  }
  
  .greyed-out-item {
    color: #929292;
    

    &:hover{
      color: #929292;
      cursor: not-allowed;
    }
  }
  .d-tree-node {
    padding: 1px 15px;
  }
  
  .d-tree-toggler {
    cursor: pointer;
  }
  .active {
    transform: rotate(90deg) translate(0px, 0px);;
    color: black;
  }
  
  .d-flex {
    cursor: pointer;
  }
  
  .d-tree-expand-icon {
    transform: rotate(-90deg);
    font-size: 20px;
    color: grey;
  }  
}
