.simple-dropdown {
    .Dropdown-control {
        border: none;
        font-size: 14px;
        padding: 5px 10px;
        border-bottom: $gray-border;
        font-weight: 600;
        color: $brand-secondary-black;
    }
    .Dropdown-placeholder:not(.is-selected) {
        color: $placeholder-grey;
    }
}
.secondary-dropdown {
    @extend .simple-dropdown;

    .Dropdown-control {
        background-color: var(--brand-light-blue);
        color: white;
    }
    .Dropdown-arrow {
        border-color: #fff transparent transparent;
    }
    &.is-open .Dropdown-arrow {
        border-color: transparent transparent #fff;
        border-width: 0px 5px 5px;
    }
    .Dropdown-disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }
}
.setting-dropdown {
    .Dropdown-control {
        border: none;
        font-size: $base-font-size;
        padding: 5px 10px;
        border-bottom: $gray-border;
        font-weight: 500;
        color: $brand-secondary-black;
    }
    .Dropdown-menu {
        font-size: $base-font-size;
    }
}
.status-dropdown-wrapper {
    p {
        font-weight: bolder;
    }
    .secondary-dropdown {
        .Dropdown-control {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .Dropdown-menu {
            width: 120px;
            padding: 0;
            .dropdown-item{
                font-size: $font-size-md;
                padding: 5px 10px;
            }
        }
    }
}