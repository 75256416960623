.template-listing-table {
    .react-bootstrap-table-pagination {
        background-color: white;
        padding-top: 0px !important;
        width: 100%;

        & > div:nth-child(1) {
            padding-left: 20px;
            padding-top: 5px;
        }
        .react-bootstrap-table-pagination-list {
            & > ul {
                margin-bottom: 0px;
            }
        }
    }
    .header-toolbar-action {
        right: 0px !important;
        padding-right: 0 !important;

        button {
            margin-left: 15px !important;
            width: 115px;
        }
        @media (max-width: 1280px) {
            button {
                margin-left: 5px !important;
            }
        }
    }
}
