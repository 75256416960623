// This file contains the styles of common components
@import "../../styles/variables";

.featureDisabled-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 108px);
}

.featureDisabled-body h5 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-top: 40px;
    color: #212529;
}

.featureDisabled-body-text {
    font-size: 20px;
    font-weight: 400;
    white-space: pre;
    color: #212529;
    line-height: 28px;
    text-align: center;
}

.featureDisabled-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.featureDisabled-btn>button {
    font-size: 16px;
    font-weight: 400;
    padding: 6px 12px;
    height: 38px;
    border-radius: 4px !important;
    background-color: #0973ba;
    width: 220px;
}

.featureDisabled-btn>button:hover {
    background-color: #0973ba;
}

// CheckBoxes 

.primary-checkbox {
    .display-checkBox {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    .checked {
        label:before{
            background: $brand-primary-blue-2 !important;
            border: $brand-primary-blue-2 !important;
        }
    }
    label:before{
        top: 2px;
        left: 0px;
    }
    label:after {
        top: 2px;
        left: 4px;
    }
    label {
        color: $brand-secondary-black;
        font-size: 14px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        font-weight: 400;

        span {
            margin-left: 4px;
        }
    }
    .custom-radio label {
        display: inline-block;
        margin-right: 20px;
    }

    .custom-radio {
        display: flex;
    }
    .custom-radio {
        width: 100%;
        margin-right: 10px;
        margin-left: 23px;

       input[disabled] + label {
            cursor: not-allowed !important;
        }
    }
}

.disable-pagination > a{
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;

}

.tinymce-textarea {
    height: 245px;
    width: 825px;
    margin-top: 35px;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.175);
}

.tox-editor-header {
    border-bottom: solid 1px rgba(0, 0, 0, 0.175) !important;
}