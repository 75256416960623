@import "../../../styles/variables";

.my-settings-modal {
  max-width: 1100px;

  .modal-content {
    width: 1100px;
  }

  // User Authentication Questions Css
  .user-auth-questions-container {

    .add-new-question-container {
      margin: 10px 0px;
      padding: 12px 5px;

      .add-question-btn {
        width: 160px;
        box-shadow: 0px 0px;
        padding: 0px;
        border-radius: 4px !important;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;


        &::before {
          content: "+";
          font-size: 20px;
          padding: 0px 5px 0px 0px;
        }
      }

      .Add-questions-form {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .toggle-container {
          display: flex;
          justify-content: center;

          .switch {
            margin: 5px 0px 0px 0px;
          }

          h6 {
            padding-right: 10px;
          }
        }

        .group1 {
          width: 200px;
          margin-bottom: 10px;
        }

        .group2 {
          width: 300px;
          margin-bottom: 10px;
        }

        .group1 .group2 h6 {
          position: absolute;
          padding-bottom: 5px;
        }

        .edit-input {
          width: 55% !important;
          margin-bottom: 10px;
        }

        .add-form-actions-container {
          button:nth-child(1) {
            margin-right: 10px;
          }

        }

      }
    }

    .auth-questions-table-container {
      margin-top: 10px;

      th {
        line-height: 30px;
      }
    }
  }

  .settings-label-toggle-wrapper{
    margin: 0px !important;
  }

  // Message Options Css
  .message-options-parent-container {

    >div {
      padding: 0rem;
    }

    .title-text {
      margin-bottom: 0.5rem;
    }

    h5 {
      font-size: 14px;
    }

    .auth-method-dropdown {
      .Dropdown-control {
        width: 150px;
      }

      .Dropdown-menu {
        width: 150px;
      }

      .Dropdown-disabled {
        opacity: .65;
        cursor: not-allowed;
      }

      .Dropdown-arrow {
        top: 12px;
      }
    }
  }
}

.user-settings-sm {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .message-box-wrapper {
    margin-left: 20px !important;
    margin-right : 20px !important;
    max-width: 54% !important;
  
    header{
      margin-bottom: 0px !important;
    }
  }
  .saved-message-list-box{
  max-width:38% !important;
  min-width: 38% !important;
  }
}

.sm-accordian{
  margin-bottom:30px !important;
}