.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
    overflow: hidden !important;
}


html, body {
    font-size: 12px;
    margin-top: 0 !important;
}

.login-container {
    background: #ffffff;
}

.outer-logo-container {
    position: relative;
    background: #ffffff;
    padding: 70px 0;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
}

.inner-logo-container {
    position: relative;
    width: 320px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.outer-form-container {
    position: relative;
    content: '';
    left: 50%;
    bottom: 0;
    margin-left: -12.5px;
    border-style: solid;
    border-width: 13px 12.5px 0 12.5px;
    border-color: #ffffff transparent transparent transparent;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.login-form-container {
    text-align: center;
    padding: 15px 10px;
    background-color: #f7f7f7;
    height: 100vh;
}

.daimond {
    position: relative;
    width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

h4, .h4 {
    font-size: 15px;
}

.h5, h5 {
    font-size: 1.25rem;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #373e4a;
}
