// Content of this file will be removed after PR is merged
@import "../../../../../../../styles/variables";

.search-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;

    .search-input-wrapper {
        margin-left: 6px;
        width: 85%;
        .search-input {
            height: 29px;
            border-radius: 0px;
        }
    }
    button{
        font-size: $font-size-md;
        width: 120px;
    }
}

.sender-search-wrapper {
    display: flex;
    align-items: center;
    border: $gray-border;
    border-radius: 8px;
    justify-content: space-between;
    width: 50%;

    input:focus {
        box-shadow: none;
    }

    .dropdown-menu {
        width: unset;
        min-width: unset;
    }
}


.drop-off-user-table-container {
    display: block !important;
    max-height: calc(100vh - 359px);
    height: unset;
}
.drop-off-user-table-container table{
    margin-bottom: 0!important;
}
.drop-off-user-table {
    thead {
        tr {
            th {
                padding: 0.5rem;
                &:last-child {
                    position: relative;
                    .header-toolbar-action {
                        position: absolute;
                        top: 3px;
                        right: 15px;
                    }
                }
            }
        }
    }

    tbody {
        tr:hover {
            .cell-actions-wrapper {
                div {
                    visibility: visible !important;
                }
            }
        }
    }
    td {
        padding: 6px;
    }

    .user-state {
        margin-left: 7.5%;
    }
    .table-email {
        max-width: 10vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .sender-email {
        max-width: 20vw;
    }
    .header-toolbar-action {
        display: flex;

        span {
            margin-left: 40px;
        }
    }
    .react-bs-table-no-data {
        visibility: visible !important;
    }
}
.sender-table {
    tbody {
        tr:nth-child(1) {
            color: inherit !important;
            font-weight: normal !important;
            .selection-cell {
                visibility: visible !important;
            }
        }
    }
}

.auth-questions-action-bar-container {
    .set-default-button {
        border-radius: 18px !important;
        background-color: white;
        width: 100px;
        padding: 4px 0;
        font-weight: 600;
        text-align: center;
        margin-left: 8px;
    }
    .action-button {
        border-radius: 18px !important;
        width: 115px;
        margin-left: 8px;
        border: 1px solid;
    }
    .btn-primary {
        background-color: $brand-primary-blue-2;
    }
    .btn-outline-primary {
        color: $brand-primary-blue-2;
    }
    .btn-outline-primary:hover {
        background-color: $brand-primary-blue-2;
        border-color: $brand-primary-blue-2 !important;
        color: white;
    }
}
.recipients-table-container tbody tr:first-child td:first-child {
    opacity: 0;
    pointer-events: none;
    visibility: inherit;
}
.recipients-table-container tbody tr .react-bs-table-no-data {
    opacity: 1 !important;
    font-weight: normal;
}
.cell-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    div {
        visibility: hidden;
    }
    .table-email {
        // font-size: 14px;
        max-width: 20vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.table-email {
    // font-size: 14px;
    max-width: 10vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}