@import "../../../../styles/variables";

.filter-dropdown-container {
  width: auto !important;
  background: #fff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.54);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.54);
  border: none;
  border-radius: .25rem;
  padding: 0px;

  .groups-container {
    display: flex;
    flex-direction: column;
    padding: 0px 5px;

    .group-title {
      font-size: 14px;
      font-weight: 600;
      color: $brand-secondary-black;
    }

    hr {
      margin: 5px 0px;
    }

    .menu-item {
      min-width: 150px;
      padding: 5px 0px;
      cursor: pointer;
      padding-left: 10px;
      font-size: 12px;

      .fa-check {
        padding-right: 5px;
        color: $success-500;
      }

      &:hover {
        background-color: rgba(5, 56, 107, 0.1);
      }
    }
  }
}

.filters-custom-component {
  padding: 6px 7px;
  min-width: 40px;
  height: 33px;
  border: 1px solid #ced4da;
  border-radius: 0px;
  cursor: pointer;

  .filter-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .basic-arrow-down {
    background-repeat: no-repeat;

    &::before {
      font-size: 16px;
    }
  }
}