/* These utilities are same like bootstrap utilities with better namings. this is to avoid problems in bootstrap version migrations*/

/* MARGINS */
.margin-0 {
    margin: 0 !important;
}
.margin-1 {
    margin: .25rem !important;
}
.margin-2 {
    margin: .5rem !important;
}
.margin-3 {
    margin: .75rem !important;
}
.margin-4 {
    margin: 1rem !important;
}
.margin-5 {
    margin: 3rem !important;
}

.margin-right-0 {
    margin-right: 0 !important;
}
.margin-right-1 {
    margin-right: .25rem !important;
}
.margin-right-2 {
    margin-right: .5rem !important;
}
.margin-right-3 {
    margin-right: .75rem !important;
}
.margin-right-4 {
    margin-right: 1rem !important;
}
.margin-right-5 {
    margin-right: 3rem !important;
}

.margin-left-0 {
    margin-left: 0 !important;
}
.margin-left-1 {
    margin-left: .25rem !important;
}
.margin-left-2 {
    margin-left: .5rem !important;
}
.margin-left-3 {
    margin-left: .75rem !important;
}
.margin-left-4 {
    margin-left: 1rem !important;
}
.margin-left-5 {
    margin-left: 3rem !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}
.margin-top-1 {
    margin-top: .25rem !important;
}
.margin-top-2 {
    margin-top: .5rem !important;
}
.margin-top-3 {
    margin-top: .75rem !important;
}
.margin-top-4 {
    margin-top: 1rem !important;
}
.margin-top-5 {
    margin-top: 3rem !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}
.margin-bottom-1 {
    margin-bottom: .25rem !important;
}
.margin-bottom-2 {
    margin-bottom: .5rem !important;
}
.margin-bottom-3 {
    margin-bottom: .75rem !important;
}
.margin-bottom-4 {
    margin-bottom: 1rem !important;
}
.margin-bottom-5 {
    margin-bottom: 3rem !important;
}

.margin-left-right-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.margin-left-right-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
}
.margin-left-right-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
}
.margin-left-right-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
}
.margin-left-right-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}
.margin-left-right-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

.margin-top-bottom-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.margin-top-bottom-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
}
.margin-top-bottom-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
}
.margin-top-bottom-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
}
.margin-top-bottom-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.margin-top-bottom-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

/* PADDINGS */
.padding-0 {
    padding: 0 !important;
}
.padding-1 {
    padding: .25rem !important;
}
.padding-2 {
    padding: .5rem !important;
}
.padding-3 {
    padding: .75rem !important;
}
.padding-4 {
    padding: 1rem !important;
}
.padding-5 {
    padding: 3rem !important;
}

.padding-right-0 {
    padding-right: 0 !important;
}
.padding-right-1 {
    padding-right: .25rem !important;
}
.padding-right-2 {
    padding-right: .5rem !important;
}
.padding-right-3 {
    padding-right: .75rem !important;
}
.padding-right-4 {
    padding-right: 1rem !important;
}
.padding-right-5 {
    padding-right: 3rem !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}
.padding-left-1 {
    padding-left: .25rem !important;
}
.padding-left-2 {
    padding-left: .5rem !important;
}
.padding-left-3 {
    padding-left: .75rem !important;
}
.padding-left-4 {
    padding-left: 1rem !important;
}
.padding-left-5 {
    padding-left: 3rem !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}
.padding-top-1 {
    padding-top: .25rem !important;
}
.padding-top-2 {
    padding-top: .5rem !important;
}
.padding-top-3 {
    padding-top: .75rem !important;
}
.padding-top-4 {
    padding-top: 1rem !important;
}
.padding-top-5 {
    padding-top: 3rem !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}
.padding-bottom-1 {
    padding-bottom: .25rem !important;
}
.padding-bottom-2 {
    padding-bottom: .5rem !important;
}
.padding-bottom-3 {
    padding-bottom: .75rem !important;
}
.padding-bottom-4 {
    padding-bottom: 1rem !important;
}
.padding-bottom-5 {
    padding-bottom: 3rem !important;
}

.padding-left-right-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.padding-left-right-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
}
.padding-left-right-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
}
.padding-left-right-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
}
.padding-left-right-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.padding-left-right-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.padding-top-bottom-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.padding-top-bottom-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
}
.padding-top-bottom-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}
.padding-top-bottom-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
}
.padding-top-bottom-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.padding-top-bottom-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

/* HEIGHT - WIDTH */

.height-100-percent {
    height: 100% !important;
}
.width-100-percent {
    width: 100% !important;
}

/* CUSTOM MODAL */

.custom-bootstrap-modal .modal-content {
    max-height: 90vh;
    border: 0;
}

.custom-bootstrap-modal .modal-header {
    background-color: var(--brand-dark-blue);
    color: #fff;
    border-radius: 0;
    font-size: 20px;
}

.custom-bootstrap-modal .modal-body {
    height: calc(100% - 105px);
    overflow: auto;
}

.custom-bootstrap-modal .modal-footer button {
    font-size: 14px;
}
.custom-bootstrap-modal .modal-footer.without-border {
    border: none;
}

.custom-bootstrap-modal .modal-footer.space-between {
    justify-content: space-between;
}

/* FORM */
.custom-form .form-label,
.custom-form input,
.custom-form textarea {
    font-size: 15px;
    margin: 0;
    font-weight: normal;
}
.custom-form input,
.custom-form .form-control[readOnly] {
    font-weight: 600;
    color:#5b5b5b;
    text-align: center;
}
.custom-form .form-control[readOnly] {
    background-color: #ebebeb;
}
.custom-form textarea {
    resize: none;
}
.custom-form .row {
    align-items: center;
    margin: 0;
}
.custom-form .row > div {
    padding: 0;
}
.custom-form > *:not(:last-child) {
    margin-bottom: 20px;
}

.file-attachment input {
    display: none;
}

.file-attachment label {
    cursor: pointer;
    margin-left: 10px;
    font-size: 15px;
}

.file-attachment .info-text {
    font-size: 12px;
    
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: #ced4da;
    background-image: none;
    box-shadow: inherit;
    padding-right: inherit;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none;
    padding-right: inherit;
}
.align-centre{
    align-items: center !important;
  }
.flex-no-wrap{
    flex-wrap: nowrap;
  }