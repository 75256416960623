@import "variables";
@import "./components/spacings";
@import "./components/button";
@import "./components/typography";
@import "./components/modal";
@import "./components/form.scss";
@import "./components/dropdown";
@import "./components/accordion";
@import "./components/tables";
@import "./components/pagination";
@import "./components/card";
@import "./components/popover";
@import "./components/loader";
@import "./components/toaster";

body {
    font-family: $font-family;
    font-size: $base-font-size;
}

// Toast Notifier CSS 

.toast-container {
    white-space: pre-line;
}

// Loading screen UI

.loading-screen-container {
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10%;

    .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;
    }
    .loading-text {
        margin-top: 10px;
        font-size: 28px;
        font-weight: 500;
        color: $brand-body_black;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: baseline;

        span:first-child {
            padding-right: 20px;
        }
    }
}

// RBAC Disable CSS

[data-resource-id].disable {
    cursor: not-allowed !important;
    pointer-events: all;
    color: $icon-normal-state;
    opacity: 0.7;

    svg {
        path {
            fill: $icon-normal-state !important;
        }
    }

    i {
        color: $icon-normal-state !important;
    }
    span {
        color: $icon-normal-state;
    }

    .Dropdown-control {
        cursor: not-allowed !important;
    }
}
/*---------------Exchange message list Start---------------*/

.message-list-wrapper {
    background: $brand-blue-10;
    padding: 0 10px;
    height: 100%;
}

.message-list {
    height: calc(100vh - 166px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -10px;
    padding-right: 10px;
    padding-left: 3px;

    .magic-checkbox:checked + label:before,
    .magic-checkbox-sm:checked + label:before {
        background: $brand-primary-blue-2;
    }
}

.message-list.document-list {
    height: calc(100vh - 233px);
}

.message-toolbar {
    display: flex;
    align-items: center;
    padding: 5px 0;

    &-action {
        padding: 5px 10px;

        & > span {
            cursor: pointer;
        }

    }
    .resend-access-icon-wrapper {
        padding: 5px 7px 3px 10px;
    }

    &-checkbox {
        .magic-checkbox-sm + label {
            padding-left: 12px;

            &:before,
            &:after {
                top: -9px;
            }
        }

        .magic-checkbox-sm-indeterminate + label:after {
            top: -7px !important;
        }
    }
}

.toolbar-icon-disabled {
    cursor: not-allowed !important;
    opacity: 0.4;
}

// message pane
/*---------------Message detail pane starts-------------------*/
.message-pane {
    height: 100%;

    &-top {
        padding-top: 10px;
        color: $brand-secondary-black;
        border-bottom: $gray-border;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .row {
            align-items: center;
        }

        .message-pane-subject {
            font-size: $heading-size-1;
            line-height: 22px;
            color: $brand-secondary-black;
            white-space: pre-wrap;
        }

        .message-pane-action {
            display: flex;
            .btn {
                box-shadow: none;
                border: none;
                font-size: $font-size-md + 1;

                span,
                i {
                    color: $icon-normal-state;

                    &:hover {
                        color: $icon-hover-state;
                    }
                }

                &:disabled {
                    cursor: not-allowed;
                    pointer-events: auto;
                }
            }

            .icon-wrapper {
                padding: 6px 12px;
                cursor: pointer;
            }

            .delete-icon-wrapper {
                padding: 8px 12px 2px 12px;
            }

            .download-button {
                padding: 8px 12px 8px 12px;
            }
            .edit-icon {
                display: flex;
                align-items: center;
                font-size: 15px;
            }
        }
    }

    &-header {
        padding: 15px 0;
        .expiry-text-container {
            position: absolute;
            right: 0;
        }
    }

    &-leftheader {
        display: flex;
        padding: 0 5px;

        .avatar-wrapper {
            width: 50px;
            margin-right: 5px;
        }

        .avatar {
            width: 45px;
            height: 45px;
            text-align: center;
            font-size: $heading-size-1;
            background-color: #989898;
            color: $white;
            font-weight: 600;
            border-radius: 50px;
            padding-top: 10px;
        }
    }

    &-sender {
        font-size: $font-size-md;
        font-weight: 700;
        color: $brand-secondary-black;

        span {
            font-weight: 400;
        }
    }

    &-sentdate,
    &-expiredate {
        font-size: $base-font-size;
        color: $dark-gray;
    }

    &-expiredate .fas {
        color: $brand-primary-blue-2;
    }

    &-recipient {
        font-size: $base-font-size;
        color: $brand-secondary-black;
    }

    &-body {
        padding: 15px 0;
        overflow: hidden;
        overflow-y: scroll;
        color: $brand-secondary-black;
        border-bottom: $gray-border;
    }

    &-attachment {
        margin-top: 15px;
    }

    .attachment-count {
        position: absolute;
        top: -4px;
        border-radius: 50%;
        background: $brand-primary-blue-2;
        font-size: 10px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 4px;
        font-weight: bold;
        color: $white;

        &-disabled {
            background: $icon-normal-state;
            color: $white;

            & > span {
                cursor: not-allowed;
            }
        }
    }
}
#changeExpiry {
    .popover-header {
        padding: 10px 12px;
        font-size: 16px;
    }
    .button-grey {
        font-size: 16px;
        min-width:80px;
        padding: 5px 10px;
        font-weight: 400;
    }
    .button-primary-blue-2 {
        font-size: 16px;
        min-width: 80px;
        border-radius: 2px;
        padding: 5px 10px;
        font-weight: 400;
    }
}
.no-message-selected {
    text-align: center;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
}

.download-button {
    position: relative;
}

.attachment-disabled {
    cursor: not-allowed;
    color: $icon-normal-state !important;
}

/*---------------Message detail pane ends-------------------*/

/*---------------Document detail pane starts-------------------*/

.document-pane-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: $gray-border;

    p {
        font-size: $font-size-md;
        color: $brand-secondary-black;
    }
}
.client-note-wrapper {
    padding-top: 15px;
    border-bottom: $gray-border;
    p {
        font-size: $font-size-md;
        color: $brand-secondary-black;
    }
}
.document-row-list-wrapper {
    overflow: auto;
    margin-top: 10px;
}
.document-pane {
    .add-files-btn {
        font-size: $font-size-md;
        margin-top: 15px;
    }
}
.document-pane-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        font-size: 14px;
        margin-right: 10px;
        text-decoration: underline;
        cursor: pointer;
        color: $brand-primary-blue-1;
        margin-left: auto;

        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
        &.top-padding {
            padding-top: 15px;
        }
    }
}
.sidebar .active{

    background-color: #13294b;

}

.side-menu-button{
    background-color: transparent;
}


.treelist-container .d-tree-toggler {
    cursor: pointer;
    background-color: transparent;
}

/*---------------Document detail pane ends---------------------*/


/* Custom Switch CSS */

.custom-switch{
    display: flex;
    align-items: flex-start;
    font-size: $font-size-md;
    margin-left: 4px;

   label {
    color: $brand-secondary-black;
    font-size: $font-size-md;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-left: 10px !important;
   }
   .form-check-input{
    width: 28px;
    height: 14px;
   }
   .form-check-input:checked {
    background-color: $brand-primary-blue-2;
    border-color: $brand-primary-blue-2;
   }
    .form-check-input:disabled {
     cursor: not-allowed;
     pointer-events: auto;
    }
}
// Disabled CSS

.isDisabled {
    cursor: not-allowed;
    opacity: 0.4;

    a {
        color: currentColor;
        display: inline-block;
        /* For IE11/ MS Edge bug */
        pointer-events: none;
        text-decoration: none;
    }

    .accordion-button {
        cursor: not-allowed !important;
    }
}
.message-detail-activity-popover {
    width: 230px;
    padding: 0px !important;
    max-height: 152px;
    min-height: auto;
    cursor: pointer;

    .files {
        font-size: 12px;
        border: 1px solid #ccc;
        width: 18%;
        border-radius: 13px;
        background: $brand-primary-blue-1;
        color: white;
        text-align: center;
        width: 20%;
        margin: auto;
    }
    .email {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .date {
        font-size: 10px;
    }
    .main {
        height: fit-content;
        justify-content: left;
        display: inline-block;
        width: 80%;
    }
    .activity {
        border-top: solid 1px #ccc;
        padding: 7px;
        width: 100%;
        display: flex;
    }
}
