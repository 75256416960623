.heading-green-1 {
    font-size: $heading-size-1;
    color: $brand-primary-green;
    font-weight: 500;
}

.heading-black-1 {
    font-size: $heading-size-1;
    color: $brand-secondary-black;
    font-weight: 500;
}
.heading-blue-1 {
    font-size: $heading-size-1;
    color: $brand-primary-blue-2;
    font-weight: 500;
}

.heading-blue-2 {
    font-size: 14px;
    color: $brand-primary-blue-2;
    font-weight: 600;
}

.heading-black-md{
    font-size: $font-size-md;
    color: $brand-secondary-black;
    font-weight: 500;
}
.heading-grey-md{
    font-size: $font-size-md;
    color: $brand-secondary-black-60;
    font-weight: 500;
}
.heading-black-base-font{
    font-size: $base-font-size;
    color: $brand-secondary-black;
    font-weight: 500;
}