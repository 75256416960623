//---- Global Modal Styling --Start-----
.custom-modal-container {
    .modal-content {
        background-color: transparent;
        font-size: 16px;
        border-radius: 4px !important;
        border: none !important;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
    }

    .body-bottom-radius {
        border-bottom-left-radius: calc(0.5rem - 1px) !important;
        border-bottom-right-radius: calc(0.5rem - 1px) !important; 
    }
    .modal-dialog {
        width: 100%;
    }

    .modal-header {
        background-color: $navy;
        padding: 16px;
        border: none;
        border-bottom: 1px solid $navy-500;
    }

    .modal-footer {
        background-color: white;

        button {
            border-radius: 2px;
            margin-left: 8px;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            min-width: 100px;
            padding: 6px 12px;
            text-align: center;
        }
    }
    .additional-action {
        font-size: 16px;
    }
    .modal-title {
        color: white;
        font-size: 20px;
        line-height: 120%;
        max-width: 96%;
    }

    .modal-body {
        max-height: calc(100vh - 185px);
        overflow: auto;
        background-color: white;
    }
}

.confirm-modal {
    .modal-header {
        background-color: white;
        padding: 16px;
        border-bottom-color: #dee2e6;

        .modal-title {
            color: $brand-body_black;
            font-weight: 700;
        }

        button {
            color: $gray-400;
        }
    }
}

//---- Global Modal Styling --End-------

.edit-folder-modal {
    .modal-dialog {
        max-width: 500px;

        .modal-content {
            max-width: 500px;
        }
    }
}

.compose-modal {
    .modal-content {
        border: none;
        overflow: hidden;
        border-radius: 5px !important;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);

        .modal-header {
            background-color: $navy;
            padding: 13px;
            border: none;
            border-bottom: 1px solid $navy-500;
            font-size: 20px;
            font-weight: 700;
            color: white;
            font-size: 20px;
        }

        .modal-body {
            padding: 0;
            overflow: auto;
            height: 73vh;
            display: flex;
        }

        .compose-left-panel {
            width: 55px;
            border-right: $gray-border;

            .compose-tab-invalid {
                color: $error-color;
                position: absolute;
                top: 5px;
                right: 15px;
            }
        }

        .compose-content {
            flex: 1;
            overflow: auto;

            .recipient-wrapper {
                display: flex;

                &> :nth-child(2) {
                    flex: 1;
                    margin-left: 20px;
                }
            }

            .subject-wrapper {
                position: relative;
                padding: 15px 0 10px 0;

                .form-label {
                    color: $brand-secondary-black;
                    font-size: 14px;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: 600;
                }

                input {
                    border: none;
                    border-bottom: $gray-border;
                    border-radius: 0;
                    padding-left: 110px;

                    &:focus {
                        border: none;
                        border-bottom: solid 1px $brand-primary-green;
                        box-shadow: none;
                    }
                }
            }

            .compose-body-message {
                position: relative;
                height: 250px;
            }

            .compose-body-attachments {
                padding-top: 5px;
            }
        }

        .attach-button {
            position: absolute;
            right: 0;
            top: 9px;
        }

        .qna-wrapper {
            max-height: 225px;
            overflow-y: auto;
            padding: 5px 0;
        }

        .compose-preview-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: $white;
            height: 100%;
            padding: 20px 15px;

            .compose-preview-title {
                display: flex;
                justify-content: space-between;
            }

            .compose-preview {
                height: calc(100% - 30px);
                overflow: auto;

                &-header {
                    padding: 15px 0;
                    border-bottom: solid 1px $brand-primary-blue-2;
                }

                &-body {
                    padding: 15px 5px;

                    & p {
                        margin: 0;
                        line-height: 18px;
                        font-size: 14px;
                    }

                    &>.message {
                        min-height: 100px;
                        overflow-y: auto;
                        max-height: 180px;
                    }

                    &.attachments {
                        max-height: 110px;
                        overflow-y: auto;
                        overflow-x: hidden;

                        .filename {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;
                            max-width: 30vw;
                        }
                    }
                }
            }
        }

        .compose-options {
            &-field {
                display: flex;
                align-items: center;

                .simple-dropdown {
                    width: 200px;
                }

                &>*:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .Dropdown-disabled {
                opacity: 0.65;
            }
        }

        .modal-footer {
            padding: 10px;
            justify-content: space-between;

            .save-draft-button {
                margin-right: 8px;
            }

            button {
                border-radius: 2px;
                margin: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                min-width: 100px;
                padding: 6px 12px;
                text-align: center;
            }

            .button-primary-blue-2 {}
        }
    }
}

.add-sender-modal {
    .add-sender-input {
        padding: 10px;
    }

    .checkbox-label-wrapper {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
            align-items: center;

            p {
                margin: 4px 0 0 4px;
                font-size: 12px;
            }
        }

        .form-label {
            font-size: 12px;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            font-weight: 700;
            color: rgb(33, 37, 41);
        }
    }
}

.prompt-control {
    display: flex;
    align-items: center;
    font-size: 12px;

    i {
        color: $brand-primary-blue-2;
    }
}

.login-history-modal {
  
    .react-bootstrap-table {
        max-height: 300px;
        overflow: auto;
    }

    .table-wrapper {
        position: relative;

        .reload-button {
            padding: 5px;
            background-color: $white;
            position: absolute;
            top: 5px;
            right: 20px;
            z-index: 9;
            cursor: pointer;
            border-radius: 0px;
            color: $brand-primary-blue-1;
            border: $gray-border;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.last-login-modal {
    .modal-dialog {
        max-width: 500px;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
    }

    .last-login-content {
        .row:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.my-account-modal {
    .modal-dialog {
        max-width: 900px;

        .modal-body {
            padding: 0px;
            max-height: 100%;
        }
    }
    
    .button-primary-blue-2 {
        border-radius: 2px;
        margin-left: 8px;
        font-size: 16px;
        font-weight: 400;
        min-width: 100px;
        padding: 6px 12px;
        height: 34px;
        text-align: center;
    }
    .button-grey {
        padding: 6px 12px;
        height: 34px;
    }
    .profile-footer-button-container {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }

}

.settings-modal {
    .modal-content {
        width: 100%;
    }
}

.add-question-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.delete-question-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.add-sender-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.request-more-modal {
    .modal-dialog {
        max-width: 600px;
    }
}
.my-downloads-delete-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.add-category-modal {
    .modal-dialog {
        max-width: 550px;
    }
}
.delete-category-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.delete-senders-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.message-options-auth-method-warning-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.add-email-template-modal {
    .modal-dialog {
        max-width: 600px;
    }
}
.delete-saved-message-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.delete-request-template-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.delete-folder-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.request-template-modal {
    margin-top: 0px;
    .modal-dialog {
        max-width: 600px;
    }
    .text-danger {
        font-size: 12px;
        white-space: nowrap;
        margin-bottom: 0;
    }
}
.delete-message-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.confirmation-modal {
    .modal-dialog {
        max-width: 500px;
    }
}
.user-settings-modal {
    .modal-body {
        padding: 0px;
    }
    .tab-content {
        height: 100% !important;
    }
}
.file-view-download-modal {
    .modal-dialog {
        max-width: 600px;
    }
}
.archive-tabs {
    margin-top: 0px !important;
    margin-left: 0px !important;
}

.archive-tabs-height {
    height: 34.5px !important;
}



.create-folder-modal-container {
    max-width: 600px;
    .modal-title {
        color: $white;
    }

    .modal-body {
        overflow: visible;

        .form-control,
        label {
            font-size: $font-size-md;
        }
    }
}

.delete-warning {
    margin-top: 20px;
    font-size: $base-font-size;

    i {
        color: $orange;
    }
}

.new-request-dr-modal {
    margin-top: 0;

    .modal-dialog {
        max-width: 600px;
    }
    .modal-content {
        max-width: 600px;
        width: 100%;

        .modal-body {
            overflow: auto;
            max-height: calc(100vh - 200px);

            .add-files-wrapper {
                max-height: unset;
            }

            form {
                &>div:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        .modal-footer {
            .notify-container {
                span {
                    padding-right: 10px;
                }
            }
        }
    }

    .text-danger {
        font-size: 12px;
        white-space: nowrap;
        margin-bottom: 0px;
    }

    .warning-highlight {
        border: 1px solid $error-color;
        border-radius: 4px;
    }
}
.session-expired-modal {
    .modal-dialog {
        max-width: 500px;

        .modal-body {
            div {
                margin-left: 0px;
            }
        }
    }
}
.change-retention-period-modal {
    .modal-dialog {
        max-width: 400px;
        height: 100%;
        .modal-body {
            overflow: visible;
        }
    }
}
.confirm-new-dr-close-modal {
    .modal-dialog {
        max-width: 400px;
    }
    .modal-content {
        width: 400px;
        margin: 73px auto 0 auto;
    }
}
.logout-modal {
    .modal-dialog {
        max-width: 500px;
        
        .button-primary-blue-2 {
            background-color: $error-500;
            border: 1px solid $error-500 ;
        }
    }
}
.rolechange-notification-modal {
    .modal-dialog {
        max-width: 500px;
    }
}

.multiple-recipient-select {
    &>div:first-of-type {
        max-height: 80px;
        overflow-y: scroll;
    }
}

.move-folder-modal-container {
    margin-top: 50px;
    .modal-content {
        margin: auto;
        width: 700px;
    }

    button {
        padding: 6px 15px 6px 15px;

        &[disabled] {
            cursor: not-allowed;
            pointer-events: all !important;
        }
    }

    .alert {
        margin: 0px;
    }
}

.confirm-message-send-without-attachment-modal {
    .modal-dialog {
        max-width: 500px;
    }
}