.form-label-blue {
    padding: 4px 12px;
    border-radius: 3px;
    background: $brand-primary-blue-2;
    color: $white;
    display: flex;
    align-items: center;
    min-width: 200px;

    span {
        margin-left: 5px;
    }
}

.add-qa-form {
    align-items: center;
    margin-bottom: 10px;

    input,
    span {
        font-size: 12px;
        color: $brand-secondary-black;
    }

    & > div:nth-child(3) {
        text-align: right;
    }

    input {
        text-align: center;

        &::placeholder {
            font-style: italic;
        }
    }

    .actions {
        text-align: right;

        a {
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

.set-qa-form {
    margin: 0;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    input {
        &::placeholder {
            font-style: italic;
        }
    }

    .actions {
        width: 30px;

        a {
            cursor: pointer;
        }
    }
}

.input-rounded {
    border-radius: 50px;
    border-color: $brand-primary-green;
}

.underlined-input {
    border: none;
    border-bottom: $gray-border;
    border-radius: 0;
    font-size: 12px;
    color: $brand-secondary-black;
    text-overflow: ellipsis;
}

.input-error-message {
    color: $error-color;
    margin-top: 0.25rem;
    font-size: 0.875em;
}

.input-error input {
    border-color: $error-color !important;
}

.settings-form {
    label {
        font-size: $font-size-md;
        color: $brand-secondary-black;
        font-weight: 500;
        margin-top: 5%;
    }

    label:first-child {
        margin-top: 0;
    }

    input {
        font-size: $base-font-size;
        color: $brand-secondary-black;
        font-weight: 500;
    }

    textarea {
        max-height: 200px;
        font-size: $base-font-size;
        color: $brand-secondary-black;
        font-weight: 500;
    }

    .toggle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        margin-top: 3%;

        h6 {
            font-size: $font-size-md;
            color: $brand-secondary-black;
            font-weight: 500;
        }

        label {
            margin: 0;
        }
    }

    .mark-blacklist-wrapper {
        width: 191px;
        align-items: flex-end;
        justify-content: flex-start;
    
        span {
            padding-bottom: 10px;
        }

        h6 {
            margin-left: 5px;
            white-space: nowrap;
        }
    }
}

.template-form {
    & > div:not(:last-child) {
        margin-bottom: 14px;
    }
    .warning-highlight {
        border: 1px solid $error-color;
        border-radius: 4px;
    }

    .add-files-btn {
        margin-left: 10px;
        padding: 5px 0 !important;
    }
}

.select-with-clear {
    position: relative;
}

.input-with-editor {
    .editor-wrapper {
        border: $gray-border;
        border-radius: 3px;
        overflow: hidden;

        &.error {
            border-color: $error-color;
        }
    }
}

.form-select {
    border-radius: 3px;
}
.form-select:disabled {
    cursor: not-allowed;
}
.add-files-btn {
    margin-left: 10px;
    margin-top: 10px;
}
.add-files-wrapper {
    max-height: 20vh;
    overflow: auto;
    cursor: pointer;
    align-items: center;
    padding-right: 5px;

    .due-date-wrapper {
        display: flex;
    }

    .file-request-wrapper {
        display: flex;
        margin-top: 8px;
        position: relative;

        .category-dropdown__value-container {
            padding-top: 0;
        }

        .category-dropdown__indicators > div {
            padding: 4px !important;
        }

        .category-dropdown__placeholder{
            top:44%;
        }

        & > div {
            width: 30.5%;
            margin-right: 10px;
        }

        & > :last-child {
            margin-right: 0;
        }

        .actions {
            position: absolute;
            right: 4px;
        }
    }
}

form {
    &.was-validated .form-select:valid {
        border: $gray-border;
    }

    &.was-validated .form-select:valid:not([multiple]):not([size]) {
        // The below code will remove the Validation icon but retain the Arrow icon in Form.Select
        background-image: url($bsicon-arrow-down);
        padding-right: 27px;
    }

    &.was-validated .form-select:valid:focus {
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
}

.category-dropdown__menu-portal {
    z-index: 9999 !important;
    max-height: 100px !important;
}

.category-dropdown__menu {
    max-height: 100px !important;
}

.category-dropdown__menu-list {
    max-height: 100px !important;
}
