// table 2 - This table is used in Company and User Authentication Questions, Unlock,

.secondary-table {
    th {
        line-height: 35px;
        user-select: none;
        cursor: pointer;
    }

    .selection-cell-header {
        position: sticky !important;
        background-color: $white !important;
        top: 0px !important;
    }

    .selection-cell-header > div {
        padding-bottom: 6px;
    }

    .selection-cell {
        width: 50px;
        padding-top: 11px;
    }

    thead th {
        box-shadow: inset 0 1px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1;
        /* Custom color not present in brands file*/
    }

    .magic-checkbox + label:before,
    .magic-checkbox-sm + label:before {
        border-radius: 3px;
    }

    .magic-radio-sm + label:before,
    .magic-checkbox-sm + label:before {
        width: 14px;
        height: 14px;
    }

    .react-bs-table-no-data {
        visibility: visible !important;
    }
}

// .custom-table is used in Login history

.custom-table {
    thead {
        position: sticky;
        top: 0;
        background: $white;
        color: $dark-gray;
        box-sizing: content-box;
        border-bottom: $gray-border;
        box-shadow: inset 0 0px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1;
    }

    tbody tr {
        &:hover {
            background-color: $brand-seconday-gray-35;
        }

        td {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: none;
        }
    }

    td,
    th {
        line-height: 30px;
        font-size: $base-font-size;
    }
}

// Report table

.report-table {
    font-size: $font-size-md;

    .react-bootstrap-table & {
        table-layout: fixed !important;
    }

    thead {
        position: sticky;
        top: 0;
        background-color: $white;
        box-shadow: inset 0 1px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1;
    }

    tbody {
        border-top: none !important;
    }

    tr {
        &:hover {
            background-color: $brand-seconday-gray-35;
        }
    }

    &.table > :not(caption) > * > * {
        border-bottom-width: 0;
    }

    &.table td {
        font-size: $font-size-md;
    }

    tr > * {
        padding: 10px;
    }

    .with-badge {
        display: flex;
        width: 100%;
        align-items: center;

        .badge {
            padding: 5px;
            border-radius: 10px;
            background-color: transparent;
            color: $brand-primary-blue-1;
            font-size: $base-font-size;
            font-weight: 400;
        }
    }
}

.settings-table {
    margin-bottom: 2px;
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-style: none;
        vertical-align: middle;
    }
    td {
        font-weight: 500;
    }

    thead {
        position: sticky;
        top: 0;
        background-color: $white;
        font-size: $font-size-md;
        color: $brand-secondary-black-60;
        font-weight: 500;

        th {
            box-shadow: inset 0 1px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1;
            background-color: $brand-blue-10 !important;
        }
        .selection-cell-header {
            width: 52px;
            padding-bottom: 18px !important;
            
        }
        tr {
            th {
                padding: 0.5rem;
                &:last-child {
                    position: relative;
                    .header-toolbar-action {
                        position: absolute;
                        top: 3px;
                        right: 15px;
                        display: flex;
                        align-items: center;
                        padding: 5px 20px;
                        span {
                            margin-left: 30px;
                        }
                        button {
                            margin-left: 20px;
                            font-size: $font-size-md;
                        }
                    }
                    .drop-off-header {
                        top: 25%;
                        padding: 0 25px;
                    }
                    .recipient-header {
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    tbody {
        border-top: none !important;
        color: $brand-secondary-black;
        font-size: $base-font-size;

        .cell-actions-wrapper div {
            visibility: hidden;
        }
        .cell-actions-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                font-size: $base-font-size;
            }
        }
        tr:hover {
            box-shadow: inset 0 0 4px 2px $non-branded-light-blue_hover;

            .cell-actions-wrapper div {
                visibility: visible !important;
            }
        }
    }
    &.table > :not(caption) > * > * {
        padding: 0.5rem;
        background-color: unset;  
    }
    .selection-cell {
        padding-top: 11px !important;
    }
    .header-actions-container {
        display: flex;
        line-height: 20px !important;
        justify-content: flex-end;
        align-items: center;
        button {
            margin-left: 5%;
        }
    }
    .inline-actions-bar-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & button:nth-child(2) {
            margin-left: 20px;
        }
    }
    .table-name-cell {
        max-width: 10vw;
        display: block;
    }
    .table-email-cell {
        max-width: 18vw;
    }
    .sender-table-email-cell {
        max-width: 25vw;
    }
}

.authentication-questions-table {
    margin-top: 10px;
    margin-bottom: 20px;

    tbody tr:first-child {
        font-weight: 700;
    }
    .header-toolbar-action {
        right: 0 !important;
    }
}

// Max heights for all the tables-- Start----
.authentication-questions-table-container {
    max-height: calc(100vh - 280px);
    overflow: auto;
}

.my-settings-wrapper .authentication-questions-table-container {
    max-height: calc(100vh - 280px);
    overflow: auto;
    .table-name-cell {
        max-width: 120px;
        display: block;
    }
    .table-email-cell {
        max-width: 180px;
    }
}
.dropOff-table-container {
    max-height: calc(100vh - 520px);
    overflow: auto;
}
.archive-table-container {
    max-height: calc(100vh - 175px);
    overflow-y: auto;
}
.template-listing-table {
    .react-bootstrap-table {
        height: calc(100vh - 320px);
        table-layout: fixed !important;
        overflow: auto;
    }
}
@media (max-width: 1280px) {
    .template-listing-table {
        .table-name-cell {
            max-width: 6vw !important;
            display: block;
        }
    }
}
// Max heights for all the tables-- End------

//File view Table-------------------Start----
.archive-table-container {
    min-height: 400px;
    max-height: calc(100vh - 195px);
   
    table {
        th {
            white-space:nowrap;
        }
    }
    .file-icon-name-container {
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
            margin-left: 10px;
            font-size: $base-font-size;
            font-weight: 500;
            color: $brand-primary-blue-2;
            cursor: pointer;
            max-width: 75%;
            white-space: pre;
            &:hover {
                text-decoration: underline;
                text-decoration-color: $brand-primary-blue-2;
            }
        }
    }
    .subject-cell {
        max-width: 100%;
        white-space: pre;
        display: block;
    }
    .sender-name-cell {
        max-width: 10vw;
    }
    .folders-date-cell {
        font-size: $base-font-size;
        color: $brand-secondary-black-60;
    }
}

.recipient-action-bar{
    width: 88px;
    height: 24px;
    gap:8px;
}

.table.settings-table.dropOff-recipients-table thead {
    z-index: 100; 
}

//File view Table-------------------End------

.my-downloads {
    tbody {
        tr {
            td:last-child {
                .action-cell {
                    opacity: 0;
                }
            }
            &:hover {
                td:last-child {
                    .action-cell {
                        opacity: 1;
                    }
                }
            }
        }
    }
}


.category-table {
    margin-bottom: 2px;
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-style: none;
        vertical-align: middle;
    }
    td {
        font-weight: 500;
    }
    &.table > :not(caption) > * > * {
        padding: 0.5rem;
        background-color: unset;  
    }
    .selection-cell-header {
        width: 10px !important;
    }
    
    thead {
        position: sticky;
        top: 0;
        background-color: $white;
        font-size: $font-size-md;
        color: $brand-secondary-black-60;
        font-weight: 500;

        th {
            box-shadow: inset 0 1px 0 white, inset 0 -1px 0 black;
        }
       .selection-cell-header{
        padding-bottom: 18px !important        ;
       }
       
    }
    tbody{
        tr{
            border-bottom:solid 1px grey !important;
            max-height: 41px !important;
        }
        .action-btn{

        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $brand-primary-blue-1;
        cursor: pointer;
        }
       
    }
}