 /* Dot-spin css starts*/

 .dot-spin-loader {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 $brand-primary-green, 12.727926px -12.727926px 0 0 $brand-primary-green, 18px 0 0 0 $brand-primary-green, 12.727926px 12.727926px 0 0 rgba(136,198,36, 0), 0 18px 0 0 rgba(136,198,36, 0), -12.727926px 12.727926px 0 0 rgba(136,198,36, 0), -18px 0 0 0 rgba(136,198,36, 0), -12.727926px -12.727926px 0 0 rgba(136,198,36, 0);
  animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {
  0%, 100% {
    box-shadow: 0 -18px 0 0 $brand-primary-green, 12.727926px -12.727926px 0 0 $brand-primary-green, 18px 0 0 0 $brand-primary-green, 12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), 0 18px 0 -5px rgba(136,198,36, 0), -12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), -18px 0 0 -5px rgba(136,198,36, 0), -12.727926px -12.727926px 0 -5px rgba(136,198,36, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(136,198,36, 0), 12.727926px -12.727926px 0 0 $brand-primary-green, 18px 0 0 0 $brand-primary-green, 12.727926px 12.727926px 0 0 $brand-primary-green, 0 18px 0 -5px rgba(136,198,36, 0), -12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), -18px 0 0 -5px rgba(136,198,36, 0), -12.727926px -12.727926px 0 -5px rgba(136,198,36, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(136,198,36, 0), 12.727926px -12.727926px 0 -5px rgba(136,198,36, 0), 18px 0 0 0 $brand-primary-green, 12.727926px 12.727926px 0 0 $brand-primary-green, 0 18px 0 0 $brand-primary-green, -12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), -18px 0 0 -5px rgba(136,198,36, 0), -12.727926px -12.727926px 0 -5px rgba(136,198,36, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(136,198,36, 0), 12.727926px -12.727926px 0 -5px rgba(136,198,36, 0), 18px 0 0 -5px rgba(136,198,36, 0), 12.727926px 12.727926px 0 0 $brand-primary-green, 0 18px 0 0 $brand-primary-green, -12.727926px 12.727926px 0 0 $brand-primary-green, -18px 0 0 -5px rgba(136,198,36, 0), -12.727926px -12.727926px 0 -5px rgba(136,198,36, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(136,198,36, 0), 12.727926px -12.727926px 0 -5px rgba(136,198,36, 0), 18px 0 0 -5px rgba(136,198,36, 0), 12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), 0 18px 0 0 $brand-primary-green, -12.727926px 12.727926px 0 0 $brand-primary-green, -18px 0 0 0 $brand-primary-green, -12.727926px -12.727926px 0 -5px rgba(136,198,36, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(136,198,36, 0), 12.727926px -12.727926px 0 -5px rgba(136,198,36, 0), 18px 0 0 -5px rgba(136,198,36, 0), 12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), 0 18px 0 -5px rgba(136,198,36, 0), -12.727926px 12.727926px 0 0 $brand-primary-green, -18px 0 0 0 $brand-primary-green, -12.727926px -12.727926px 0 0 $brand-primary-green;
  }
  75% {
    box-shadow: 0 -18px 0 0 $brand-primary-green, 12.727926px -12.727926px 0 -5px rgba(136,198,36, 0), 18px 0 0 -5px rgba(136,198,36, 0), 12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), 0 18px 0 -5px rgba(136,198,36, 0), -12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), -18px 0 0 0 $brand-primary-green, -12.727926px -12.727926px 0 0 $brand-primary-green;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 $brand-primary-green, 12.727926px -12.727926px 0 0 $brand-primary-green, 18px 0 0 -5px rgba(136,198,36, 0), 12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), 0 18px 0 -5px rgba(136,198,36, 0), -12.727926px 12.727926px 0 -5px rgba(136,198,36, 0), -18px 0 0 -5px rgba(136,198,36, 0), -12.727926px -12.727926px 0 0 $brand-primary-green;
  }
}

/* Dot-spin css ends*/ 